.about-US-page {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.about-US-page .about-US-page-mob {
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
}

.about-US-page .overlap-51 {
  position: absolute;
}

.about-US-page .ellipse-35 {
  background-color: #f0da9080;
  border-radius: 76.29px/73.84px;
  filter: blur(172.72px);
  height: 148px;
  left: 71px;
  opacity: 0.3;
  position: absolute;
  top: 2115px;
  width: 153px;
}

.about-US-page .ellipse-36 {
  background-color: #f0da9080;
  border-radius: 76.29px/73.84px;
  filter: blur(172.72px);
  height: 148px;
  left: 28px;
  opacity: 0.3;
  position: absolute;
  top: 1867px;
  width: 153px;
}

.about-US-page .decor-35 {
  height: 274px;
  left: 128px;
  position: absolute;
  top: 11px;
  width: 92px;
}

.about-US-page .overlap-52 {
  left: 0;
  position: absolute;
  top: 0;
}

.about-US-page .group-31 {
  height: 946px;
  left: 641px;
  position: absolute;
  top: 1558px;
  transform: rotate(-32.69deg);
  width: 624px;
}

.about-US-page .ellipse-37 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 110px;
  opacity: 0.3;
  position: absolute;
  top: 700px;
  width: 394px;
}

.about-US-page .ellipse-38 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 420px;
  width: 394px;
}

.about-US-page .ellipse-39 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 230px;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 394px;
}

.about-US-page .group-32 {
  background-color: #f0da904c;
  border-radius: 693.25px/432.84px;
  filter: blur(1569.58px);
  height: 866px;
  left: 267px;
  position: absolute;
  top: 0;
  width: 1386px;
}

.about-US-page .text-wrapper-102 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1098px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  white-space: nowrap;
}

.about-US-page .text-wrapper-103 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1211px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  white-space: nowrap;
}

.about-US-page .text-wrapper-104 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1447px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  white-space: nowrap;
}

.about-US-page .text-wrapper-105 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1595px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  white-space: nowrap;
}

.about-US-page .overlap-53 {
  position: absolute;
}

.about-US-page .decor-36 {
  height: 587px;
  left: 0;
  position: absolute;
  top: 308px;
  width: 210px;
}

.about-US-page .decor-37 {
  height: 428px;
  left: 34px;
  position: absolute;
  top: 0;
  width: 176px;
}

.about-US-page .text-wrapper-106 {
  color: #708090;
  font-family: "Poppins", Helvetica;
  font-size: 150px;
  font-weight: 600;
  left: 226px;
  letter-spacing: -4.5px;
  line-height: 150px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.about-US-page .we-re-not-just-a {
  color: var(--black);
  font-family: "Poppins", Helvetica;
  font-size: 40px;
  font-weight: 400;
  height: 120px;
  left: 0;
  letter-spacing: -1.6px;
  line-height: 60px;
  position: absolute;
  text-align: center;
  top: 190px;
  width: 1380px;
}

.about-US-page .group-wrapper {
  background-image: url(../../../static/img/item-12-2.png);
  background-size: 100% 100%;
  height: 558px;
  left: 300px;
  position: absolute;
  top: 2338px;
  width: 1320px;
}

.about-US-page .group-33 {
  height: 445px;
  left: 135px;
  position: relative;
  top: 56px;
  width: 1079px;
}

.about-US-page .may-post {
  height: 445px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 445px;
}

.about-US-page .group-34 {
  height: 219px;
  left: 546px;
  position: absolute;
  top: 113px;
  width: 533px;
}

.about-US-page .frame-84 {
  height: 219px;
  position: relative;
}

.about-US-page .frame-85 {
  height: 101px;
  left: 0;
  position: absolute;
  top: 125px;
  width: 533px;
}

.about-US-page .group-35 {
  height: 108px;
  position: relative;
  top: -4px;
  width: 498px;
}

.about-US-page .text-wrapper-107 {
  color: #000000;
  font-family: "Josefin Sans", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 108px;
  left: 0;
  letter-spacing: -1.04px;
  line-height: 36px;
  position: absolute;
  top: 0;
  width: 496px;
}

.about-US-page .text-wrapper-108 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 70px;
  font-weight: 500;
  height: 75px;
  left: 0;
  letter-spacing: 0;
  line-height: 74.4px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.about-US-page .decor-38 {
  height: 399px;
  left: 128px;
  position: absolute;
  top: 2454px;
  width: 202px;
}

.about-US-page .ellipse-40 {
  background-color: #f0da9080;
  border-radius: 69.13px/90.39px;
  filter: blur(156.51px);
  height: 181px;
  left: 428px;
  opacity: 0.3;
  position: absolute;
  top: 2707px;
  width: 138px;
}

.about-US-page .decor-39 {
  height: 358px;
  left: 128px;
  position: absolute;
  top: -86px;
  width: 209px;
}

.about-US-page .frame-86 {
  position: absolute;
}

.about-US-page .frame-87 {
  position: absolute;
}

.about-US-page .item-14 {
  height: 292px;
  left: 0;
  position: absolute;
  top: 0;
  width: 297px;
}

.about-US-page .item-15 {
  height: 292px;
  left: 0;
  position: absolute;
  top: 322px;
  width: 297px;
}

.about-US-page .item-16 {
  height: 292px;
  left: 330px;
  position: absolute;
  top: 0;
  width: 297px;
}

.about-US-page .item-17 {
  height: 292px;
  left: 330px;
  position: absolute;
  top: 322px;
  width: 297px;
}

.about-US-page .frame-88 {
  left: 0;
  position: absolute;
  top: 0;
}

.about-US-page .group-36 {
  height: 236px;
  left: 46px;
  position: relative;
  top: 189px;
  width: 465px;
}

.about-US-page .frame-89 {
  height: 236px;
  position: relative;
}

.about-US-page .we-mix-smart-travel {
  color: #000000;
  font-family: "Josefin Sans", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 108px;
  left: 0;
  letter-spacing: -1.04px;
  line-height: 36px;
  position: absolute;
  top: 131px;
  width: 465px;
}

.about-US-page .text-wrapper-109 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 70px;
  font-weight: 500;
  height: 82px;
  left: 0;
  letter-spacing: 0;
  line-height: 81.8px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.about-US-page .ellipse-41 {
  background-color: #f0da90;
  border-radius: 69.13px/90.39px;
  filter: blur(156.51px);
  height: 181px;
  left: 119px;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 138px;
}

.about-US-page .ellipse-42 {
  background-color: #f0da90;
  border-radius: 69.13px/90.39px;
  filter: blur(156.51px);
  height: 181px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 131px;
  width: 138px;
}

.about-US-page .decor-40 {
  height: 1233px;
  left: 0;
  position: absolute;
  top: 406px;
  width: 656px;
}

.about-US-page .decor-41 {
  height: 1233px;
  left: 1390px;
  position: absolute;
  top: 675px;
  width: 530px;
}

.about-US-page .goessy-logo-pdf-8 {
  height: 60px;
  left: 94px;
  position: absolute;
  top: 440px;
  width: 106px;
}

.about-US-page .frame-90 {
  height: 196px;
  left: 140px;
  position: absolute;
  top: 2644px;
  width: 350px;
}

.about-US-page .overlap-54 {
  border-radius: 21.34px;
  height: 196px;
  position: relative;
}

.about-US-page .item-18 {
  background-color: #ffa500;
  border-radius: 21.34px;
  height: 196px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 350px;
}

.about-US-page .goessy-black-logo-7 {
  height: 44px;
  left: 136px;
  position: absolute;
  top: 11px;
  width: 78px;
}

.about-US-page .text-wrapper-110 {
  color: #000000;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 9px;
  font-weight: 400;
  height: 9px;
  left: 114px;
  letter-spacing: 0;
  line-height: 9px;
  position: absolute;
  text-align: center;
  top: 169px;
  white-space: nowrap;
}

.about-US-page .list-7 {
  height: 32px;
  left: 114px;
  position: absolute;
  top: 128px;
  width: 121px;
}

.about-US-page .link-item-17 {
  background-color: #000000;
  border-radius: 15.86px;
  box-shadow: 0px 6.34px 6.34px #0000001a;
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
}

.about-US-page .symbol-19 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  height: 18px;
  left: 9px;
  letter-spacing: 0;
  line-height: 18.1px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 12px;
}

.about-US-page .link-item-18 {
  background-color: #000000;
  border-radius: 15.86px;
  box-shadow: 0px 6.34px 6.34px #0000001a;
  height: 32px;
  left: 44px;
  position: absolute;
  top: 0;
  width: 32px;
}

.about-US-page .symbol-20 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  height: 18px;
  left: 8px;
  letter-spacing: 0;
  line-height: 18.1px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 16px;
}

.about-US-page .link-item-19 {
  background-color: #000000;
  border-radius: 15.86px;
  box-shadow: 0px 6.34px 6.34px #0000001a;
  height: 32px;
  left: 89px;
  position: absolute;
  top: 0;
  width: 32px;
}

.about-US-page .symbol-21 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  height: 18px;
  left: 6px;
  letter-spacing: 0;
  line-height: 18.1px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 21px;
}

.about-US-page .frame-91 {
  height: 56px;
  left: 29px;
  position: absolute;
  top: 63px;
  width: 287px;
}

.about-US-page .frame-92 {
  height: 27px;
  left: 14px;
  position: absolute;
  top: 0;
  width: 258px;
}

.about-US-page .frame-93 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 39px;
}

.about-US-page .overlap-group-24 {
  height: 21px;
  position: relative;
  width: 40px;
}

.about-US-page .text-wrapper-111 {
  color: #000000;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 21px;
  left: 0;
  letter-spacing: 0;
  line-height: 20.1px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.about-US-page .line-17 {
  height: 1px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 19px;
  width: 14px;
}

.about-US-page .frame-94 {
  height: 20px;
  left: 62px;
  position: absolute;
  top: 0;
  width: 114px;
}

.about-US-page .overlap-55 {
  height: 21px;
  position: relative;
  width: 117px;
}

.about-US-page .frame-95 {
  height: 20px;
  left: 198px;
  position: absolute;
  top: 0;
  width: 60px;
}

.about-US-page .overlap-56 {
  height: 21px;
  position: relative;
  width: 61px;
}

.about-US-page .frame-96 {
  height: 20px;
  left: 19px;
  position: absolute;
  top: 31px;
  width: 248px;
}

.about-US-page .frame-97 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 57px;
}

.about-US-page .overlap-group-25 {
  height: 21px;
  position: relative;
  width: 59px;
}

.about-US-page .line-18 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 19px;
  width: 14px;
}

.about-US-page .frame-98 {
  height: 20px;
  left: 80px;
  position: absolute;
  top: 0;
  width: 29px;
}

.about-US-page .overlap-57 {
  height: 21px;
  position: relative;
  top: -1px;
  width: 30px;
}

.about-US-page .frame-99 {
  height: 20px;
  left: 132px;
  position: absolute;
  top: 0;
  width: 136px;
}

.about-US-page .overlap-58 {
  height: 21px;
  position: relative;
  width: 119px;
}

.about-US-page .group-37 {
  height: 106px;
  left: 152px;
  position: absolute;
  top: 170px;
  width: 325px;
}

.about-US-page .text-wrapper-112 {
  color: #708090;
  font-family: "Poppins", Helvetica;
  font-size: 40px;
  font-weight: 600;
  left: 36px;
  letter-spacing: -1.2px;
  line-height: 45.1px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.about-US-page .we-re-not-just-a-2 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 50px;
  left: 0;
  letter-spacing: -0.8px;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 56px;
  width: 321px;
}

.about-US-page .section {
  height: 790px;
  left: 129px;
  position: absolute;
  top: 1754px;
  width: 371px;
}

.about-US-page .overlap-59 {
  height: 832px;
  left: 10px;
  position: absolute;
  top: -663px;
  width: 363px;
}

.about-US-page .frame-100 {
  height: 59px;
  left: 0;
  position: absolute;
  top: 773px;
  width: 349px;
}

.about-US-page .text-wrapper-113 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 13px;
  left: 13px;
  letter-spacing: 0;
  line-height: 12.9px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.about-US-page .input {
  background-color: #ffffff;
  border: 0.65px solid;
  border-color: #ced4da;
  border-radius: 8.18px;
  height: 41px;
  left: -1px;
  position: absolute;
  top: 18px;
  width: 351px;
}

.about-US-page .container-2 {
  background: none;
  border: none;
  color: #2125295d;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 18px;
  left: 17px;
  letter-spacing: 0;
  line-height: normal;
  overflow: hidden;
  padding: 0;
  padding-left: 0;
  position: relative;
  top: 13px;
  width: 316px;
}

.about-US-page .frame-101 {
  height: 79px;
  left: 55px;
  position: absolute;
  top: 668px;
  width: 240px;
}

.about-US-page .overlap-group-26 {
  height: 76px;
  left: 28px;
  position: relative;
  width: 191px;
}

.about-US-page .text-wrapper-114 {
  color: #211715;
  font-family: "Poppins", Helvetica;
  font-size: 30px;
  font-weight: 500;
  height: 54px;
  left: 0;
  letter-spacing: 0;
  line-height: 53.2px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.about-US-page .text-wrapper-115 {
  color: #708090;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 23px;
  left: 21px;
  letter-spacing: 0;
  line-height: 22.8px;
  position: absolute;
  text-align: center;
  top: 53px;
  white-space: nowrap;
}

.about-US-page .decor-42 {
  height: 793px;
  left: 12px;
  position: absolute;
  top: 0;
  width: 351px;
}

.about-US-page .frame-102 {
  height: 58px;
  left: 10px;
  position: absolute;
  top: 195px;
  width: 349px;
}

.about-US-page .frame-103 {
  height: 58px;
  left: 10px;
  position: absolute;
  top: 450px;
  width: 349px;
}

.about-US-page .frame-104 {
  height: 59px;
  left: 10px;
  position: absolute;
  top: 534px;
  width: 349px;
}

.about-US-page .container-3 {
  background: none;
  border: none;
  color: #2125295d;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 18px;
  left: 17px;
  letter-spacing: 0;
  line-height: normal;
  overflow: hidden;
  padding: 0;
  padding-left: 0;
  position: relative;
  top: 12px;
  width: 316px;
}

.about-US-page .frame-105 {
  height: 59px;
  left: 10px;
  position: absolute;
  top: 279px;
  width: 349px;
}

.about-US-page .button {
  all: unset;
  background-color: #ffa500;
  border-radius: 11.4px;
  box-shadow: 3.23px 3.23px 3.23px #00000014;
  box-sizing: border-box;
  height: 38px;
  left: 126px;
  position: absolute;
  top: 752px;
  width: 118px;
}

.about-US-page .text-wrapper-116 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 18px;
  left: 34px;
  letter-spacing: 0;
  line-height: 17.4px;
  position: absolute;
  text-align: center;
  top: 9px;
  white-space: nowrap;
}

.about-US-page .frame-106 {
  height: 103px;
  left: 10px;
  position: absolute;
  top: 619px;
  width: 349px;
}

.about-US-page .container-wrapper {
  background-color: #ffffff;
  border: 0.65px solid;
  border-color: #ced4da;
  border-radius: 8.18px;
  height: 85px;
  left: -1px;
  position: absolute;
  top: 18px;
  width: 350px;
}

.about-US-page .container-4 {
  height: 15px;
  left: 17px;
  overflow: hidden;
  position: relative;
  top: 15px;
  width: 316px;
}

.about-US-page .div-4 {
  color: #2125295d;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 18px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -3px;
}

.about-US-page .frame-107 {
  height: 60px;
  left: 10px;
  position: absolute;
  top: 364px;
  width: 349px;
}

.about-US-page .input-2 {
  background-color: #ffffff;
  border: 0.65px solid;
  border-color: #ced4da;
  border-radius: 8.18px;
  height: 41px;
  left: -1px;
  position: absolute;
  top: 19px;
  width: 351px;
}

.about-US-page .enter-your-contact-wrapper {
  height: 15px;
  left: 17px;
  overflow: hidden;
  position: relative;
  top: 12px;
  width: 316px;
}

.about-US-page .text-wrapper-117 {
  color: #2125295d;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
}

.about-US-page .decor-43 {
  height: 684px;
  left: 128px;
  position: absolute;
  top: 1835px;
  width: 374px;
}

.about-US-page .frame-108 {
  background-image: url(../../../static/img/item-12.png);
  background-size: 100% 100%;
  height: 334px;
  left: 145px;
  position: absolute;
  top: 972px;
  width: 340px;
}

.about-US-page .group-38 {
  height: 150px;
  left: 25px;
  position: relative;
  top: 92px;
  width: 253px;
}

.about-US-page .frame-109 {
  height: 150px;
  position: relative;
}

.about-US-page .we-mix-smart-travel-2 {
  color: #000000;
  font-family: "Josefin Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 81px;
  left: 0;
  letter-spacing: -0.64px;
  line-height: 20px;
  position: absolute;
  top: 65px;
  width: 253px;
}

.about-US-page .text-wrapper-118 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 30px;
  font-weight: 500;
  height: 45px;
  left: 0;
  letter-spacing: 0;
  line-height: 44.5px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.about-US-page .frame-110 {
  height: 335px;
  left: 145px;
  position: absolute;
  top: 1356px;
  width: 340px;
}

.about-US-page .frame-111 {
  background-image: url(../../../static/img/item-13-2.png);
  background-size: 100% 100%;
  height: 334px;
}

.about-US-page .group-39 {
  height: 335px;
  left: 144px;
  position: absolute;
  top: 587px;
  width: 339px;
}

.about-US-page .item-19 {
  height: 159px;
  left: 0;
  position: absolute;
  top: 0;
  width: 162px;
}

.about-US-page .item-20 {
  height: 159px;
  left: 0;
  position: absolute;
  top: 176px;
  width: 162px;
}

.about-US-page .item-21 {
  height: 159px;
  left: 177px;
  position: absolute;
  top: 0;
  width: 162px;
}

.about-US-page .item-22 {
  height: 159px;
  left: 177px;
  position: absolute;
  top: 176px;
  width: 162px;
}

.about-US-page .frame-112 {
  display: block;
  height: 33px;
  left: 1739px;
  position: absolute;
  top: 452px;
  width: 57px;
}

.about-US-page .text-wrapper-119 {
  color: #000000;
  display: block;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 0;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.about-US-page .group-40 {
  position: absolute;
}

.about-US-page .row-7 {
  background-image: url(../../../static/img/item-10.png);
  background-size: 100% 100%;
  height: 436px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1775px;
}

.about-US-page .overlap-60 {
  background-image: url(../../../static/img/item-9.png);
  background-size: 100% 100%;
  height: 502px;
  left: 6px;
  position: relative;
  top: -33px;
  width: 1762px;
}

.about-US-page .frame-113 {
  height: 331px;
  left: 323px;
  position: absolute;
  top: 108px;
  width: 1116px;
}

.about-US-page .text-wrapper-120 {
  color: #000000;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 423px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 310px;
  white-space: nowrap;
}

.about-US-page .list-8 {
  height: 70px;
  left: 424px;
  position: absolute;
  top: 203px;
  width: 268px;
}

.about-US-page .link-item-20 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 70px;
}

.about-US-page .symbol-22 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 21px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 23px;
}

.about-US-page .link-item-21 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 99px;
  position: absolute;
  top: 0;
  width: 70px;
}

.about-US-page .symbol-23 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 18px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 33px;
}

.about-US-page .link-item-22 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 197px;
  position: absolute;
  top: 0;
  width: 70px;
}

.about-US-page .symbol-24 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 15px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 42px;
}

.about-US-page .goessy-black-logo-8 {
  height: 132px;
  left: 456px;
  position: absolute;
  top: -44px;
  width: 235px;
}

.about-US-page .frame-114 {
  height: 58px;
  left: 264px;
  position: absolute;
  top: 219px;
  width: 1235px;
}

.about-US-page .overlap-group-27 {
  height: 44px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 86px;
}

.about-US-page .text-wrapper-121 {
  color: #000000;
  display: block;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 44px;
  left: 0;
  letter-spacing: 0;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.about-US-page .line-19 {
  height: 2px;
  left: 3px;
  position: absolute;
  top: 41px;
  width: 30px;
}

.about-US-page .overlap-61 {
  height: 44px;
  left: 134px;
  position: absolute;
  top: -1px;
  width: 250px;
}

.about-US-page .text-wrapper-122 {
  color: #000000;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 44px;
  left: 0;
  letter-spacing: 0;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.about-US-page .overlap-62 {
  height: 44px;
  left: 431px;
  position: absolute;
  top: -1px;
  width: 130px;
}

.about-US-page .overlap-63 {
  height: 44px;
  left: 611px;
  position: absolute;
  top: -1px;
  width: 126px;
}

.about-US-page .line-20 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 41px;
  width: 30px;
}

.about-US-page .overlap-64 {
  height: 44px;
  left: 785px;
  position: absolute;
  top: -1px;
  width: 63px;
}

.about-US-page .line-21 {
  height: 2px;
  left: 2px;
  position: absolute;
  top: 41px;
  width: 30px;
}

.about-US-page .overlap-65 {
  height: 44px;
  left: 897px;
  position: absolute;
  top: -1px;
  width: 255px;
}

.about-US-page .ellipse-43 {
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  opacity: 0.3;
  position: absolute;
  width: 394px;
}

.about-US-page .navbar-4 {
  position: absolute;
}

.about-US-page .link-home-4 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 626px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.about-US-page .link-our-4 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 726px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.about-US-page .link-lokally-AI-4 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 932px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.about-US-page .link-business-with-4 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 1063px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.about-US-page .link-about-us-4 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 1265px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.about-US-page .label-first-name {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 23px;
  left: 41px;
  letter-spacing: 0;
  line-height: 23.2px;
  position: absolute;
  top: 197px;
  white-space: nowrap;
  width: 120px;
}

.about-US-page .input-3 {
  background-color: #ffffff;
  border: 1.16px solid;
  border-color: #ced4da;
  border-radius: 14.68px;
  height: 63px;
  left: 17px;
  position: absolute;
  top: 230px;
  width: 629px;
}

.about-US-page .container-5 {
  height: 27px;
  left: 30px;
  position: relative;
  top: 17px;
  width: 567px;
}

.about-US-page .text-wrapper-123 {
  color: #2125295d;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 184px;
}

.about-US-page .label-last-name {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 23px;
  left: 698px;
  letter-spacing: 0;
  line-height: 23.2px;
  position: absolute;
  top: 197px;
  white-space: nowrap;
  width: 117px;
}

.about-US-page .input-4 {
  background-color: #ffffff;
  border: 1.16px solid;
  border-color: #ced4da;
  border-radius: 14.68px;
  height: 63px;
  left: 675px;
  position: absolute;
  top: 230px;
  width: 629px;
}

.about-US-page .label-email {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 23px;
  left: 41px;
  letter-spacing: 0;
  line-height: 23.2px;
  position: absolute;
  top: 305px;
  white-space: nowrap;
  width: 62px;
}

.about-US-page .input-5 {
  background-color: #ffffff;
  border: 1.16px solid;
  border-color: #ced4da;
  border-radius: 14.68px;
  height: 63px;
  left: 17px;
  position: absolute;
  top: 338px;
  width: 629px;
}

.about-US-page .text-wrapper-124 {
  color: #2125295d;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 139px;
}

.about-US-page .label-contact-number {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 23px;
  left: 698px;
  letter-spacing: 0;
  line-height: 23.2px;
  position: absolute;
  top: 305px;
  white-space: nowrap;
  width: 182px;
}

.about-US-page .input-6 {
  background-color: #ffffff;
  border: 1.16px solid;
  border-color: #ced4da;
  border-radius: 14.68px;
  height: 63px;
  left: 675px;
  position: absolute;
  top: 338px;
  width: 629px;
}

.about-US-page .text-wrapper-125 {
  color: #2125295d;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 146px;
}

.about-US-page .label-city {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 23px;
  left: 41px;
  letter-spacing: 0;
  line-height: 23.2px;
  position: absolute;
  top: 412px;
  white-space: nowrap;
  width: 43px;
}

.about-US-page .input-7 {
  background-color: #ffffff;
  border: 1.16px solid;
  border-color: #ced4da;
  border-radius: 14.68px;
  height: 63px;
  left: 17px;
  position: absolute;
  top: 446px;
  width: 629px;
}

.about-US-page .container-6 {
  height: 27px;
  left: 30px;
  overflow: hidden;
  position: relative;
  top: 17px;
  width: 567px;
}

.about-US-page .text-wrapper-126 {
  color: #2125295d;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 183px;
}

.about-US-page .input-8 {
  background-color: #ffffff;
  border: 1.16px solid;
  border-color: #ced4da;
  border-radius: 14.68px;
  height: 63px;
  left: 17px;
  position: absolute;
  top: 560px;
  width: 1287px;
}

.about-US-page .label-state {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 23px;
  left: 698px;
  letter-spacing: 0;
  line-height: 23.2px;
  position: absolute;
  top: 412px;
  white-space: nowrap;
  width: 58px;
}

.about-US-page .input-9 {
  background-color: #ffffff;
  border: 1.16px solid;
  border-color: #ced4da;
  border-radius: 14.68px;
  height: 63px;
  left: 675px;
  position: absolute;
  top: 446px;
  width: 629px;
}

.about-US-page .text-wrapper-127 {
  color: #2125295d;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 195px;
}

.about-US-page .label-description {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 23px;
  left: 41px;
  letter-spacing: 0;
  line-height: 23.2px;
  position: absolute;
  top: 520px;
  white-space: nowrap;
  width: 127px;
}

.about-US-page .button-2 {
  all: unset;
  background-color: #ffa500;
  border-radius: 20.47px;
  box-shadow: 5.79px 5.79px 5.79px #00000014;
  box-sizing: border-box;
  height: 68px;
  left: 555px;
  position: absolute;
  top: 654px;
  width: 212px;
}

.about-US-page .text-wrapper-128 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 600;
  height: 32px;
  left: 71px;
  letter-spacing: 0;
  line-height: 31.3px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.about-US-page .frame-115 {
  position: absolute;
}

.about-US-page .text-wrapper-129 {
  color: #211715;
  font-family: "Poppins", Helvetica;
  font-size: 70px;
  font-weight: 500;
  height: 96px;
  left: 0;
  letter-spacing: 0;
  line-height: 95.5px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.about-US-page .text-wrapper-130 {
  color: #708090;
  font-family: "Poppins", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 41px;
  left: 83px;
  letter-spacing: 0;
  line-height: 40.9px;
  position: absolute;
  text-align: center;
  top: 99px;
  white-space: nowrap;
}

.about-US-page .overlap-group-28 {
  height: 34px;
  position: relative;
  top: -1px;
}

.about-US-page .rectangle-9 {
  background-color: #000000;
  border: 0.69px solid;
  border-radius: 8px;
  height: 31px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 92px;
}

.about-US-page .text-wrapper-131 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 14.4px;
  font-weight: 700;
  left: 14px;
  letter-spacing: -0.57px;
  line-height: 34px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.about-US-page .arrow-4 {
  height: 7px;
  left: 65px;
  position: absolute;
  top: 16px;
  width: 13px;
}

.about-US-page .goessy-logo-pdf-9 {
  height: 60px;
  left: 25px;
  position: absolute;
  top: 8px;
  width: 106px;
}
