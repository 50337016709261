.blog-page {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.blog-page .blog-page-mob {
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
}

.blog-page .overlap-66 {
  position: absolute;
}

.blog-page .frame-116 {
  position: absolute;
}

.blog-page .ellipse-44 {
  background-color: #f0da9080;
  border-radius: 197px/161.45px;
  filter: blur(446.03px);
  height: 323px;
  left: 107px;
  opacity: 0.3;
  position: absolute;
  top: 1718px;
  width: 394px;
}

.blog-page .frame-117 {
  height: 520px;
  left: 200px;
  position: absolute;
  top: 1300px;
  width: 1520px;
}

.blog-page .frame-118 {
  display: block;
  height: 626px;
  left: 0;
  position: absolute;
  top: 0;
  width: 455px;
}

.blog-page .overlap-group-29 {
  height: 626px;
  position: relative;
}

.blog-page .frame-119 {
  height: 626px;
  left: 0;
  position: absolute;
  top: 0;
  width: 455px;
}

.blog-page .rectangle-10 {
  height: 371px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 455px;
}

.blog-page .frame-120 {
  height: 223px;
  left: 0;
  position: absolute;
  top: 391px;
  width: 455px;
}

.blog-page .community-june-2 {
  color: transparent;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 21px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.blog-page .text-wrapper-132 {
  color: #708090;
  font-weight: 700;
}

.blog-page .text-wrapper-133 {
  color: #708090;
}

.blog-page .text-wrapper-134 {
  color: #b5b5b5;
}

.blog-page .unlocking-innovation-3 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 500;
  height: 86px;
  left: 0;
  letter-spacing: -0.41px;
  line-height: 28.6px;
  position: absolute;
  top: 28px;
  width: 455px;
}

.blog-page .frame-121 {
  height: 626px;
  left: 532px;
  position: absolute;
  top: 0;
  width: 455px;
}

.blog-page .div-5 {
  color: #b5b5b5;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 21px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.blog-page .text-wrapper-135 {
  font-weight: 700;
}

.blog-page .text-wrapper-136 {
  color: #b5b5b5;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}

.blog-page .frame-122 {
  height: 626px;
  left: 1065px;
  position: absolute;
  top: 0;
  width: 455px;
}

.blog-page .overlap-67 {
  height: 626px;
  position: relative;
  width: 456px;
}

.blog-page .text-wrapper-137 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 500;
  height: 29px;
  left: 0;
  letter-spacing: -0.41px;
  line-height: 28.6px;
  position: absolute;
  top: 29px;
  width: 455px;
}

.blog-page .frame-123 {
  height: 520px;
  left: 200px;
  position: absolute;
  top: 1920px;
  width: 1520px;
}

.blog-page .text-wrapper-138 {
  color: #708090;
  font-family: "Poppins", Helvetica;
  font-size: 150px;
  font-weight: 600;
  left: 548px;
  letter-spacing: -4.5px;
  line-height: 150px;
  position: absolute;
  text-align: center;
  top: 369px;
  white-space: nowrap;
}

.blog-page .text-wrapper-139 {
  color: #708090;
  font-family: "Josefin Sans", Helvetica;
  font-size: 36px;
  font-weight: 400;
  height: 90px;
  left: 396px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  text-align: center;
  top: 1146px;
  width: 1128px;
}

.blog-page .frame-124 {
  background-color: #ffffff;
  height: 113px;
  left: 1px;
  position: absolute;
  top: 888px;
  width: 1918px;
}

.blog-page .frame-125 {
  background-color: #000000;
  border-radius: 15px;
  height: 73px;
  left: 300px;
  position: absolute;
  top: 21px;
  width: 199px;
}

.blog-page .text-wrapper-140 {
  color: var(--white);
  font-family: "Museo Sans-500", Helvetica;
  font-size: 21px;
  font-weight: 400;
  height: 21px;
  left: 32px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 26px;
  white-space: nowrap;
}

.blog-page .frame-126 {
  background-color: #eaeaea;
  border-radius: 15px;
  height: 73px;
  left: 524px;
  position: absolute;
  top: 21px;
  width: 199px;
}

.blog-page .text-wrapper-141 {
  color: var(--our-grey);
  font-family: "Museo Sans-500", Helvetica;
  font-size: 21px;
  font-weight: 400;
  height: 21px;
  left: 41px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 25px;
  white-space: nowrap;
}

.blog-page .frame-127 {
  background-color: #eaeaea;
  border-radius: 15px;
  height: 73px;
  left: 748px;
  position: absolute;
  top: 21px;
  width: 199px;
}

.blog-page .text-wrapper-142 {
  color: var(--our-grey);
  font-family: "Museo Sans-500", Helvetica;
  font-size: 21px;
  font-weight: 400;
  height: 21px;
  left: 52px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 25px;
  white-space: nowrap;
}

.blog-page .frame-128 {
  background-color: #eaeaea;
  border-radius: 15px;
  height: 73px;
  left: 1196px;
  position: absolute;
  top: 21px;
  width: 199px;
}

.blog-page .text-wrapper-143 {
  color: var(--our-grey);
  font-family: "Museo Sans-500", Helvetica;
  font-size: 21px;
  font-weight: 400;
  height: 21px;
  left: 52px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 26px;
  white-space: nowrap;
}

.blog-page .frame-129 {
  background-color: #eaeaea;
  border-radius: 15px;
  height: 73px;
  left: 972px;
  position: absolute;
  top: 21px;
  width: 199px;
}

.blog-page .text-wrapper-144 {
  color: var(--our-grey);
  font-family: "Museo Sans-500", Helvetica;
  font-size: 21px;
  font-weight: 400;
  height: 21px;
  left: 63px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 25px;
  white-space: nowrap;
}

.blog-page .frame-130 {
  background-color: #eaeaea;
  border-radius: 15px;
  height: 73px;
  left: 1420px;
  position: absolute;
  top: 21px;
  width: 199px;
}

.blog-page .text-wrapper-145 {
  color: var(--our-grey);
  font-family: "Museo Sans-500", Helvetica;
  font-size: 21px;
  font-weight: 400;
  height: 21px;
  left: 63px;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: 26px;
  white-space: nowrap;
}

.blog-page .ellipse-45 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 664px;
  opacity: 0.3;
  position: absolute;
  top: 1149px;
  transform: rotate(-32.69deg);
  width: 394px;
}

.blog-page .decor-44 {
  height: 1233px;
  left: 1272px;
  position: absolute;
  top: 1230px;
  width: 648px;
}

.blog-page .decor-45 {
  height: 1123px;
  left: 0;
  position: absolute;
  top: 0;
  width: 622px;
}

.blog-page .decor-46 {
  height: 1233px;
  left: 1356px;
  position: absolute;
  top: 159px;
  width: 564px;
}

.blog-page .we-re-your {
  font-weight: 400;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.blog-page .goessy-logo-pdf-10 {
  height: 60px;
  left: 94px;
  position: absolute;
  top: 38px;
  width: 106px;
}

.blog-page .text-wrapper-146 {
  color: #708090;
  font-family: "Josefin Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 54px;
  left: 0;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 35px;
  width: 320px;
}

.blog-page .frame-131 {
  position: absolute;
}

.blog-page .frame-132 {
  display: block;
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 63px;
}

.blog-page .text-wrapper-147 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 0;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blog-page .frame-133 {
  height: 33px;
  left: 113px;
  position: absolute;
  top: 0;
  width: 186px;
}

.blog-page .frame-134 {
  height: 33px;
  left: 349px;
  position: absolute;
  top: 0;
  width: 98px;
}

.blog-page .frame-135 {
  display: block;
  height: 33px;
  left: 497px;
  position: absolute;
  top: 0;
  width: 94px;
}

.blog-page .frame-136 {
  height: 33px;
  left: 641px;
  position: absolute;
  top: 0;
  width: 57px;
}

.blog-page .overlap-68 {
  border-radius: 21.34px;
  height: 196px;
  position: relative;
}

.blog-page .item-23 {
  background-color: #ffa500;
  border-radius: 21.34px;
  height: 196px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 350px;
}

.blog-page .goessy-black-logo-9 {
  height: 44px;
  left: 136px;
  position: absolute;
  top: 11px;
  width: 78px;
}

.blog-page .text-wrapper-148 {
  color: #000000;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 9px;
  font-weight: 400;
  height: 9px;
  left: 114px;
  letter-spacing: 0;
  line-height: 9px;
  position: absolute;
  text-align: center;
  top: 169px;
  white-space: nowrap;
}

.blog-page .list-9 {
  height: 32px;
  left: 114px;
  position: absolute;
  top: 128px;
  width: 121px;
}

.blog-page .link-item-23 {
  background-color: #000000;
  border-radius: 15.86px;
  box-shadow: 0px 6.34px 6.34px #0000001a;
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
}

.blog-page .symbol-25 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  height: 18px;
  left: 9px;
  letter-spacing: 0;
  line-height: 18.1px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 12px;
}

.blog-page .link-item-24 {
  background-color: #000000;
  border-radius: 15.86px;
  box-shadow: 0px 6.34px 6.34px #0000001a;
  height: 32px;
  left: 44px;
  position: absolute;
  top: 0;
  width: 32px;
}

.blog-page .symbol-26 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  height: 18px;
  left: 8px;
  letter-spacing: 0;
  line-height: 18.1px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 16px;
}

.blog-page .link-item-25 {
  background-color: #000000;
  border-radius: 15.86px;
  box-shadow: 0px 6.34px 6.34px #0000001a;
  height: 32px;
  left: 89px;
  position: absolute;
  top: 0;
  width: 32px;
}

.blog-page .symbol-27 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  height: 18px;
  left: 6px;
  letter-spacing: 0;
  line-height: 18.1px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 21px;
}

.blog-page .frame-137 {
  height: 56px;
  left: 29px;
  position: absolute;
  top: 63px;
  width: 287px;
}

.blog-page .frame-138 {
  height: 27px;
  left: 14px;
  position: absolute;
  top: 0;
  width: 258px;
}

.blog-page .frame-139 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 39px;
}

.blog-page .overlap-group-30 {
  height: 21px;
  position: relative;
  width: 40px;
}

.blog-page .text-wrapper-149 {
  color: #000000;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 21px;
  left: 0;
  letter-spacing: 0;
  line-height: 20.1px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.blog-page .line-22 {
  height: 1px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 19px;
  width: 14px;
}

.blog-page .frame-140 {
  height: 20px;
  left: 62px;
  position: absolute;
  top: 0;
  width: 114px;
}

.blog-page .overlap-69 {
  height: 21px;
  position: relative;
  width: 117px;
}

.blog-page .frame-141 {
  height: 20px;
  left: 198px;
  position: absolute;
  top: 0;
  width: 60px;
}

.blog-page .overlap-70 {
  height: 21px;
  position: relative;
  width: 61px;
}

.blog-page .frame-142 {
  height: 20px;
  left: 19px;
  position: absolute;
  top: 31px;
  width: 248px;
}

.blog-page .frame-143 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 57px;
}

.blog-page .overlap-group-31 {
  height: 21px;
  position: relative;
  width: 59px;
}

.blog-page .line-23 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 19px;
  width: 14px;
}

.blog-page .frame-144 {
  height: 20px;
  left: 80px;
  position: absolute;
  top: 0;
  width: 29px;
}

.blog-page .overlap-71 {
  height: 21px;
  position: relative;
  top: -1px;
  width: 30px;
}

.blog-page .frame-145 {
  height: 20px;
  left: 132px;
  position: absolute;
  top: 0;
  width: 136px;
}

.blog-page .overlap-72 {
  height: 21px;
  position: relative;
  width: 119px;
}

.blog-page .text-wrapper-150 {
  color: #000000;
  font-family: "Josefin Sans", Helvetica;
  font-size: 50px;
  font-weight: 400;
  height: 45px;
  left: 800px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  text-align: center;
  top: 1077px;
  white-space: nowrap;
}

.blog-page .frame-146 {
  border: 1px solid;
  border-color: #000000;
  border-radius: 10.15px;
  height: 37px;
  left: 264px;
  position: absolute;
  top: 2352px;
  width: 101px;
}

.blog-page .frame-147 {
  height: 11px;
  left: 17px;
  position: relative;
  top: 12px;
  width: 65px;
}

.blog-page .frame-148 {
  height: 11px;
  left: -5px;
  position: relative;
  width: 71px;
}

.blog-page .line-24 {
  height: 9px;
  left: 67px;
  position: absolute;
  top: 1px;
  width: 7px;
}

.blog-page .text-wrapper-151 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 11px;
  left: 0;
  letter-spacing: 0;
  line-height: 10.7px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blog-page .group-41 {
  height: 105px;
  left: 155px;
  position: absolute;
  top: 166px;
  width: 325px;
}

.blog-page .text-wrapper-152 {
  color: #708090;
  font-family: "Poppins", Helvetica;
  font-size: 40px;
  font-weight: 600;
  left: 50px;
  letter-spacing: -1.2px;
  line-height: 45px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.blog-page .we-re-your-2 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 50px;
  left: 0;
  letter-spacing: -0.8px;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 55px;
  width: 321px;
}

.blog-page .ellipse-46 {
  background-color: #f0da9080;
  border-radius: 76.29px/73.84px;
  filter: blur(172.72px);
  height: 148px;
  left: 71px;
  opacity: 0.3;
  position: absolute;
  top: 2115px;
  width: 153px;
}

.blog-page .ellipse-47 {
  background-color: #f0da9080;
  border-radius: 76.29px/73.84px;
  filter: blur(172.72px);
  height: 148px;
  left: 28px;
  opacity: 0.3;
  position: absolute;
  top: 1867px;
  width: 153px;
}

.blog-page .decor-47 {
  height: 274px;
  left: 128px;
  position: absolute;
  top: 11px;
  width: 92px;
}

.blog-page .group-42 {
  height: 2955px;
  left: 0;
  position: absolute;
  top: 0;
  width: 630px;
}

.blog-page .overlap-73 {
  height: 757px;
  left: 292px;
  position: absolute;
  top: 494px;
  width: 210px;
}

.blog-page .decor-48 {
  height: 428px;
  left: 73px;
  position: absolute;
  top: 0;
  width: 138px;
}

.blog-page .decor-49 {
  height: 587px;
  left: 0;
  position: absolute;
  top: 171px;
  width: 210px;
}

.blog-page .decor-50 {
  height: 270px;
  left: 128px;
  position: absolute;
  top: 2454px;
  width: 202px;
}

.blog-page .ellipse-48 {
  background-color: #f0da9080;
  border-radius: 69.13px/90.39px;
  filter: blur(156.51px);
  height: 181px;
  left: 428px;
  opacity: 0.3;
  position: absolute;
  top: 2707px;
  width: 138px;
}

.blog-page .decor-51 {
  height: 358px;
  left: 128px;
  position: absolute;
  top: -86px;
  width: 209px;
}

.blog-page .group-43 {
  height: 687px;
  left: 0;
  position: absolute;
  top: 894px;
  width: 266px;
}

.blog-page .ellipse-49 {
  background-color: #f0da9080;
  border-radius: 69.13px/90.39px;
  filter: blur(156.51px);
  height: 181px;
  left: 128px;
  opacity: 0.3;
  position: absolute;
  top: 506px;
  width: 138px;
}

.blog-page .overlap-group-32 {
  height: 312px;
  left: 0;
  position: absolute;
  top: 0;
  width: 258px;
}

.blog-page .ellipse-50 {
  background-color: #f0da90;
  border-radius: 69.13px/90.39px;
  filter: blur(156.51px);
  height: 181px;
  left: 119px;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 138px;
}

.blog-page .ellipse-51 {
  background-color: #f0da90;
  border-radius: 69.13px/90.39px;
  filter: blur(156.51px);
  height: 181px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 131px;
  width: 138px;
}

.blog-page .decor-52 {
  height: 684px;
  left: 128px;
  position: absolute;
  top: 1835px;
  width: 374px;
}

.blog-page .frame-149 {
  background-color: #ffffff;
  height: 69px;
  left: 128px;
  overflow-x: scroll;
  position: absolute;
  top: 604px;
  width: 374px;
}

.blog-page .frame-150 {
  background-color: #000000;
  border-radius: 9.26px;
  height: 45px;
  left: 12px;
  position: absolute;
  top: 13px;
  width: 123px;
}

.blog-page .text-wrapper-153 {
  color: var(--white);
  font-family: "Museo Sans-500", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 13px;
  left: 20px;
  letter-spacing: 0;
  line-height: 13px;
  position: absolute;
  top: 16px;
  white-space: nowrap;
}

.blog-page .frame-151 {
  background-color: #eaeaea;
  border-radius: 9.26px;
  height: 45px;
  left: 145px;
  position: absolute;
  top: 13px;
  width: 123px;
}

.blog-page .text-wrapper-154 {
  color: var(--our-grey);
  font-family: "Museo Sans-500", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 13px;
  left: 39px;
  letter-spacing: 0;
  line-height: 13px;
  position: absolute;
  top: 16px;
  white-space: nowrap;
}

.blog-page .frame-152 {
  background-color: #eaeaea;
  border-radius: 9.26px;
  height: 45px;
  left: 278px;
  position: absolute;
  top: 13px;
  width: 123px;
}

.blog-page .text-wrapper-155 {
  color: var(--our-grey);
  font-family: "Museo Sans-500", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 13px;
  left: 32px;
  letter-spacing: 0;
  line-height: 13px;
  position: absolute;
  top: 16px;
  white-space: nowrap;
}

.blog-page .frame-153 {
  background-color: #eaeaea;
  border-radius: 9.26px;
  height: 45px;
  left: 543px;
  position: absolute;
  top: 13px;
  width: 123px;
}

.blog-page .frame-154 {
  background-color: #eaeaea;
  border-radius: 9.26px;
  height: 45px;
  left: 410px;
  position: absolute;
  top: 13px;
  width: 123px;
}

.blog-page .frame-155 {
  background-color: #eaeaea;
  border-radius: 9.26px;
  height: 45px;
  left: 676px;
  position: absolute;
  top: 13px;
  width: 123px;
}

.blog-page .frame-156 {
  height: 1414px;
  left: 143px;
  position: absolute;
  top: 888px;
  width: 343px;
}

.blog-page .frame-157 {
  height: 303px;
  left: 0;
  position: absolute;
  top: 0;
  width: 343px;
}

.blog-page .overlap-group-33 {
  height: 303px;
  position: relative;
}

.blog-page .rectangle-11 {
  height: 225px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 340px;
}

.blog-page .frame-158 {
  height: 70px;
  left: 0;
  position: absolute;
  top: 233px;
  width: 342px;
}

.blog-page .div-6 {
  color: #b5b5b5;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 18px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -2px;
}

.blog-page .text-wrapper-156 {
  color: #b5b5b5;
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
}

.blog-page .text-wrapper-157 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 44px;
  left: 0;
  letter-spacing: -0.31px;
  line-height: 21.5px;
  position: absolute;
  top: 26px;
  width: 342px;
}

.blog-page .frame-159 {
  height: 303px;
  left: 0;
  position: absolute;
  top: 353px;
  width: 343px;
}

.blog-page .frame-160 {
  height: 303px;
  left: 0;
  position: absolute;
  top: 706px;
  width: 343px;
}

.blog-page .frame-161 {
  height: 355px;
  left: 0;
  position: absolute;
  top: 1059px;
  width: 343px;
}

.blog-page .overlap-74 {
  height: 355px;
  position: relative;
}

.blog-page .rectangle-wrapper {
  height: 355px;
  left: 0;
  position: absolute;
  top: 0;
  width: 343px;
}

.blog-page .frame-162 {
  height: 113px;
  left: 0;
  position: absolute;
  top: 242px;
  width: 342px;
}

.blog-page .text-wrapper-158 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 87px;
  left: 0;
  letter-spacing: -0.31px;
  line-height: 21.5px;
  position: absolute;
  top: 26px;
  width: 342px;
}

.blog-page .navbar-5 {
  background-color: #ffffff;
  box-shadow: 0px 0px 3.76px #ffa500;
  height: 76px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.blog-page .link-home-5 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 626px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.blog-page .link-our-5 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 726px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.blog-page .link-lokally-AI-5 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 932px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.blog-page .link-business-with-5 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 1063px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.blog-page .link-about-us-5 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 1265px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.blog-page .frame-163 {
  height: 34px;
  left: 264px;
  position: absolute;
  top: 23px;
  width: 92px;
}

.blog-page .overlap-group-34 {
  height: 34px;
  position: relative;
  top: -1px;
}

.blog-page .rectangle-12 {
  background-color: #000000;
  border: 0.69px solid;
  border-radius: 8px;
  height: 31px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 92px;
}

.blog-page .text-wrapper-159 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 14.4px;
  font-weight: 700;
  left: 14px;
  letter-spacing: -0.57px;
  line-height: 34px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.blog-page .arrow-5 {
  height: 7px;
  left: 65px;
  position: absolute;
  top: 16px;
  width: 13px;
}

.blog-page .goessy-logo-pdf-11 {
  height: 60px;
  left: 25px;
  position: absolute;
  top: 8px;
  width: 106px;
}

.blog-page .overlap-75 {
  height: 520px;
  left: -13px;
  position: absolute;
  top: 2540px;
  width: 1733px;
}

.blog-page .frame-164 {
  height: 520px;
  left: 213px;
  position: absolute;
  top: 0;
  width: 1520px;
}

.blog-page .ellipse-52 {
  background-color: #f0da9080;
  border-radius: 197px/161.45px;
  filter: blur(446.03px);
  height: 323px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 96px;
  width: 394px;
}

.blog-page .overlap-76 {
  height: 1387px;
  left: 0;
  position: absolute;
  top: 3160px;
  width: 2053px;
}

.blog-page .overlap-77 {
  height: 1387px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2053px;
}

.blog-page .frame-165 {
  height: 520px;
  left: 200px;
  position: absolute;
  top: 0;
  width: 1520px;
}

.blog-page .row-8 {
  background-image: url(../../../static/img/item-18.png);
  background-size: 100% 100%;
  height: 436px;
  left: 73px;
  position: absolute;
  top: 893px;
  width: 1775px;
}

.blog-page .overlap-78 {
  background-image: url(../../../static/img/item-19.png);
  background-size: 100% 100%;
  height: 502px;
  left: 6px;
  position: relative;
  top: -33px;
  width: 1762px;
}

.blog-page .frame-166 {
  height: 331px;
  left: 323px;
  position: absolute;
  top: 108px;
  width: 1116px;
}

.blog-page .text-wrapper-160 {
  color: #000000;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 423px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 310px;
  white-space: nowrap;
}

.blog-page .list-10 {
  height: 70px;
  left: 424px;
  position: absolute;
  top: 203px;
  width: 268px;
}

.blog-page .link-item-26 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 70px;
}

.blog-page .symbol-28 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 21px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 23px;
}

.blog-page .link-item-27 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 99px;
  position: absolute;
  top: 0;
  width: 70px;
}

.blog-page .symbol-29 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 18px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 33px;
}

.blog-page .link-item-28 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 197px;
  position: absolute;
  top: 0;
  width: 70px;
}

.blog-page .symbol-30 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 15px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 42px;
}

.blog-page .goessy-black-logo-10 {
  height: 132px;
  left: 456px;
  position: absolute;
  top: -44px;
  width: 235px;
}

.blog-page .frame-167 {
  height: 58px;
  left: 307px;
  position: absolute;
  top: 215px;
  width: 1148px;
}

.blog-page .overlap-79 {
  height: 44px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 86px;
}

.blog-page .text-wrapper-161 {
  color: #000000;
  display: block;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 44px;
  left: 0;
  letter-spacing: 0;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.blog-page .line-25 {
  height: 2px;
  left: 3px;
  position: absolute;
  top: 41px;
  width: 30px;
}

.blog-page .overlap-80 {
  height: 44px;
  left: 134px;
  position: absolute;
  top: -1px;
  width: 250px;
}

.blog-page .text-wrapper-162 {
  color: #000000;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 44px;
  left: 0;
  letter-spacing: 0;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.blog-page .overlap-81 {
  height: 44px;
  left: 431px;
  position: absolute;
  top: -1px;
  width: 130px;
}

.blog-page .overlap-group-35 {
  height: 44px;
  left: 611px;
  position: absolute;
  top: -1px;
  width: 126px;
}

.blog-page .line-26 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 41px;
  width: 30px;
}

.blog-page .overlap-82 {
  height: 44px;
  left: 785px;
  position: absolute;
  top: -1px;
  width: 63px;
}

.blog-page .line-27 {
  height: 2px;
  left: 2px;
  position: absolute;
  top: 41px;
  width: 30px;
}

.blog-page .overlap-83 {
  height: 44px;
  left: 897px;
  position: absolute;
  top: -1px;
  width: 255px;
}

.blog-page .decor-53 {
  height: 1233px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 662px;
}

.blog-page .decor-54 {
  height: 451px;
  left: 0;
  position: absolute;
  top: 936px;
  width: 563px;
}

.blog-page .ellipse-53 {
  background-color: #f0da90;
  border-radius: 197px/161.45px;
  filter: blur(446.03px);
  height: 323px;
  left: 1659px;
  opacity: 0.3;
  position: absolute;
  top: 1012px;
  width: 394px;
}

.blog-page .frame-168 {
  border: 2px solid;
  border-color: #000000;
  border-radius: 20px;
  height: 73px;
  left: 860px;
  position: absolute;
  top: 620px;
  width: 199px;
}

.blog-page .frame-169 {
  height: 21px;
  left: 34px;
  position: relative;
  top: 24px;
  width: 128px;
}

.blog-page .line-28 {
  height: 18px;
  left: 113px;
  position: absolute;
  top: 2px;
  width: 15px;
}

.blog-page .text-wrapper-163 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 21px;
  font-weight: 400;
  height: 21px;
  left: 0;
  letter-spacing: 0;
  line-height: 21px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}
