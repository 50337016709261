.terms-and-conditions {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.terms-and-conditions .div {
  background-color: #fff;
  position: relative;
}

.terms-and-conditions .decor {
  width: 506px;
  height: 559px;
  position: absolute;
  top: -3297px;
  left: -6397px;
}

.terms-and-conditions .img {
  width: 506px;
  height: 559px;
  position: absolute;
  top: -1978px;
  left: -6586px;
}

.terms-and-conditions .ellipse {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 3990px;
  left: 1651px;
}

.terms-and-conditions .overlap {
  width: 2150px;
  height: 2870px;
  position: absolute;
  top: -402px;
  left: -131px;
}

.terms-and-conditions .goessy-logo-pdf {
  width: 106px;
  height: 60px;
  position: absolute;
  top: 436px;
  left: 225px;
}

.terms-and-conditions .text-wrapper {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  left: 1254px;
}

.terms-and-conditions .text-wrapper-2 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  left: 1367px;
}

.terms-and-conditions .text-wrapper-3 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  left: 1603px;
}

.terms-and-conditions .text-wrapper-4 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  left: 1751px;
}

.terms-and-conditions .frame {
  width: 2150px;
  height: 2859px;
  position: absolute;
  top: 0;
  left: 0;
}

.terms-and-conditions .overlap-group {
  width: 2051px;
  height: 2859px;
  position: relative;
}

.terms-and-conditions .overlap-group-2 {
  width: 1920px;
  height: 2579px;
  position: absolute;
  top: 0;
  left: 131px;
}

.terms-and-conditions .decor-2 {
  width: 656px;
  height: 1057px;
  position: absolute;
  top: 1482px;
  left: 1264px;
}

.terms-and-conditions .group {
  filter: blur(1569.58px);
  background-color: #f0da904c;
  border-radius: 693.25px / 432.84px;
  width: 1386px;
  height: 866px;
  position: absolute;
  top: 0;
  left: 267px;
}

.terms-and-conditions .decor-3 {
  width: 744px;
  height: 1057px;
  position: absolute;
  top: 461px;
  left: 0;
}

.terms-and-conditions .decor-4 {
  width: 672px;
  height: 1057px;
  position: absolute;
  top: 666px;
  left: 1248px;
}

.terms-and-conditions .group-2 {
  width: 624px;
  height: 946px;
  position: absolute;
  top: 1540px;
  left: 729px;
  transform: rotate(-32.69deg);
}

.terms-and-conditions .ellipse-2 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 700px;
  left: 110px;
}

.terms-and-conditions .ellipse-3 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 420px;
  left: 0;
}

.terms-and-conditions .ellipse-4 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 0;
  left: 230px;
}

.terms-and-conditions .group-3 {
  width: 624px;
  height: 946px;
  position: absolute;
  top: 1913px;
  left: 0;
}

.terms-and-conditions .row {
  background-image: url("item.5f700304.png");
  background-size: 100% 100%;
  width: 1775px;
  height: 436px;
  position: absolute;
  top: 2434px;
  left: 204px;
}

.terms-and-conditions .overlap-2 {
  background-image: url("item-1.30152dd8.png");
  background-size: 100% 100%;
  width: 1762px;
  height: 502px;
  position: relative;
  top: -33px;
  left: 6px;
}

.terms-and-conditions .frame-2 {
  width: 1116px;
  height: 331px;
  position: absolute;
  top: 108px;
  left: 323px;
}

.terms-and-conditions .text-wrapper-5 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 20px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 310px;
  left: 423px;
}

.terms-and-conditions .list {
  width: 268px;
  height: 70px;
  position: absolute;
  top: 203px;
  left: 424px;
}

.terms-and-conditions .link-item {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.terms-and-conditions .symbol {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 23px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 21px;
}

.terms-and-conditions .symbol-wrapper {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 99px;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.terms-and-conditions .symbol-2 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 33px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 18px;
}

.terms-and-conditions .div-wrapper {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 197px;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.terms-and-conditions .symbol-3 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 42px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.terms-and-conditions .goessy-black-logo {
  width: 235px;
  height: 132px;
  position: absolute;
  top: -44px;
  left: 456px;
}

.terms-and-conditions .frame-3 {
  width: 1190px;
  height: 58px;
  position: absolute;
  top: 219px;
  left: 264px;
}

.terms-and-conditions .overlap-3 {
  width: 86px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 0;
}

.terms-and-conditions .text-wrapper-6 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 44px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  left: 0;
}

.terms-and-conditions .line {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 40px;
  left: 3px;
}

.terms-and-conditions .overlap-4 {
  width: 250px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 134px;
}

.terms-and-conditions .overlap-group-3 {
  width: 130px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 431px;
}

.terms-and-conditions .overlap-5 {
  width: 126px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 611px;
}

.terms-and-conditions .line-2 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 40px;
  left: 0;
}

.terms-and-conditions .overlap-6 {
  width: 63px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 785px;
}

.terms-and-conditions .line-3 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 40px;
  left: 2px;
}

.terms-and-conditions .overlap-7 {
  width: 255px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 897px;
}

.terms-and-conditions .frame-4 {
  width: 57px;
  height: 33px;
  position: absolute;
  top: 452px;
  left: 1895px;
}

.terms-and-conditions .text-wrapper-7 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: -1px;
  left: 0;
}

.terms-and-conditions .privacy-policies-wrapper {
  background-color: #fff;
  border-radius: 40px;
  width: 1141px;
  height: 1655px;
  position: absolute;
  top: 579px;
  left: 811px;
  box-shadow: 0 0 10px #00000040;
}

.terms-and-conditions .privacy-policies {
  color: #000;
  letter-spacing: 0;
  width: 1043px;
  height: 1522px;
  font-family: undefined-Regular, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 26px;
  position: absolute;
  top: 74px;
  left: 56px;
}

.terms-and-conditions .ellipse-5 {
  filter: blur(128.42px);
  opacity: .3;
  background-color: #c090f080;
  border-radius: 178.5px / 175.93px;
  width: 357px;
  height: 352px;
  position: absolute;
  top: 3003px;
  left: 732px;
}

.terms-and-conditions .frame-5 {
  background-color: #fff;
}

.terms-and-conditions .p {
  color: #000;
  letter-spacing: 0;
  width: 278px;
  height: 2739px;
  font-family: undefined-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 22px;
  left: 26px;
}

.terms-and-conditions .frame-6 {
  background-color: orange;
  border-radius: 20px;
  width: 441px;
  height: 96px;
  position: absolute;
  top: 95px;
  left: 47px;
}

.terms-and-conditions .text-wrapper-8 {
  color: #22272c;
  letter-spacing: 0;
  white-space: nowrap;
  width: 223px;
  height: 28px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 33px;
  left: 56px;
}

.terms-and-conditions .frame-7 {
  background-color: #fff;
  border-radius: 20px;
  width: 441px;
  height: 96px;
  position: absolute;
  top: 215px;
  left: 47px;
  box-shadow: 0 0 4px #00000040;
}

.terms-and-conditions .frame-8 {
  background-color: #fff;
  border-radius: 20px;
  width: 441px;
  height: 96px;
  position: absolute;
  top: 335px;
  left: 47px;
  box-shadow: 0 0 4px #00000040;
}

.terms-and-conditions .navbar {
  background-color: #fff;
  width: 375px;
  height: 73px;
  position: absolute;
  top: 0;
  left: 0;
}

.terms-and-conditions .link-home {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 626px;
}

.terms-and-conditions .link-our {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 726px;
}

.terms-and-conditions .link-lokally-AI {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 932px;
}

.terms-and-conditions .link-business-with {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 1063px;
}

.terms-and-conditions .link-about-us {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 1265px;
}

.terms-and-conditions .overlap-group-wrapper {
  width: 92px;
  height: 34px;
  position: absolute;
  top: 23px;
  left: 264px;
}

.terms-and-conditions .overlap-group-4 {
  height: 34px;
  position: relative;
  top: -1px;
}

.terms-and-conditions .rectangle {
  background-color: #000;
  border: .69px solid;
  border-radius: 8px;
  width: 92px;
  height: 31px;
  position: absolute;
  top: 3px;
  left: 0;
}

.terms-and-conditions .text-wrapper-9 {
  color: #fff;
  letter-spacing: -.57px;
  white-space: nowrap;
  font-family: Montserrat, Helvetica;
  font-size: 14.4px;
  font-weight: 700;
  line-height: 34px;
  position: absolute;
  top: 0;
  left: 14px;
}

.terms-and-conditions .arrow {
  width: 13px;
  height: 7px;
  position: absolute;
  top: 16px;
  left: 65px;
}

.terms-and-conditions .rectangle-2 {
  background-color: orange;
  width: 375px;
  height: 4px;
  position: absolute;
  top: 69px;
  left: 0;
}

.terms-and-conditions .goessy-logo-pdf-2 {
  width: 106px;
  height: 60px;
  position: absolute;
  top: 8px;
  left: 25px;
}

.terms-and-conditions .frame-wrapper {
  background-color: #fff;
  width: 375px;
  height: 81px;
  position: fixed;
  top: 73px;
  left: 0;
  box-shadow: 0 4px 4px #00000040;
}

.terms-and-conditions .frame-9 {
  height: 41px;
  position: relative;
  top: 20px;
  overflow-x: scroll;
}

.terms-and-conditions .frame-10 {
  background-color: orange;
  border-radius: 12px;
  width: 186px;
  height: 41px;
  position: absolute;
  top: 0;
  left: 10px;
}

.terms-and-conditions .text-wrapper-10 {
  color: #22272c;
  letter-spacing: 0;
  white-space: nowrap;
  height: 28px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 6px;
  left: 22px;
}

.terms-and-conditions .frame-11 {
  background-color: #fff;
  border: 1px solid #708090;
  border-radius: 12px;
  width: 156px;
  height: 41px;
  position: absolute;
  top: 0;
  left: 201px;
}

.terms-and-conditions .text-wrapper-11 {
  color: #22272c;
  letter-spacing: 0;
  white-space: nowrap;
  height: 28px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 5px;
  left: 27px;
}

.terms-and-conditions .frame-12 {
  background-color: #fff;
  border: 1px solid #708090;
  border-radius: 12px;
  width: 156px;
  height: 41px;
  position: absolute;
  top: 0;
  left: 362px;
}

.terms-and-conditions .text-wrapper-12 {
  color: #22272c;
  letter-spacing: 0;
  white-space: nowrap;
  height: 28px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 5px;
  left: 40px;
}

.home-page {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.home-page .homepage-mobile {
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.home-page .overlap-8 {
  position: absolute;
}

.home-page .overlap-wrapper {
  background-image: url("item-2.d8ce4467.png");
  background-size: 100% 100%;
  width: 1775px;
  height: 436px;
  position: absolute;
  top: 146px;
  left: 73px;
}

.home-page .overlap-9 {
  background-image: url("item-3.06b0b520.png");
  background-size: 100% 100%;
  width: 1762px;
  height: 502px;
  position: relative;
  top: -33px;
  left: 6px;
}

.home-page .frame-13 {
  width: 1116px;
  height: 331px;
  position: absolute;
  top: 108px;
  left: 323px;
}

.home-page .text-wrapper-13 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 20px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 310px;
  left: 423px;
}

.home-page .list-2 {
  width: 268px;
  height: 70px;
  position: absolute;
  top: 203px;
  left: 424px;
}

.home-page .link-item-2 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.home-page .symbol-4 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 23px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 21px;
}

.home-page .link-item-3 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 99px;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.home-page .symbol-5 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 33px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 18px;
}

.home-page .link-item-4 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 197px;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.home-page .symbol-6 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 42px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.home-page .goessy-black-logo-2 {
  width: 235px;
  height: 132px;
  position: absolute;
  top: -44px;
  left: 456px;
}

.home-page .frame-14 {
  width: 1190px;
  height: 58px;
  position: absolute;
  top: 219px;
  left: 264px;
}

.home-page .overlap-10 {
  width: 86px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 0;
}

.home-page .text-wrapper-14 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 44px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .line-4 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 40px;
  left: 3px;
}

.home-page .overlap-11 {
  width: 250px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 134px;
}

.home-page .overlap-12 {
  width: 130px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 431px;
}

.home-page .text-wrapper-15 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 44px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 43.8px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .overlap-group-5 {
  width: 126px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 611px;
}

.home-page .line-5 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 40px;
  left: 0;
}

.home-page .overlap-13 {
  width: 63px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 785px;
}

.home-page .line-6 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 40px;
  left: 2px;
}

.home-page .overlap-14 {
  width: 255px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 897px;
}

.home-page .decor-5 {
  position: absolute;
}

.home-page .group-4 {
  width: 493px;
  height: 1468px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .ellipse-6 {
  filter: blur(50px);
  opacity: .3;
  background-color: #c090f080;
  border-radius: 69.5px / 68.5px;
  width: 139px;
  height: 137px;
  position: absolute;
  top: 1331px;
  left: 158px;
}

.home-page .overlap-group-6 {
  width: 413px;
  height: 537px;
  position: absolute;
  top: -76px;
  left: 25px;
}

.home-page .decor-6 {
  width: 211px;
  height: 294px;
  position: absolute;
  top: 208px;
  left: 203px;
}

.home-page .decor-7 {
  width: 268px;
  height: 284px;
  position: absolute;
  top: 0;
  left: 39px;
}

.home-page .decor-8 {
  width: 216px;
  height: 294px;
  position: absolute;
  top: 33px;
  left: 197px;
}

.home-page .group-5 {
  width: 219px;
  height: 203px;
  position: absolute;
  top: 334px;
  left: 0;
}

.home-page .ellipse-7 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 69.13px / 26.35px;
  width: 138px;
  height: 53px;
  position: absolute;
  top: 150px;
  left: 39px;
}

.home-page .ellipse-8 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 69.13px / 26.35px;
  width: 138px;
  height: 53px;
  position: absolute;
  top: 90px;
  left: 0;
}

.home-page .ellipse-9 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 69.13px / 26.35px;
  width: 138px;
  height: 53px;
  position: absolute;
  top: 0;
  left: 81px;
}

.home-page .decor-9 {
  width: 202px;
  height: 294px;
  position: absolute;
  top: 938px;
  left: 64px;
}

.home-page .group-6 {
  width: 502px;
  height: 1017px;
  position: absolute;
  top: 1033px;
  left: 0;
}

.home-page .overlap-15 {
  width: 374px;
  height: 645px;
  position: absolute;
  top: -87px;
  left: 64px;
}

.home-page .decor-10 {
  width: 211px;
  height: 314px;
  position: absolute;
  top: 254px;
  left: 164px;
}

.home-page .decor-11 {
  width: 268px;
  height: 314px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .decor-12 {
  width: 209px;
  height: 314px;
  position: absolute;
  top: 331px;
  left: 0;
}

.home-page .group-7 {
  width: 180px;
  height: 235px;
  position: absolute;
  top: 386px;
  left: 0;
}

.home-page .ellipse-10 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 69.13px / 30.59px;
  width: 138px;
  height: 61px;
  position: absolute;
  top: 174px;
  left: 0;
}

.home-page .ellipse-11 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 69.13px / 30.59px;
  width: 138px;
  height: 61px;
  position: absolute;
  top: 0;
  left: 42px;
}

.home-page .decor-13 {
  width: 202px;
  height: 238px;
  position: absolute;
  top: 790px;
  left: 64px;
}

.home-page .ellipse-12 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 69.13px / 30.59px;
  width: 138px;
  height: 61px;
  position: absolute;
  top: 916px;
  left: 363px;
}

.home-page .ellipse-13 {
  opacity: .3;
  position: absolute;
}

.home-page .decor-14 {
  width: 92px;
  height: 274px;
  position: absolute;
  top: 69px;
  left: 64px;
}

.home-page .decor-15 {
  width: 151px;
  height: 274px;
  position: absolute;
  top: 122px;
  left: 287px;
}

.home-page .content {
  align-items: flex-start;
  gap: 12.76px;
  width: 344px;
  height: 357px;
  display: flex;
  position: absolute;
  top: 32px;
  left: 79px;
}

.home-page .item {
  background-color: #0003;
  background-image: url("item-3-2x.cb70c380.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 43px;
  flex: 1;
  align-self: stretch;
  position: relative;
  overflow: hidden;
}

.home-page .revaluing-now-travel {
  color: #fff;
  letter-spacing: -1.44px;
  width: 244px;
  font-family: Josefin Sans, Helvetica;
  font-size: 36px;
  font-weight: 300;
  line-height: 50px;
  position: absolute;
  top: 102px;
  left: 50px;
}

.home-page .item-2 {
  background-image: url("item-2.f8082183.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 22px;
  width: 345px;
  height: 180px;
  position: absolute;
  top: 409px;
  left: 79px;
  overflow: hidden;
}

.home-page .overlap-16 {
  height: 180px;
  position: relative;
}

.home-page .image {
  object-fit: cover;
  width: 178px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 166px;
}

.home-page .vector {
  width: 322px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .new-style-new {
  color: #000;
  letter-spacing: -.89px;
  font-family: Josefin Sans, Helvetica;
  font-size: 22.3px;
  font-weight: 300;
  line-height: 22px;
  position: absolute;
  top: 93px;
  left: 13px;
}

.home-page .row-2 {
  align-items: flex-start;
  gap: 12.76px;
  width: 345px;
  height: 164px;
  display: flex;
  position: absolute;
  top: 609px;
  left: 79px;
}

.home-page .item-3 {
  background-color: #000;
  border-radius: 21.27px;
  flex: 1;
  align-self: stretch;
  position: relative;
  overflow: hidden;
}

.home-page .vector-2 {
  width: 115px;
  height: 100px;
  position: absolute;
  top: -1288px;
  left: -1190px;
}

.home-page .overlap-group-7 {
  background-image: url("vector-2.43e679be.svg");
  background-size: 100% 100%;
  width: 166px;
  height: 108px;
  position: absolute;
  top: 56px;
  left: 0;
}

.home-page .text-wrapper-16 {
  color: #fff;
  letter-spacing: -.68px;
  width: 124px;
  font-family: Poppins, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  position: absolute;
  top: 49px;
  left: 13px;
}

.home-page .experince {
  color: #fff;
  letter-spacing: -1.44px;
  white-space: nowrap;
  width: 105px;
  font-family: Josefin Sans, Helvetica;
  font-size: 36px;
  font-weight: 600;
  line-height: 36px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.home-page .span {
  color: #dca3ff;
  letter-spacing: -.52px;
}

.home-page .text-wrapper-17 {
  color: #dca3ff;
  letter-spacing: -.18px;
  font-size: 21.3px;
  line-height: 25.5px;
}

.home-page .content-2 {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 19px;
  left: 125px;
}

.home-page .item-4 {
  background-color: #ffeb4d;
  border-radius: 21.27px;
  flex: 1;
  align-self: stretch;
  position: relative;
  overflow: hidden;
}

.home-page .overlap-17 {
  width: 154px;
  height: 116px;
  position: absolute;
  top: 48px;
  left: 12px;
}

.home-page .vector-3 {
  width: 117px;
  height: 116px;
  position: absolute;
  top: 0;
  left: 37px;
}

.home-page .text-wrapper-18 {
  color: #000;
  letter-spacing: -.68px;
  width: 96px;
  font-family: Poppins, Helvetica;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  position: absolute;
  top: 57px;
  left: 2px;
}

.home-page .explore {
  color: #000;
  letter-spacing: -1.44px;
  white-space: nowrap;
  width: 78px;
  font-family: Josefin Sans, Helvetica;
  font-size: 36px;
  font-weight: 600;
  line-height: 36px;
  position: absolute;
  top: 18px;
  left: 0;
}

.home-page .text-wrapper-19 {
  color: orange;
  letter-spacing: -.52px;
}

.home-page .text-wrapper-20 {
  color: orange;
  letter-spacing: -.12px;
  font-size: 17px;
}

.home-page .frame-15 {
  width: 350px;
  height: 346px;
  position: absolute;
  top: 1080px;
  left: 76px;
}

.home-page .item-5 {
  background-color: #ffeb4d;
  border-radius: 21.34px;
  width: 350px;
  height: 164px;
  position: absolute;
  top: 182px;
  left: 0;
  overflow: hidden;
}

.home-page .overlap-18 {
  width: 305px;
  height: 106px;
  position: absolute;
  top: 59px;
  left: 17px;
}

.home-page .vector-4 {
  width: 222px;
  height: 106px;
  position: absolute;
  top: 0;
  left: 83px;
}

.home-page .text-wrapper-21 {
  color: #000;
  letter-spacing: -1.2px;
  white-space: nowrap;
  font-family: Grandstander, Helvetica;
  font-size: 30px;
  font-weight: 600;
  line-height: 35.3px;
  position: absolute;
  top: 55px;
  left: 0;
}

.home-page .overlap-group-8 {
  width: 319px;
  height: 36px;
  position: absolute;
  top: 15px;
  left: 17px;
}

.home-page .content-3 {
  width: 319px;
  height: 19px;
  position: absolute;
  top: 2px;
  left: 0;
}

.home-page .text-wrapper-22 {
  color: #000;
  letter-spacing: -.56px;
  width: 189px;
  font-family: Poppins, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.5px;
  position: absolute;
  top: -1px;
  left: 0;
}

.home-page .frame-16 {
  background-color: orange;
  border-radius: 10px;
  width: 108px;
  height: 36px;
  position: absolute;
  top: 0;
  left: 210px;
  box-shadow: 0 0 2.57px #deaaf0;
}

.home-page .text-wrapper-23 {
  color: #000;
  letter-spacing: -.4px;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 10px;
  font-weight: 700;
  line-height: 19.2px;
  position: absolute;
  top: 8px;
  left: 14px;
}

.home-page .group-8 {
  width: 14px;
  height: 13px;
  position: absolute;
  top: 13px;
  left: 79px;
}

.home-page .item-wrapper {
  align-items: flex-start;
  gap: 12.8px;
  width: 346px;
  height: 164px;
  display: flex;
  position: absolute;
  top: 0;
  left: 2px;
}

.home-page .item-6 {
  background-color: #000;
  border-radius: 21.34px;
  flex: 1;
  align-self: stretch;
  position: relative;
  overflow: hidden;
}

.home-page .vector-5 {
  width: 115px;
  height: 100px;
  position: absolute;
  top: -1758px;
  left: -1099px;
}

.home-page .overlap-19 {
  width: 329px;
  height: 103px;
  position: absolute;
  top: 61px;
  left: 17px;
}

.home-page .vector-6 {
  width: 149px;
  height: 103px;
  position: absolute;
  top: 0;
  left: 180px;
}

.home-page .text-wrapper-24 {
  color: #fff;
  letter-spacing: -1.2px;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 30px;
  font-weight: 600;
  line-height: 35.3px;
  position: absolute;
  top: 46px;
  left: 0;
}

.home-page .overlap-20 {
  width: 316px;
  height: 36px;
  position: absolute;
  top: 15px;
  left: 17px;
}

.home-page .content-4 {
  width: 315px;
  height: 21px;
  position: absolute;
  top: 2px;
  left: 0;
}

.home-page .text-wrapper-25 {
  color: #dca3ff;
  letter-spacing: -.56px;
  width: 189px;
  font-family: Poppins, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.5px;
  position: absolute;
  top: -3px;
  left: 0;
}

.home-page .group-9 {
  width: 110px;
  height: 36px;
  position: absolute;
  top: 0;
  left: 206px;
}

.home-page .overlap-group-9 {
  background-color: #dda6f8;
  border-radius: 10px;
  width: 108px;
  height: 36px;
  position: relative;
  box-shadow: 0 0 2.57px #deaaf0;
}

.home-page .vector-7 {
  width: 11px;
  height: 10px;
  position: absolute;
  top: 14px;
  left: 79px;
}

.home-page .text-wrapper-26 {
  color: #000;
  letter-spacing: -.4px;
  width: 63px;
  font-family: Poppins, Helvetica;
  font-size: 10px;
  font-weight: 700;
  line-height: 19.2px;
  position: absolute;
  top: 9px;
  left: 12px;
}

.home-page .group-10 {
  width: 352px;
  height: 213px;
  position: absolute;
  top: 1526px;
  left: 76px;
}

.home-page .text-wrapper-27 {
  color: #708090;
  letter-spacing: 0;
  text-align: center;
  width: 350px;
  height: 145px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: 28.6px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .cta-link-wrapper {
  background-color: var(--black);
  border-radius: 12px;
  width: 257px;
  height: 48px;
  position: absolute;
  top: 165px;
  left: 47px;
  box-shadow: 0 0 15px .31px #00000040;
}

.home-page .cta-link {
  width: 226px;
  height: 18px;
  position: relative;
  top: 15px;
  left: 15px;
}

.home-page .cta-link-wrapper-2 {
  height: 18px;
  position: relative;
}

.home-page .text-wrapper-28 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#b16cea 14.06%, #ff5e69 44.79%, #ff8a56 71.88%, #ffa84b 100%) text;
  height: 18px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-background-clip: text !important;
}

.home-page .vector-8 {
  width: 13px;
  height: 13px;
  position: absolute;
  top: 2px;
  left: 212px;
}

.home-page .group-11 {
  width: 291px;
  height: 167px;
  position: absolute;
  top: 873px;
  left: 107px;
}

.home-page .text-wrapper-29 {
  color: #211715;
  letter-spacing: -1.2px;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 30px;
}

.home-page .text-wrapper-30 {
  color: #211715;
  letter-spacing: -.88px;
  text-align: center;
  width: 287px;
  font-family: Josefin Sans, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.5px;
  position: absolute;
  top: 60px;
  left: 0;
}

.home-page .frame-17 {
  width: 350px;
  height: 196px;
  position: absolute;
  top: 1839px;
  left: 76px;
}

.home-page .overlap-21 {
  border-radius: 21.34px;
  height: 196px;
  position: relative;
}

.home-page .item-7 {
  background-color: orange;
  border-radius: 21.34px;
  width: 350px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.home-page .goessy-black-logo-3 {
  width: 78px;
  height: 44px;
  position: absolute;
  top: 11px;
  left: 136px;
}

.home-page .text-wrapper-31 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 9px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 9px;
  font-weight: 400;
  line-height: 9px;
  position: absolute;
  top: 169px;
  left: 114px;
}

.home-page .list-3 {
  width: 121px;
  height: 32px;
  position: absolute;
  top: 128px;
  left: 114px;
}

.home-page .link-item-5 {
  background-color: #000;
  border-radius: 15.86px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 6.34px 6.34px #0000001a;
}

.home-page .symbol-7 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 12px;
  height: 18px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  line-height: 18.1px;
  position: absolute;
  top: 6px;
  left: 9px;
}

.home-page .link-item-6 {
  background-color: #000;
  border-radius: 15.86px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 44px;
  box-shadow: 0 6.34px 6.34px #0000001a;
}

.home-page .symbol-8 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 16px;
  height: 18px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  line-height: 18.1px;
  position: absolute;
  top: 6px;
  left: 8px;
}

.home-page .link-item-7 {
  background-color: #000;
  border-radius: 15.86px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 89px;
  box-shadow: 0 6.34px 6.34px #0000001a;
}

.home-page .symbol-9 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 21px;
  height: 18px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  line-height: 18.1px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.home-page .frame-18 {
  width: 287px;
  height: 56px;
  position: absolute;
  top: 63px;
  left: 29px;
}

.home-page .frame-19 {
  width: 258px;
  height: 27px;
  position: absolute;
  top: 0;
  left: 14px;
}

.home-page .frame-20 {
  width: 39px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .overlap-group-10 {
  width: 40px;
  height: 21px;
  position: relative;
}

.home-page .text-wrapper-32 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 21px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.1px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .line-7 {
  object-fit: cover;
  width: 14px;
  height: 1px;
  position: absolute;
  top: 19px;
  left: 1px;
}

.home-page .frame-21 {
  width: 114px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 62px;
}

.home-page .overlap-22 {
  width: 117px;
  height: 21px;
  position: relative;
}

.home-page .frame-22 {
  width: 60px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 198px;
}

.home-page .overlap-23 {
  width: 61px;
  height: 21px;
  position: relative;
}

.home-page .frame-23 {
  width: 248px;
  height: 20px;
  position: absolute;
  top: 31px;
  left: 19px;
}

.home-page .frame-24 {
  width: 57px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .overlap-group-11 {
  width: 59px;
  height: 21px;
  position: relative;
}

.home-page .line-8 {
  object-fit: cover;
  width: 14px;
  height: 1px;
  position: absolute;
  top: 19px;
  left: 0;
}

.home-page .frame-25 {
  width: 29px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 80px;
}

.home-page .overlap-24 {
  width: 30px;
  height: 21px;
  position: relative;
  top: -1px;
}

.home-page .frame-26 {
  width: 136px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 132px;
}

.home-page .overlap-25 {
  width: 119px;
  height: 21px;
  position: relative;
}

.home-page .decor-16 {
  width: 127px;
  height: 218px;
  position: absolute;
  top: 681px;
  left: 311px;
}

.home-page .group-12 {
  position: absolute;
}

.home-page .ellipse-14 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 700px;
  left: 110px;
}

.home-page .ellipse-15 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 420px;
  left: 0;
}

.home-page .ellipse-16 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 0;
  left: 230px;
}

.home-page .overlap-26 {
  width: 2051px;
  height: 2466px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .decor-17 {
  width: 656px;
  height: 1057px;
  position: absolute;
  top: 1046px;
  left: 1395px;
}

.home-page .group-13 {
  width: 1150px;
  height: 457px;
  position: absolute;
  top: 2009px;
  left: 517px;
}

.home-page .text-wrapper-33 {
  color: #708090;
  letter-spacing: 0;
  text-align: center;
  width: 1148px;
  height: 312px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 60px;
  font-weight: 700;
  line-height: 78px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .group-14 {
  background-color: var(--black);
  border-radius: 23.51px;
  width: 495px;
  height: 91px;
  position: absolute;
  top: 365px;
  left: 327px;
  box-shadow: 0 0 15px .58px #00000040;
}

.home-page .cta-link-2 {
  width: 402px;
  height: 35px;
  position: relative;
  top: 29px;
  left: 47px;
}

.home-page .cta-link-wrapper-3 {
  height: 35px;
  position: relative;
}

.home-page .text-wrapper-34 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: 0;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#b16cea 14.06%, #ff5e69 44.79%, #ff8a56 71.88%, #ffa84b 100%) text;
  height: 30px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 2px;
  -webkit-background-clip: text !important;
}

.home-page .vector-9 {
  width: 20px;
  height: 26px;
  position: absolute;
  top: 5px;
  left: 376px;
}

.home-page .goessy-logo-pdf-3 {
  width: 106px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 225px;
}

.home-page .decor-18 {
  width: 744px;
  height: 1057px;
  position: absolute;
  top: 25px;
  left: 131px;
}

.home-page .decor-19 {
  width: 672px;
  height: 1057px;
  position: absolute;
  top: 230px;
  left: 1379px;
}

.home-page .content-5 {
  align-items: flex-start;
  gap: 23.67px;
  width: 1320px;
  height: 662px;
  display: flex;
  position: absolute;
  top: 126px;
  left: 431px;
}

.home-page .revaluing-now-travel-wrapper {
  background-color: #0003;
  background-image: url("item-5.e58ea6d9.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 75.87px;
  flex: 1;
  align-self: stretch;
  position: relative;
  overflow: hidden;
}

.home-page .revaluing-now-travel-2 {
  color: #fff;
  letter-spacing: -2.8px;
  width: 444px;
  font-family: Josefin Sans, Helvetica;
  font-size: 70px;
  font-weight: 300;
  line-height: 91px;
  position: absolute;
  top: 193px;
  left: 64px;
}

.home-page .left {
  align-self: stretch;
  width: 639.02px;
  position: relative;
  overflow: hidden;
}

.home-page .item-8 {
  background-image: url("item-4.aed97aa2.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 39.45px;
  width: 639px;
  height: 334px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.home-page .overlap-group-12 {
  height: 334px;
  position: relative;
}

.home-page .image-2 {
  object-fit: cover;
  width: 330px;
  height: 334px;
  position: absolute;
  top: 0;
  left: 309px;
}

.home-page .vector-10 {
  width: 598px;
  height: 334px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .new-style-new-2 {
  color: #211715;
  letter-spacing: -1.44px;
  width: 246px;
  font-family: Poppins, Helvetica;
  font-size: 36px;
  font-weight: 200;
  line-height: 40px;
  position: absolute;
  top: 180px;
  left: 31px;
}

.home-page .row-3 {
  align-items: flex-start;
  gap: 23.67px;
  width: 639px;
  height: 304px;
  display: flex;
  position: absolute;
  top: 358px;
  left: 0;
}

.home-page .item-9 {
  background-color: #000;
  border-radius: 39.45px;
  flex: 1;
  align-self: stretch;
  position: relative;
  overflow: hidden;
}

.home-page .vector-11 {
  width: 213px;
  height: 185px;
  position: absolute;
  top: -1024px;
  left: -7px;
}

.home-page .overlap-group-13 {
  background-image: url("vector-3-2.277ec30b.svg");
  background-size: 100% 100%;
  width: 308px;
  height: 191px;
  position: absolute;
  top: 112px;
  left: 0;
}

.home-page .text-wrapper-35 {
  color: #fff;
  letter-spacing: -1.28px;
  width: 243px;
  font-family: Poppins, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 41.1px;
  position: absolute;
  top: 90px;
  left: 31px;
}

.home-page .experience {
  color: #fff;
  letter-spacing: -2.8px;
  white-space: nowrap;
  width: 243px;
  font-family: Josefin Sans, Helvetica;
  font-size: 70px;
  font-weight: 600;
  line-height: 47.3px;
  position: absolute;
  top: 29px;
  left: 27px;
}

.home-page .text-wrapper-36 {
  color: #dca3ff;
  letter-spacing: -1.96px;
}

.home-page .text-wrapper-37 {
  color: #dca3ff;
  letter-spacing: -.64px;
  font-size: 40px;
}

.home-page .content-6 {
  width: 245px;
  height: 39px;
  position: absolute;
  top: 32px;
  left: 32px;
}

.home-page .item-10 {
  background-color: #ffeb4d;
  border-radius: 39.45px;
  flex: 1;
  align-self: stretch;
  position: relative;
  overflow: hidden;
}

.home-page .overlap-27 {
  width: 281px;
  height: 219px;
  position: absolute;
  top: 85px;
  left: 27px;
}

.home-page .vector-12 {
  width: 218px;
  height: 219px;
  position: absolute;
  top: 0;
  left: 62px;
}

.home-page .text-wrapper-38 {
  color: #000;
  letter-spacing: -1.28px;
  width: 231px;
  font-family: Poppins, Helvetica;
  font-size: 32px;
  font-weight: 600;
  line-height: 41.1px;
  position: absolute;
  top: 118px;
  left: 5px;
}

.home-page .explore-2 {
  color: #000;
  letter-spacing: -2.8px;
  white-space: nowrap;
  width: 231px;
  font-family: Josefin Sans, Helvetica;
  font-size: 70px;
  font-weight: 600;
  line-height: 47.3px;
  position: absolute;
  top: 56px;
  left: 0;
}

.home-page .text-wrapper-39 {
  color: orange;
  letter-spacing: -1.96px;
}

.home-page .text-wrapper-40 {
  color: orange;
  letter-spacing: -.64px;
  font-size: 40px;
}

.home-page .content-7 {
  width: 245px;
  height: 36px;
  position: absolute;
  top: 32px;
  left: 32px;
}

.home-page .group-15 {
  width: 624px;
  height: 946px;
  position: absolute;
  top: 1477px;
  left: 0;
}

.home-page .group-16 {
  width: 624px;
  height: 946px;
  position: absolute;
  top: 1104px;
  left: 860px;
  transform: rotate(-32.69deg);
}

.home-page .group-17 {
  width: 1514px;
  height: 748px;
  position: absolute;
  top: 1061px;
  left: 323px;
}

.home-page .frame-27 {
  width: 1514px;
  height: 348px;
  position: absolute;
  top: 400px;
  left: 0;
}

.home-page .row-wrapper {
  width: 735px;
  height: 345px;
  position: absolute;
  top: 3px;
  left: 0;
}

.home-page .row-4 {
  align-items: flex-start;
  gap: 27.08px;
  width: 731px;
  height: 348px;
  display: flex;
  position: relative;
  left: 4px;
}

.home-page .item-11 {
  background-color: #000;
  border-radius: 45.13px;
  flex: 1;
  align-self: stretch;
  position: relative;
  overflow: hidden;
}

.home-page .vector-13 {
  width: 244px;
  height: 212px;
  position: absolute;
  top: -1970px;
  left: 977px;
}

.home-page .overlap-group-14 {
  width: 695px;
  height: 219px;
  position: absolute;
  top: 129px;
  left: 36px;
}

.home-page .vector-14 {
  width: 303px;
  height: 219px;
  position: absolute;
  top: 0;
  left: 392px;
}

.home-page .text-wrapper-41 {
  color: #fff;
  letter-spacing: -2.4px;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 60px;
  font-weight: 600;
  line-height: 74.6px;
  position: absolute;
  top: 98px;
  left: 0;
}

.home-page .content-8 {
  width: 665px;
  height: 45px;
  position: absolute;
  top: 36px;
  left: 36px;
}

.home-page .text-wrapper-42 {
  color: #dca3ff;
  letter-spacing: -1.04px;
  width: 400px;
  font-family: Poppins, Helvetica;
  font-size: 26px;
  font-weight: 600;
  line-height: 36.9px;
  position: absolute;
  top: 1px;
  left: 0;
}

.home-page .frame-28 {
  background-color: #dda6f8;
  border-radius: 19.82px;
  width: 199px;
  height: 69px;
  position: absolute;
  top: 4px;
  left: 457px;
  box-shadow: 0 0 4.95px #deaaf0;
}

.home-page .text-wrapper-43 {
  color: #000;
  letter-spacing: -.8px;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 36.9px;
  position: absolute;
  top: 15px;
  left: 26px;
}

.home-page .frame-29 {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 22px;
  left: 157px;
}

.home-page .item-12 {
  background-color: #ffeb4d;
  border-radius: 45.13px;
  width: 740px;
  height: 348px;
  position: absolute;
  top: 0;
  left: 775px;
  overflow: hidden;
}

.home-page .overlap-28 {
  width: 642px;
  height: 238px;
  position: absolute;
  top: 110px;
  left: 36px;
}

.home-page .vector-15 {
  width: 469px;
  height: 238px;
  position: absolute;
  top: 0;
  left: 173px;
}

.home-page .text-wrapper-44 {
  color: #000;
  letter-spacing: -2.4px;
  white-space: nowrap;
  font-family: Grandstander, Helvetica;
  font-size: 60px;
  font-weight: 600;
  line-height: 74.6px;
  position: absolute;
  top: 132px;
  left: 0;
}

.home-page .content-9 {
  width: 675px;
  height: 41px;
  position: absolute;
  top: 36px;
  left: 36px;
}

.home-page .text-wrapper-45 {
  color: #000;
  letter-spacing: -1.04px;
  width: 400px;
  font-family: Poppins, Helvetica;
  font-size: 26px;
  font-weight: 600;
  line-height: 36.9px;
  position: absolute;
  top: 1px;
  left: 0;
}

.home-page .frame-30 {
  background-color: orange;
  border-radius: 19.82px;
  width: 199px;
  height: 69px;
  position: absolute;
  top: 5px;
  left: 464px;
  box-shadow: 0 0 4.95px orange;
}

.home-page .direct-instagram {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 25px;
  left: 157px;
}

.home-page .group-18 {
  width: 1324px;
  height: 350px;
  position: absolute;
  top: 0;
  left: 108px;
}

.home-page .text-wrapper-46 {
  color: var(--black);
  letter-spacing: -2.8px;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 70px;
  font-weight: 500;
  line-height: 90px;
  position: absolute;
  top: 0;
  left: 441px;
}

.home-page .text-wrapper-47 {
  color: var(--black);
  letter-spacing: -2px;
  text-align: center;
  width: 1320px;
  font-family: Josefin Sans, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 70px;
  position: absolute;
  top: 140px;
  left: 0;
}

.home-page .text-wrapper-48 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 15px;
  left: 1254px;
}

.home-page .text-wrapper-49 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 15px;
  left: 1603px;
}

.home-page .text-wrapper-50 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  display: block;
  position: absolute;
  top: 15px;
  left: 1751px;
}

.home-page .frame-31 {
  width: 57px;
  height: 33px;
  display: block;
  position: absolute;
  top: 16px;
  left: 1895px;
}

.home-page .text-wrapper-51 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  display: block;
  position: absolute;
  top: -1px;
  left: 0;
}

.home-page .overlap-29 {
  width: 375px;
  height: 76px;
  position: absolute;
  top: 0;
  left: 0;
}

.home-page .navbar-2 {
  background-color: #fff;
  width: 375px;
  height: 76px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 3.76px orange;
}

.home-page .link-home-2 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 626px;
}

.home-page .link-our-2 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 726px;
}

.home-page .link-lokally-AI-2 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 932px;
}

.home-page .link-business-with-2 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 1063px;
}

.home-page .link-about-us-2 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 1265px;
}

.home-page .frame-32 {
  width: 92px;
  height: 34px;
  position: absolute;
  top: 23px;
  left: 264px;
}

.home-page .overlap-group-15 {
  height: 34px;
  position: relative;
  top: -1px;
}

.home-page .rectangle-3 {
  background-color: #000;
  border: .69px solid;
  border-radius: 8px;
  width: 92px;
  height: 31px;
  position: absolute;
  top: 3px;
  left: 0;
}

.home-page .text-wrapper-52 {
  color: #fff;
  letter-spacing: -.57px;
  white-space: nowrap;
  font-family: Montserrat, Helvetica;
  font-size: 14.4px;
  font-weight: 700;
  line-height: 34px;
  position: absolute;
  top: 0;
  left: 14px;
}

.home-page .arrow-2 {
  width: 13px;
  height: 7px;
  position: absolute;
  top: 16px;
  left: 65px;
}

.home-page .goessy-logo-pdf-4 {
  width: 106px;
  height: 60px;
  position: absolute;
  top: 8px;
  left: 25px;
}

.home-page .decor-20 {
  width: 506px;
  height: 559px;
  position: absolute;
  top: 3378px;
  left: 1222px;
}

.home-page .ellipse-17 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 3990px;
  left: 1651px;
}

.blog-page-inside {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.blog-page-inside .div-2 {
  background-color: #fff;
  width: 1920px;
  height: 5798px;
  position: relative;
  overflow: hidden;
}

.blog-page-inside .overlap-30 {
  width: 2066px;
  height: 5572px;
  position: absolute;
  top: 148px;
  left: -13px;
}

.blog-page-inside .ellipse-18 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 1001px;
  left: 677px;
  transform: rotate(-32.69deg);
}

.blog-page-inside .decor-21 {
  width: 648px;
  height: 1233px;
  position: absolute;
  top: 1082px;
  left: 1285px;
}

.blog-page-inside .decor-22 {
  width: 563px;
  height: 1233px;
  position: absolute;
  top: 3948px;
  left: 13px;
}

.blog-page-inside .ellipse-19 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 161.45px;
  width: 394px;
  height: 323px;
  position: absolute;
  top: 4024px;
  left: 1672px;
}

.blog-page-inside .decor-23 {
  width: 655px;
  height: 1233px;
  position: absolute;
  top: 29px;
  left: 1278px;
}

.blog-page-inside .ellipse-20 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 197px / 161.45px;
  width: 394px;
  height: 323px;
  position: absolute;
  top: 2489px;
  left: 0;
}

.blog-page-inside .ellipse-21 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 197px / 161.45px;
  width: 394px;
  height: 323px;
  position: absolute;
  top: 1570px;
  left: 120px;
}

.blog-page-inside .rectangle-4 {
  object-fit: cover;
  width: 1259px;
  height: 623px;
  position: absolute;
  top: 50px;
  left: 344px;
}

.blog-page-inside .rectangle-5 {
  object-fit: cover;
  width: 1259px;
  height: 623px;
  position: absolute;
  top: 1393px;
  left: 344px;
}

.blog-page-inside .unlocking-innovation {
  color: #000;
  letter-spacing: -.9px;
  width: 1259px;
  height: 127px;
  font-family: Poppins, Helvetica;
  font-size: 36px;
  font-weight: 500;
  line-height: 63px;
  position: absolute;
  top: 772px;
  left: 344px;
}

.blog-page-inside .community-june {
  color: #b5b5b5;
  letter-spacing: 0;
  height: 30px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 715px;
  left: 341px;
}

.blog-page-inside .text-wrapper-53 {
  font-weight: 700;
}

.blog-page-inside .text-wrapper-54 {
  color: #b5b5b5;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
}

.blog-page-inside .strong-history-of {
  color: #1c1c1c;
  letter-spacing: 0;
  white-space: nowrap;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 26px;
  font-weight: 600;
  line-height: 22px;
  position: absolute;
  top: 955px;
  left: 341px;
}

.blog-page-inside .strong-history-of-2 {
  color: #1c1c1c;
  letter-spacing: 0;
  width: 1262px;
  height: 90px;
  font-family: Poppins, Helvetica;
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  position: absolute;
  top: 1247px;
  left: 341px;
}

.blog-page-inside .most-of-you-are {
  color: #1c1c1c;
  letter-spacing: 0;
  width: 1256px;
  height: 210px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  position: absolute;
  top: 1007px;
  left: 341px;
}

.blog-page-inside .udaipur-the-city-of {
  color: #000;
  letter-spacing: 0;
  width: 1259px;
  font-family: Museo Sans-Regular, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  position: absolute;
  top: 2090px;
  left: 344px;
}

.blog-page-inside .text-wrapper-55 {
  color: #000;
  letter-spacing: 0;
  font-family: Museo Sans-Regular, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
}

.blog-page-inside .beyond-the-sights {
  color: #000;
  letter-spacing: 0;
  width: 1259px;
  font-family: Museo Sans-Regular, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  position: absolute;
  top: 2580px;
  left: 344px;
}

.blog-page-inside .frame-33 {
  width: 1520px;
  height: 1265px;
  position: absolute;
  top: 3671px;
  left: 213px;
}

.blog-page-inside .text-wrapper-56 {
  color: #000;
  letter-spacing: -.9px;
  width: 1259px;
  height: 64px;
  font-family: Poppins, Helvetica;
  font-size: 36px;
  font-weight: 500;
  line-height: 63px;
  position: absolute;
  top: -1px;
  left: 4px;
}

.blog-page-inside .group-19 {
  width: 1520px;
  height: 1140px;
  position: absolute;
  top: 114px;
  left: 0;
}

.blog-page-inside .frame-34 {
  width: 1520px;
  height: 520px;
  position: absolute;
  top: 620px;
  left: 0;
}

.blog-page-inside .frame-35 {
  width: 455px;
  height: 626px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page-inside .overlap-group-16 {
  height: 626px;
  position: relative;
}

.blog-page-inside .frame-36 {
  width: 455px;
  height: 626px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page-inside .rectangle-6 {
  object-fit: cover;
  width: 455px;
  height: 371px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page-inside .frame-37 {
  width: 455px;
  height: 223px;
  position: absolute;
  top: 391px;
  left: 0;
}

.blog-page-inside .div-3 {
  color: #b5b5b5;
  letter-spacing: 0;
  height: 21px;
  font-family: Poppins, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page-inside .text-wrapper-57 {
  color: #b5b5b5;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 14px;
  font-weight: 400;
}

.blog-page-inside .unlocking-innovation-2 {
  color: #000;
  letter-spacing: -.41px;
  width: 455px;
  height: 86px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: 28.6px;
  position: absolute;
  top: 28px;
  left: 0;
}

.blog-page-inside .frame-38 {
  width: 455px;
  height: 626px;
  position: absolute;
  top: 0;
  left: 532px;
}

.blog-page-inside .frame-39 {
  width: 455px;
  height: 626px;
  position: absolute;
  top: 0;
  left: 1065px;
}

.blog-page-inside .overlap-31 {
  width: 456px;
  height: 626px;
  position: relative;
}

.blog-page-inside .text-wrapper-58 {
  color: #000;
  letter-spacing: -.41px;
  width: 455px;
  height: 29px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: 28.6px;
  position: absolute;
  top: 29px;
  left: 0;
}

.blog-page-inside .frame-40 {
  width: 1520px;
  height: 520px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page-inside .decor-24 {
  width: 616px;
  height: 1233px;
  position: absolute;
  top: 0;
  left: 13px;
}

.blog-page-inside .decor-25 {
  width: 662px;
  height: 1233px;
  position: absolute;
  top: 3017px;
  left: 13px;
}

.blog-page-inside .row-5 {
  background-image: url("item.5f700304.png");
  background-size: 100% 100%;
  width: 1775px;
  height: 436px;
  position: absolute;
  top: 5136px;
  left: 86px;
}

.blog-page-inside .overlap-32 {
  background-image: url("item-1.30152dd8.png");
  background-size: 100% 100%;
  width: 1762px;
  height: 502px;
  position: relative;
  top: -33px;
  left: 6px;
}

.blog-page-inside .frame-41 {
  width: 1116px;
  height: 331px;
  position: absolute;
  top: 108px;
  left: 323px;
}

.blog-page-inside .text-wrapper-59 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 20px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 310px;
  left: 423px;
}

.blog-page-inside .list-4 {
  width: 268px;
  height: 70px;
  position: absolute;
  top: 203px;
  left: 424px;
}

.blog-page-inside .link-item-8 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.blog-page-inside .symbol-10 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 23px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 21px;
}

.blog-page-inside .link-item-9 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 99px;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.blog-page-inside .symbol-11 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 33px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 18px;
}

.blog-page-inside .link-item-10 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 197px;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.blog-page-inside .symbol-12 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 42px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.blog-page-inside .goessy-black-logo-4 {
  width: 235px;
  height: 132px;
  position: absolute;
  top: -44px;
  left: 456px;
}

.blog-page-inside .frame-42 {
  width: 1148px;
  height: 58px;
  position: absolute;
  top: 215px;
  left: 307px;
}

.blog-page-inside .overlap-group-17 {
  width: 86px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 0;
}

.blog-page-inside .text-wrapper-60 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 44px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 43.8px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page-inside .line-9 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 41px;
  left: 3px;
}

.blog-page-inside .overlap-33 {
  width: 250px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 134px;
}

.blog-page-inside .text-wrapper-61 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 44px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page-inside .overlap-34 {
  width: 130px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 431px;
}

.blog-page-inside .overlap-35 {
  width: 126px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 611px;
}

.blog-page-inside .line-10 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 41px;
  left: 0;
}

.blog-page-inside .overlap-36 {
  width: 63px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 785px;
}

.blog-page-inside .line-11 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 41px;
  left: 2px;
}

.blog-page-inside .overlap-37 {
  width: 255px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 897px;
}

.blog-page-inside .frame-43 {
  width: 1920px;
  height: 125px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page-inside .overlap-38 {
  background-color: #fff;
  height: 196px;
  position: relative;
}

.blog-page-inside .frame-44 {
  width: 698px;
  height: 33px;
  position: absolute;
  top: 53px;
  left: 1116px;
}

.blog-page-inside .frame-45 {
  width: 63px;
  height: 33px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page-inside .text-wrapper-62 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: -1px;
  left: 0;
}

.blog-page-inside .frame-46 {
  width: 186px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 113px;
}

.blog-page-inside .frame-47 {
  width: 98px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 349px;
}

.blog-page-inside .frame-48 {
  width: 94px;
  height: 33px;
  display: block;
  position: absolute;
  top: 0;
  left: 497px;
}

.blog-page-inside .frame-49 {
  width: 57px;
  height: 33px;
  display: block;
  position: absolute;
  top: 0;
  left: 641px;
}

.blog-page-inside .rectangle-7 {
  background-color: orange;
  width: 1920px;
  height: 4px;
  position: absolute;
  top: 121px;
  left: 0;
}

.blog-page-inside .goessy-logo-pdf-5 {
  width: 106px;
  height: 60px;
  position: absolute;
  top: 38px;
  left: 94px;
}

.lokally-AI {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.lokally-AI .lokally-AI-mob {
  background-color: #fff;
  overflow: hidden;
}

.lokally-AI .frame-50 {
  position: absolute;
}

.lokally-AI .frame-51 {
  height: 308px;
  position: relative;
}

.lokally-AI .frame-52 {
  width: 203px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 52px;
}

.lokally-AI .how-it-works-wrapper {
  height: 31px;
  position: relative;
}

.lokally-AI .how-it-works {
  color: #0000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 22px;
  font-family: Poppins, Helvetica;
  font-size: 30px;
  font-weight: 500;
  line-height: 21.3px;
  position: absolute;
  top: 0;
  left: 7px;
}

.lokally-AI .text-wrapper-63 {
  color: #000;
}

.lokally-AI .text-wrapper-64 {
  color: #211715;
}

.lokally-AI .lokally-AI-helps-you {
  color: #870058;
  letter-spacing: -.72px;
  text-align: center;
  width: 305px;
  font-family: Grandstander-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 44px;
  left: 4px;
}

.lokally-AI .text-wrapper-65 {
  letter-spacing: -.13px;
}

.lokally-AI .text-wrapper-66 {
  letter-spacing: -.13px;
  font-family: Josefin Sans, Helvetica;
}

.lokally-AI .article-wie {
  width: 331px;
  height: 215px;
  position: absolute;
  top: 143px;
  left: -9px;
}

.lokally-AI .group-20 {
  width: 624px;
  height: 946px;
  position: absolute;
  top: 1564px;
  left: 835px;
  transform: rotate(-32.69deg);
}

.lokally-AI .ellipse-22 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 700px;
  left: 110px;
}

.lokally-AI .ellipse-23 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 420px;
  left: 0;
}

.lokally-AI .ellipse-24 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 0;
  left: 230px;
}

.lokally-AI .group-21 {
  width: 2306px;
  height: 5748px;
  position: absolute;
  top: 544px;
  left: 0;
}

.lokally-AI .decor-26 {
  width: 662px;
  height: 1233px;
  position: absolute;
  top: 3029px;
  left: 194px;
}

.lokally-AI .overlap-group-18 {
  width: 648px;
  height: 2304px;
  position: absolute;
  top: 23px;
  left: 1466px;
}

.lokally-AI .decor-27 {
  width: 648px;
  height: 1233px;
  position: absolute;
  top: 1071px;
  left: 0;
}

.lokally-AI .decor-28 {
  width: 564px;
  height: 1233px;
  position: absolute;
  top: 0;
  left: 84px;
}

.lokally-AI .decor-29 {
  width: 563px;
  height: 1137px;
  position: absolute;
  top: 4760px;
  left: 194px;
}

.lokally-AI .ellipse-25 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 161.45px;
  width: 394px;
  height: 323px;
  position: absolute;
  top: 4836px;
  left: 1853px;
}

.lokally-AI .decor-30 {
  width: 622px;
  height: 1123px;
  position: absolute;
  top: -136px;
  left: 194px;
}

.lokally-AI .group-22 {
  width: 624px;
  height: 1242px;
  position: absolute;
  top: 1582px;
  left: 71px;
}

.lokally-AI .ellipse-26 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 197px / 161.45px;
  width: 394px;
  height: 323px;
  position: absolute;
  top: 919px;
  left: 110px;
}

.lokally-AI .ellipse-27 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 197px / 161.45px;
  width: 394px;
  height: 323px;
  position: absolute;
  top: 551px;
  left: 0;
}

.lokally-AI .ellipse-28 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 197px / 161.45px;
  width: 394px;
  height: 323px;
  position: absolute;
  top: 0;
  left: 230px;
}

.lokally-AI .group-23 {
  filter: blur(1569.58px);
  background-color: #f0da904c;
  border-radius: 693.25px / 432.84px;
  width: 1386px;
  height: 866px;
  position: absolute;
  top: 0;
  left: 461px;
}

.lokally-AI .ellipse-29 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 4552px;
  left: 1853px;
}

.lokally-AI .text-wrapper-67 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 457px;
  left: 1287px;
}

.lokally-AI .text-wrapper-68 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 457px;
  left: 1400px;
}

.lokally-AI .text-wrapper-69 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 457px;
  left: 1636px;
}

.lokally-AI .text-wrapper-70 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 457px;
  left: 1784px;
}

.lokally-AI .frame-53 {
  width: 1052px;
  height: 242px;
  position: absolute;
  top: 790px;
  left: 628px;
}

.lokally-AI .intro {
  width: 1052px;
  height: 150px;
  position: absolute;
  top: 0;
  left: 0;
}

.lokally-AI .healine {
  flex-direction: column;
  align-items: center;
  gap: 7.21px;
  display: inline-flex;
  position: relative;
}

.lokally-AI .text {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 3.61px;
  width: 100%;
  display: flex;
  position: relative;
}

.lokally-AI .meet-lokally-AI {
  color: var(--our-grey);
  letter-spacing: -4.5px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -.9px;
  font-family: Poppins, Helvetica;
  font-size: 150px;
  font-weight: 400;
  line-height: 131.2px;
  position: relative;
}

.lokally-AI .text-wrapper-71 {
  color: #708090;
  letter-spacing: -6.75px;
  font-weight: 600;
}

.lokally-AI .text-wrapper-72 {
  color: #708090;
  letter-spacing: -6.75px;
  font-family: Grandstander, Helvetica;
  font-weight: 600;
}

.lokally-AI .text-wrapper-73 {
  color: orange;
  letter-spacing: -6.75px;
  font-family: Grandstander, Helvetica;
  font-weight: 600;
}

.lokally-AI .text-wrapper-74 {
  color: var(--black);
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 40px;
  font-weight: 400;
  line-height: 37.5px;
  position: absolute;
  top: 189px;
  left: 201px;
}

.lokally-AI .article-wie-2 {
  background-color: #fff;
  border-radius: 117.2px;
  width: 1176px;
  height: 586px;
  position: absolute;
  top: 4236px;
  left: 561px;
  box-shadow: 0 2.34px 16.1px #0000001a;
}

.lokally-AI .background {
  background-color: orange;
  border-radius: 27.4px;
  width: 55px;
  height: 55px;
  position: absolute;
  top: 48px;
  left: 59px;
}

.lokally-AI .heading-the-way-to {
  color: var(--black);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 50px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
  position: absolute;
  top: 191px;
  left: 218px;
}

.lokally-AI .text-wrapper-75 {
  color: var(--our-grey);
  letter-spacing: 0;
  text-align: center;
  width: 1014px;
  height: 92px;
  font-family: Josefin Sans, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: 46px;
  position: absolute;
  top: 315px;
  left: 81px;
}

.lokally-AI .container {
  width: 1315px;
  height: 1066px;
  position: absolute;
  top: 2970px;
  left: 499px;
}

.lokally-AI .group-24 {
  width: 1317px;
  height: 920px;
  position: absolute;
  top: 138px;
  left: 0;
}

.lokally-AI .background-shadow {
  background-color: #fff;
  border-radius: 47.39px;
  width: 643px;
  height: 301px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: inset 0 0 8.74px orange;
}

.lokally-AI .img-2 {
  width: 151px;
  height: 151px;
  position: absolute;
  top: 75px;
  left: 46px;
}

.lokally-AI .frame-54 {
  width: 347px;
  height: 148px;
  position: absolute;
  top: 76px;
  left: 236px;
}

.lokally-AI .text-wrapper-76 {
  color: #000;
  letter-spacing: 0;
  width: 347px;
  height: 108px;
  font-family: Josefin Sans, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 49px;
  left: 0;
}

.lokally-AI .text-wrapper-77 {
  color: #870058;
  letter-spacing: 0;
  white-space: nowrap;
  height: 38px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 37.9px;
  position: absolute;
  top: -1px;
  left: 0;
}

.lokally-AI .background-shadow-2 {
  background-color: #fff;
  border-radius: 47.39px;
  width: 643px;
  height: 301px;
  position: absolute;
  top: 0;
  left: 673px;
  overflow: hidden;
  box-shadow: inset 0 0 8.74px orange;
}

.lokally-AI .background-shadow-3 {
  background-color: #fff;
  border-radius: 47.39px;
  width: 643px;
  height: 301px;
  position: absolute;
  top: 336px;
  left: 0;
  overflow: hidden;
  box-shadow: inset 0 0 8.74px orange;
}

.lokally-AI .frame-55 {
  width: 347px;
  height: 148px;
  position: absolute;
  top: 76px;
  left: 237px;
}

.lokally-AI .background-shadow-4 {
  background-color: #fff;
  border-radius: 47.39px;
  width: 643px;
  height: 301px;
  position: absolute;
  top: 336px;
  left: 673px;
  overflow: hidden;
  box-shadow: inset 0 0 8.74px orange;
}

.lokally-AI .background-shadow-5 {
  background-color: #fff;
  border-radius: 47.39px;
  width: 1315px;
  height: 249px;
  position: absolute;
  top: 671px;
  left: 0;
  overflow: hidden;
  box-shadow: inset 0 0 8.74px orange;
}

.lokally-AI .heading-digital {
  color: #870058;
  letter-spacing: 0;
  white-space: nowrap;
  height: 38px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 37.9px;
  position: absolute;
  top: 61px;
  left: 237px;
}

.lokally-AI .indicator-svgrepo {
  width: 151px;
  height: 151px;
  position: absolute;
  top: 49px;
  left: 46px;
}

.lokally-AI .text-wrapper-78 {
  color: #000;
  letter-spacing: 0;
  width: 864px;
  height: 72px;
  font-family: Josefin Sans, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 119px;
  left: 237px;
}

.lokally-AI .why-choose-lokally {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 88px;
  font-family: Poppins, Helvetica;
  font-size: 70px;
  font-weight: 400;
  line-height: 87.4px;
  position: absolute;
  top: -1px;
  left: 245px;
}

.lokally-AI .text-wrapper-79 {
  color: #000;
  font-weight: 500;
}

.lokally-AI .text-wrapper-80 {
  color: orange;
  font-family: Grandstander, Helvetica;
  font-weight: 500;
}

.lokally-AI .group-25 {
  width: 944px;
  height: 660px;
  position: absolute;
  top: 2089px;
  left: 682px;
}

.lokally-AI .article-wie-3 {
  object-fit: cover;
  width: 888px;
  height: 475px;
  position: absolute;
  top: 220px;
  left: 28px;
}

.lokally-AI .heading {
  width: 465px;
  height: 79px;
  position: absolute;
  top: 0;
  left: 239px;
}

.lokally-AI .text-wrapper-81 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 71px;
  font-family: Poppins, Helvetica;
  font-size: 70px;
  font-weight: 500;
  line-height: 70.3px;
  position: absolute;
  top: 2px;
  left: 6px;
}

.lokally-AI .lokally-AI-helps-you-wrapper {
  width: 946px;
  height: 92px;
  position: absolute;
  top: 109px;
  left: 0;
}

.lokally-AI .lokally-AI-helps-you-2 {
  color: #870058;
  letter-spacing: 0;
  text-align: center;
  width: 944px;
  height: 92px;
  font-family: Grandstander, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: 46px;
  position: absolute;
  top: 0;
  left: 0;
}

.lokally-AI .text-wrapper-82 {
  font-weight: 500;
}

.lokally-AI .text-wrapper-83 {
  font-family: Josefin Sans, Helvetica;
  font-weight: 500;
}

.lokally-AI .text-wrapper-84 {
  font-family: Josefin Sans, Helvetica;
}

.lokally-AI .we-are-in-wrapper {
  width: 1322px;
  height: 280px;
  position: absolute;
  top: 1503px;
  left: 494px;
}

.lokally-AI .we-are-in {
  color: var(--black);
  letter-spacing: -2px;
  text-align: center;
  width: 1320px;
  font-family: Josefin Sans, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 70px;
  position: absolute;
  top: 0;
  left: 0;
}

.lokally-AI .row-6 {
  background-image: url("item-8.9dc9a803.png");
  background-size: 100% 100%;
  width: 1775px;
  height: 436px;
  position: absolute;
  top: 5921px;
  left: 267px;
}

.lokally-AI .overlap-39 {
  background-image: url("item-9.f7b973f2.png");
  background-size: 100% 100%;
  width: 1762px;
  height: 502px;
  position: relative;
  top: -33px;
  left: 6px;
}

.lokally-AI .frame-56 {
  width: 1116px;
  height: 331px;
  position: absolute;
  top: 108px;
  left: 323px;
}

.lokally-AI .text-wrapper-85 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 20px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 310px;
  left: 423px;
}

.lokally-AI .list-5 {
  width: 268px;
  height: 70px;
  position: absolute;
  top: 203px;
  left: 424px;
}

.lokally-AI .link-item-11 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.lokally-AI .symbol-13 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 23px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 21px;
}

.lokally-AI .link-item-12 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 99px;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.lokally-AI .symbol-14 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 33px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 18px;
}

.lokally-AI .link-item-13 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 197px;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.lokally-AI .symbol-15 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 42px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.lokally-AI .goessy-black-logo-5 {
  width: 235px;
  height: 132px;
  position: absolute;
  top: -44px;
  left: 456px;
}

.lokally-AI .frame-57 {
  width: 1190px;
  height: 58px;
  position: absolute;
  top: 219px;
  left: 264px;
}

.lokally-AI .overlap-40 {
  width: 86px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 0;
}

.lokally-AI .text-wrapper-86 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 44px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 43.8px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.lokally-AI .line-12 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 40px;
  left: 3px;
}

.lokally-AI .overlap-41 {
  width: 250px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 134px;
}

.lokally-AI .text-wrapper-87 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 44px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  left: 0;
}

.lokally-AI .overlap-group-19 {
  width: 130px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 431px;
}

.lokally-AI .overlap-42 {
  width: 126px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 611px;
}

.lokally-AI .line-13 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 40px;
  left: 0;
}

.lokally-AI .overlap-43 {
  width: 63px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 785px;
}

.lokally-AI .line-14 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 40px;
  left: 2px;
}

.lokally-AI .overlap-44 {
  width: 255px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 897px;
}

.lokally-AI .group-26 {
  width: 1423px;
  height: 699px;
  position: absolute;
  top: 5022px;
  left: 442px;
}

.lokally-AI .frame-58 {
  width: 1423px;
  height: 550px;
  position: absolute;
  top: 0;
  left: 0;
}

.lokally-AI .text-wrapper-88 {
  color: #969696;
  letter-spacing: 0;
  opacity: 1;
  width: 1471px;
  height: 600px;
  font-family: Montserrat, Helvetica;
  font-size: 120px;
  font-weight: 700;
  line-height: 150px;
  position: absolute;
  top: -34px;
  left: 0;
}

.lokally-AI .frame-59 {
  width: 482px;
  height: 69px;
  position: absolute;
  top: 630px;
  left: 471px;
}

.lokally-AI .frame-60 {
  width: 57px;
  height: 33px;
  display: block;
  position: absolute;
  top: 458px;
  left: 1928px;
}

.lokally-AI .text-wrapper-89 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: -1px;
  left: 0;
}

.lokally-AI .frame-61 {
  width: 344px;
  height: 128px;
  position: absolute;
  top: 590px;
  left: 143px;
}

.lokally-AI .text-wrapper-90 {
  color: var(--black);
  letter-spacing: -.88px;
  text-align: center;
  width: 312px;
  font-family: Josefin Sans, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  position: absolute;
  top: -1px;
  left: 16px;
}

.lokally-AI .frame-62 {
  width: 345px;
  height: 935px;
  position: absolute;
  top: 1264px;
  left: 142px;
}

.lokally-AI .frame-63 {
  width: 318px;
  height: 823px;
  position: absolute;
  top: 108px;
  left: 14px;
}

.lokally-AI .background-shadow-6 {
  background-color: #fff;
  border-radius: 23.43px;
  width: 318px;
  height: 149px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: inset 0 0 5px orange;
}

.lokally-AI .img-3 {
  width: 75px;
  height: 75px;
  position: absolute;
  top: 37px;
  left: 23px;
}

.lokally-AI .frame-64 {
  width: 171px;
  height: 93px;
  position: absolute;
  top: 28px;
  left: 117px;
}

.lokally-AI .text-wrapper-91 {
  color: #870058;
  letter-spacing: 0;
  white-space: nowrap;
  height: 19px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 18.7px;
  position: absolute;
  top: -2px;
  left: 0;
}

.lokally-AI .text-wrapper-92 {
  color: #000;
  letter-spacing: 0;
  width: 171px;
  height: 61px;
  font-family: Josefin Sans, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.1px;
  position: absolute;
  top: 30px;
  left: 0;
}

.lokally-AI .background-shadow-7 {
  background-color: #fff;
  border-radius: 23.43px;
  width: 318px;
  height: 149px;
  position: absolute;
  top: 169px;
  left: 0;
  overflow: hidden;
  box-shadow: inset 0 0 5px orange;
}

.lokally-AI .frame-65 {
  width: 172px;
  height: 93px;
  position: absolute;
  top: 34px;
  left: 117px;
}

.lokally-AI .background-shadow-8 {
  background-color: #fff;
  border-radius: 23.43px;
  width: 318px;
  height: 149px;
  position: absolute;
  top: 337px;
  left: 0;
  overflow: hidden;
  box-shadow: inset 0 0 5px orange;
}

.lokally-AI .frame-66 {
  width: 171px;
  height: 93px;
  position: absolute;
  top: 35px;
  left: 117px;
}

.lokally-AI .background-shadow-9 {
  background-color: #fff;
  border-radius: 23.43px;
  width: 318px;
  height: 149px;
  position: absolute;
  top: 506px;
  left: 0;
  overflow: hidden;
  box-shadow: inset 0 0 5px orange;
}

.lokally-AI .frame-67 {
  width: 171px;
  height: 92px;
  position: absolute;
  top: 33px;
  left: 117px;
}

.lokally-AI .background-shadow-10 {
  background-color: #fff;
  border-radius: 23.43px;
  width: 318px;
  height: 149px;
  position: absolute;
  top: 675px;
  left: 0;
  overflow: hidden;
  box-shadow: inset 0 0 5px orange;
}

.lokally-AI .frame-68 {
  width: 171px;
  height: 93px;
  position: absolute;
  top: 32px;
  left: 117px;
}

.lokally-AI .why-choose-lokally-2 {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 246px;
  height: 80px;
  font-family: Poppins, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  position: absolute;
  top: 7px;
  left: 49px;
}

.lokally-AI .text-wrapper-93 {
  color: orange;
  font-weight: 500;
}

.lokally-AI .article-wie-4 {
  background-color: #fff;
  border-radius: 33.97px;
  width: 341px;
  height: 212px;
  position: absolute;
  top: 2299px;
  left: 145px;
  box-shadow: 0 .68px 4.67px #00000026;
}

.lokally-AI .background-2 {
  background-color: orange;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 14px;
  left: 17px;
}

.lokally-AI .frame-69 {
  width: 294px;
  height: 115px;
  position: absolute;
  top: 49px;
  left: 23px;
}

.lokally-AI .heading-the-way-to-2 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 25px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.9px;
  position: absolute;
  top: 8px;
  left: 13px;
}

.lokally-AI .text-wrapper-94 {
  color: var(--our-grey);
  letter-spacing: 0;
  text-align: center;
  width: 294px;
  height: 60px;
  font-family: Josefin Sans, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 58px;
  left: 0;
}

.lokally-AI .goessy-logo-pdf-6 {
  position: absolute;
}

.lokally-AI .group-27 {
  width: 591px;
  height: 3355px;
  position: absolute;
  top: 0;
  left: 0;
}

.lokally-AI .decor-31 {
  width: 211px;
  height: 471px;
  position: absolute;
  top: 629px;
  left: 291px;
}

.lokally-AI .decor-32 {
  width: 202px;
  height: 393px;
  position: absolute;
  top: 2854px;
  left: 128px;
}

.lokally-AI .ellipse-30 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 69.13px / 90.39px;
  width: 138px;
  height: 181px;
  position: absolute;
  top: 2707px;
  left: 428px;
}

.lokally-AI .overlap-45 {
  width: 374px;
  height: 618px;
  position: absolute;
  top: -86px;
  left: 128px;
}

.lokally-AI .decor-33 {
  width: 209px;
  height: 455px;
  position: absolute;
  top: 0;
  left: 0;
}

.lokally-AI .decor-34 {
  width: 176px;
  height: 467px;
  position: absolute;
  top: 151px;
  left: 198px;
}

.lokally-AI .group-28 {
  width: 228px;
  height: 1285px;
  position: absolute;
  top: 1025px;
  left: 0;
}

.lokally-AI .ellipse-31 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 69px / 135px;
  width: 138px;
  height: 270px;
  position: absolute;
  top: 375px;
  left: 38px;
}

.lokally-AI .ellipse-32 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 69px / 135px;
  width: 138px;
  height: 270px;
  position: absolute;
  top: 1015px;
  left: 38px;
}

.lokally-AI .overlap-group-20 {
  width: 228px;
  height: 350px;
  position: absolute;
  top: 0;
  left: 0;
}

.lokally-AI .ellipse-33 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 69.13px / 90.39px;
  width: 138px;
  height: 181px;
  position: absolute;
  top: 169px;
  left: 89px;
}

.lokally-AI .ellipse-34 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 69.13px / 90.39px;
  width: 138px;
  height: 181px;
  position: absolute;
  top: 0;
  left: 0;
}

.lokally-AI .frame-70 {
  width: 350px;
  height: 196px;
  position: absolute;
  top: 3026px;
  left: 140px;
}

.lokally-AI .overlap-46 {
  border-radius: 21.34px;
  height: 196px;
  position: relative;
}

.lokally-AI .item-13 {
  background-color: orange;
  border-radius: 21.34px;
  width: 350px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.lokally-AI .goessy-black-logo-6 {
  width: 78px;
  height: 44px;
  position: absolute;
  top: 11px;
  left: 136px;
}

.lokally-AI .text-wrapper-95 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 9px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 9px;
  font-weight: 400;
  line-height: 9px;
  position: absolute;
  top: 169px;
  left: 114px;
}

.lokally-AI .list-6 {
  width: 121px;
  height: 32px;
  position: absolute;
  top: 128px;
  left: 114px;
}

.lokally-AI .link-item-14 {
  background-color: #000;
  border-radius: 15.86px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 6.34px 6.34px #0000001a;
}

.lokally-AI .symbol-16 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 12px;
  height: 18px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  line-height: 18.1px;
  position: absolute;
  top: 6px;
  left: 9px;
}

.lokally-AI .link-item-15 {
  background-color: #000;
  border-radius: 15.86px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 44px;
  box-shadow: 0 6.34px 6.34px #0000001a;
}

.lokally-AI .symbol-17 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 16px;
  height: 18px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  line-height: 18.1px;
  position: absolute;
  top: 6px;
  left: 8px;
}

.lokally-AI .link-item-16 {
  background-color: #000;
  border-radius: 15.86px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 89px;
  box-shadow: 0 6.34px 6.34px #0000001a;
}

.lokally-AI .symbol-18 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 21px;
  height: 18px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  line-height: 18.1px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.lokally-AI .frame-71 {
  width: 287px;
  height: 56px;
  position: absolute;
  top: 63px;
  left: 29px;
}

.lokally-AI .frame-72 {
  width: 258px;
  height: 27px;
  position: absolute;
  top: 0;
  left: 14px;
}

.lokally-AI .frame-73 {
  width: 39px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.lokally-AI .overlap-group-21 {
  width: 40px;
  height: 21px;
  position: relative;
}

.lokally-AI .text-wrapper-96 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 21px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.1px;
  position: absolute;
  top: 0;
  left: 0;
}

.lokally-AI .line-15 {
  object-fit: cover;
  width: 14px;
  height: 1px;
  position: absolute;
  top: 19px;
  left: 1px;
}

.lokally-AI .frame-74 {
  width: 114px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 62px;
}

.lokally-AI .overlap-47 {
  width: 117px;
  height: 21px;
  position: relative;
}

.lokally-AI .frame-75 {
  width: 60px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 198px;
}

.lokally-AI .overlap-48 {
  width: 61px;
  height: 21px;
  position: relative;
}

.lokally-AI .frame-76 {
  width: 248px;
  height: 20px;
  position: absolute;
  top: 28px;
  left: 19px;
}

.lokally-AI .frame-77 {
  width: 57px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.lokally-AI .overlap-group-22 {
  width: 59px;
  height: 21px;
  position: relative;
}

.lokally-AI .line-16 {
  object-fit: cover;
  width: 14px;
  height: 1px;
  position: absolute;
  top: 19px;
  left: 0;
}

.lokally-AI .frame-78 {
  width: 29px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 80px;
}

.lokally-AI .overlap-49 {
  width: 30px;
  height: 21px;
  position: relative;
  top: -1px;
}

.lokally-AI .frame-79 {
  width: 136px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 132px;
}

.lokally-AI .overlap-50 {
  width: 119px;
  height: 21px;
  position: relative;
}

.lokally-AI .frame-80 {
  width: 328px;
  height: 315px;
  position: absolute;
  top: 2611px;
  left: 151px;
}

.lokally-AI .frame-81 {
  width: 328px;
  height: 208px;
  position: absolute;
  top: 16px;
  left: 0;
}

.lokally-AI .text-wrapper-97 {
  color: #969696;
  letter-spacing: 0;
  opacity: 1;
  width: 334px;
  height: 250px;
  font-family: Montserrat, Helvetica;
  font-size: 34px;
  font-weight: 700;
  line-height: 50px;
  position: absolute;
  top: -23px;
  left: 0;
}

.lokally-AI .frame-82 {
  width: 285px;
  height: 41px;
  position: absolute;
  top: 274px;
  left: 22px;
}

.lokally-AI .group-29 {
  width: 325px;
  height: 81px;
  position: absolute;
  top: 170px;
  left: 152px;
}

.lokally-AI .meet-lokallyai {
  color: #0000;
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 40px;
  font-weight: 400;
  line-height: 45.1px;
  position: absolute;
  top: 0;
  left: 21px;
}

.lokally-AI .text-wrapper-98 {
  color: #708090;
  letter-spacing: -.48px;
  font-weight: 600;
}

.lokally-AI .text-wrapper-99 {
  color: orange;
  letter-spacing: -.48px;
  font-family: Grandstander, Helvetica;
  font-weight: 600;
}

.lokally-AI .text-wrapper-100 {
  color: #000;
  letter-spacing: -.8px;
  text-align: center;
  width: 321px;
  height: 25px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  position: absolute;
  top: 56px;
  left: 0;
}

.lokally-AI .group-30 {
  width: 624px;
  height: 946px;
  position: absolute;
  top: 869px;
  left: 832px;
}

.lokally-AI .navbar-3 {
  background-color: #fff;
  width: 375px;
  height: 76px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 3.76px orange;
}

.lokally-AI .link-home-3 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 626px;
}

.lokally-AI .link-our-3 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 726px;
}

.lokally-AI .link-lokally-AI-3 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 932px;
}

.lokally-AI .link-business-with-3 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 1063px;
}

.lokally-AI .link-about-us-3 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 1265px;
}

.lokally-AI .frame-83 {
  width: 92px;
  height: 34px;
  position: absolute;
  top: 23px;
  left: 264px;
}

.lokally-AI .overlap-group-23 {
  height: 34px;
  position: relative;
  top: -1px;
}

.lokally-AI .rectangle-8 {
  background-color: #000;
  border: .69px solid;
  border-radius: 8px;
  width: 92px;
  height: 31px;
  position: absolute;
  top: 3px;
  left: 0;
}

.lokally-AI .text-wrapper-101 {
  color: #fff;
  letter-spacing: -.57px;
  white-space: nowrap;
  font-family: Montserrat, Helvetica;
  font-size: 14.4px;
  font-weight: 700;
  line-height: 34px;
  position: absolute;
  top: 0;
  left: 14px;
}

.lokally-AI .arrow-3 {
  width: 13px;
  height: 7px;
  position: absolute;
  top: 16px;
  left: 65px;
}

.lokally-AI .goessy-logo-pdf-7 {
  width: 106px;
  height: 60px;
  position: absolute;
  top: 8px;
  left: 25px;
}

.about-US-page {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.about-US-page .about-US-page-mob {
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.about-US-page .overlap-51 {
  position: absolute;
}

.about-US-page .ellipse-35 {
  filter: blur(172.72px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 76.29px / 73.84px;
  width: 153px;
  height: 148px;
  position: absolute;
  top: 2115px;
  left: 71px;
}

.about-US-page .ellipse-36 {
  filter: blur(172.72px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 76.29px / 73.84px;
  width: 153px;
  height: 148px;
  position: absolute;
  top: 1867px;
  left: 28px;
}

.about-US-page .decor-35 {
  width: 92px;
  height: 274px;
  position: absolute;
  top: 11px;
  left: 128px;
}

.about-US-page .overlap-52 {
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .group-31 {
  width: 624px;
  height: 946px;
  position: absolute;
  top: 1558px;
  left: 641px;
  transform: rotate(-32.69deg);
}

.about-US-page .ellipse-37 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 700px;
  left: 110px;
}

.about-US-page .ellipse-38 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 420px;
  left: 0;
}

.about-US-page .ellipse-39 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 0;
  left: 230px;
}

.about-US-page .group-32 {
  filter: blur(1569.58px);
  background-color: #f0da904c;
  border-radius: 693.25px / 432.84px;
  width: 1386px;
  height: 866px;
  position: absolute;
  top: 0;
  left: 267px;
}

.about-US-page .text-wrapper-102 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  left: 1098px;
}

.about-US-page .text-wrapper-103 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  left: 1211px;
}

.about-US-page .text-wrapper-104 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  left: 1447px;
}

.about-US-page .text-wrapper-105 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  left: 1595px;
}

.about-US-page .overlap-53 {
  position: absolute;
}

.about-US-page .decor-36 {
  width: 210px;
  height: 587px;
  position: absolute;
  top: 308px;
  left: 0;
}

.about-US-page .decor-37 {
  width: 176px;
  height: 428px;
  position: absolute;
  top: 0;
  left: 34px;
}

.about-US-page .text-wrapper-106 {
  color: #708090;
  letter-spacing: -4.5px;
  text-align: center;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 150px;
  font-weight: 600;
  line-height: 150px;
  position: absolute;
  top: 0;
  left: 226px;
}

.about-US-page .we-re-not-just-a {
  color: var(--black);
  letter-spacing: -1.6px;
  text-align: center;
  width: 1380px;
  height: 120px;
  font-family: Poppins, Helvetica;
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  position: absolute;
  top: 190px;
  left: 0;
}

.about-US-page .group-wrapper {
  background-image: url("item-12-2.cd37a7b3.png");
  background-size: 100% 100%;
  width: 1320px;
  height: 558px;
  position: absolute;
  top: 2338px;
  left: 300px;
}

.about-US-page .group-33 {
  width: 1079px;
  height: 445px;
  position: relative;
  top: 56px;
  left: 135px;
}

.about-US-page .may-post {
  object-fit: cover;
  width: 445px;
  height: 445px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .group-34 {
  width: 533px;
  height: 219px;
  position: absolute;
  top: 113px;
  left: 546px;
}

.about-US-page .frame-84 {
  height: 219px;
  position: relative;
}

.about-US-page .frame-85 {
  width: 533px;
  height: 101px;
  position: absolute;
  top: 125px;
  left: 0;
}

.about-US-page .group-35 {
  width: 498px;
  height: 108px;
  position: relative;
  top: -4px;
}

.about-US-page .text-wrapper-107 {
  color: #000;
  letter-spacing: -1.04px;
  width: 496px;
  height: 108px;
  font-family: Josefin Sans, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .text-wrapper-108 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 75px;
  font-family: Poppins, Helvetica;
  font-size: 70px;
  font-weight: 500;
  line-height: 74.4px;
  position: absolute;
  top: -1px;
  left: 0;
}

.about-US-page .decor-38 {
  width: 202px;
  height: 399px;
  position: absolute;
  top: 2454px;
  left: 128px;
}

.about-US-page .ellipse-40 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 69.13px / 90.39px;
  width: 138px;
  height: 181px;
  position: absolute;
  top: 2707px;
  left: 428px;
}

.about-US-page .decor-39 {
  width: 209px;
  height: 358px;
  position: absolute;
  top: -86px;
  left: 128px;
}

.about-US-page .frame-86, .about-US-page .frame-87 {
  position: absolute;
}

.about-US-page .item-14 {
  width: 297px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .item-15 {
  width: 297px;
  height: 292px;
  position: absolute;
  top: 322px;
  left: 0;
}

.about-US-page .item-16 {
  width: 297px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 330px;
}

.about-US-page .item-17 {
  width: 297px;
  height: 292px;
  position: absolute;
  top: 322px;
  left: 330px;
}

.about-US-page .frame-88 {
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .group-36 {
  width: 465px;
  height: 236px;
  position: relative;
  top: 189px;
  left: 46px;
}

.about-US-page .frame-89 {
  height: 236px;
  position: relative;
}

.about-US-page .we-mix-smart-travel {
  color: #000;
  letter-spacing: -1.04px;
  width: 465px;
  height: 108px;
  font-family: Josefin Sans, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 131px;
  left: 0;
}

.about-US-page .text-wrapper-109 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 82px;
  font-family: Poppins, Helvetica;
  font-size: 70px;
  font-weight: 500;
  line-height: 81.8px;
  position: absolute;
  top: -1px;
  left: 0;
}

.about-US-page .ellipse-41 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 69.13px / 90.39px;
  width: 138px;
  height: 181px;
  position: absolute;
  top: 0;
  left: 119px;
}

.about-US-page .ellipse-42 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 69.13px / 90.39px;
  width: 138px;
  height: 181px;
  position: absolute;
  top: 131px;
  left: 0;
}

.about-US-page .decor-40 {
  width: 656px;
  height: 1233px;
  position: absolute;
  top: 406px;
  left: 0;
}

.about-US-page .decor-41 {
  width: 530px;
  height: 1233px;
  position: absolute;
  top: 675px;
  left: 1390px;
}

.about-US-page .goessy-logo-pdf-8 {
  width: 106px;
  height: 60px;
  position: absolute;
  top: 440px;
  left: 94px;
}

.about-US-page .frame-90 {
  width: 350px;
  height: 196px;
  position: absolute;
  top: 2644px;
  left: 140px;
}

.about-US-page .overlap-54 {
  border-radius: 21.34px;
  height: 196px;
  position: relative;
}

.about-US-page .item-18 {
  background-color: orange;
  border-radius: 21.34px;
  width: 350px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.about-US-page .goessy-black-logo-7 {
  width: 78px;
  height: 44px;
  position: absolute;
  top: 11px;
  left: 136px;
}

.about-US-page .text-wrapper-110 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 9px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 9px;
  font-weight: 400;
  line-height: 9px;
  position: absolute;
  top: 169px;
  left: 114px;
}

.about-US-page .list-7 {
  width: 121px;
  height: 32px;
  position: absolute;
  top: 128px;
  left: 114px;
}

.about-US-page .link-item-17 {
  background-color: #000;
  border-radius: 15.86px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 6.34px 6.34px #0000001a;
}

.about-US-page .symbol-19 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 12px;
  height: 18px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  line-height: 18.1px;
  position: absolute;
  top: 6px;
  left: 9px;
}

.about-US-page .link-item-18 {
  background-color: #000;
  border-radius: 15.86px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 44px;
  box-shadow: 0 6.34px 6.34px #0000001a;
}

.about-US-page .symbol-20 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 16px;
  height: 18px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  line-height: 18.1px;
  position: absolute;
  top: 6px;
  left: 8px;
}

.about-US-page .link-item-19 {
  background-color: #000;
  border-radius: 15.86px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 89px;
  box-shadow: 0 6.34px 6.34px #0000001a;
}

.about-US-page .symbol-21 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 21px;
  height: 18px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  line-height: 18.1px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.about-US-page .frame-91 {
  width: 287px;
  height: 56px;
  position: absolute;
  top: 63px;
  left: 29px;
}

.about-US-page .frame-92 {
  width: 258px;
  height: 27px;
  position: absolute;
  top: 0;
  left: 14px;
}

.about-US-page .frame-93 {
  width: 39px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .overlap-group-24 {
  width: 40px;
  height: 21px;
  position: relative;
}

.about-US-page .text-wrapper-111 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 21px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.1px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .line-17 {
  object-fit: cover;
  width: 14px;
  height: 1px;
  position: absolute;
  top: 19px;
  left: 1px;
}

.about-US-page .frame-94 {
  width: 114px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 62px;
}

.about-US-page .overlap-55 {
  width: 117px;
  height: 21px;
  position: relative;
}

.about-US-page .frame-95 {
  width: 60px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 198px;
}

.about-US-page .overlap-56 {
  width: 61px;
  height: 21px;
  position: relative;
}

.about-US-page .frame-96 {
  width: 248px;
  height: 20px;
  position: absolute;
  top: 31px;
  left: 19px;
}

.about-US-page .frame-97 {
  width: 57px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .overlap-group-25 {
  width: 59px;
  height: 21px;
  position: relative;
}

.about-US-page .line-18 {
  object-fit: cover;
  width: 14px;
  height: 1px;
  position: absolute;
  top: 19px;
  left: 0;
}

.about-US-page .frame-98 {
  width: 29px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 80px;
}

.about-US-page .overlap-57 {
  width: 30px;
  height: 21px;
  position: relative;
  top: -1px;
}

.about-US-page .frame-99 {
  width: 136px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 132px;
}

.about-US-page .overlap-58 {
  width: 119px;
  height: 21px;
  position: relative;
}

.about-US-page .group-37 {
  width: 325px;
  height: 106px;
  position: absolute;
  top: 170px;
  left: 152px;
}

.about-US-page .text-wrapper-112 {
  color: #708090;
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 40px;
  font-weight: 600;
  line-height: 45.1px;
  position: absolute;
  top: 0;
  left: 36px;
}

.about-US-page .we-re-not-just-a-2 {
  color: #000;
  letter-spacing: -.8px;
  text-align: center;
  width: 321px;
  height: 50px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  position: absolute;
  top: 56px;
  left: 0;
}

.about-US-page .section {
  width: 371px;
  height: 790px;
  position: absolute;
  top: 1754px;
  left: 129px;
}

.about-US-page .overlap-59 {
  width: 363px;
  height: 832px;
  position: absolute;
  top: -663px;
  left: 10px;
}

.about-US-page .frame-100 {
  width: 349px;
  height: 59px;
  position: absolute;
  top: 773px;
  left: 0;
}

.about-US-page .text-wrapper-113 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 13px;
  font-family: Poppins, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 12.9px;
  position: absolute;
  top: -1px;
  left: 13px;
}

.about-US-page .input {
  background-color: #fff;
  border: .65px solid #ced4da;
  border-radius: 8.18px;
  width: 351px;
  height: 41px;
  position: absolute;
  top: 18px;
  left: -1px;
}

.about-US-page .container-2 {
  color: #2125295d;
  letter-spacing: 0;
  background: none;
  border: none;
  width: 316px;
  height: 18px;
  padding: 0;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  top: 13px;
  left: 17px;
  overflow: hidden;
}

.about-US-page .frame-101 {
  width: 240px;
  height: 79px;
  position: absolute;
  top: 668px;
  left: 55px;
}

.about-US-page .overlap-group-26 {
  width: 191px;
  height: 76px;
  position: relative;
  left: 28px;
}

.about-US-page .text-wrapper-114 {
  color: #211715;
  letter-spacing: 0;
  white-space: nowrap;
  height: 54px;
  font-family: Poppins, Helvetica;
  font-size: 30px;
  font-weight: 500;
  line-height: 53.2px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .text-wrapper-115 {
  color: #708090;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 23px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.8px;
  position: absolute;
  top: 53px;
  left: 21px;
}

.about-US-page .decor-42 {
  width: 351px;
  height: 793px;
  position: absolute;
  top: 0;
  left: 12px;
}

.about-US-page .frame-102 {
  width: 349px;
  height: 58px;
  position: absolute;
  top: 195px;
  left: 10px;
}

.about-US-page .frame-103 {
  width: 349px;
  height: 58px;
  position: absolute;
  top: 450px;
  left: 10px;
}

.about-US-page .frame-104 {
  width: 349px;
  height: 59px;
  position: absolute;
  top: 534px;
  left: 10px;
}

.about-US-page .container-3 {
  color: #2125295d;
  letter-spacing: 0;
  background: none;
  border: none;
  width: 316px;
  height: 18px;
  padding: 0;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  top: 12px;
  left: 17px;
  overflow: hidden;
}

.about-US-page .frame-105 {
  width: 349px;
  height: 59px;
  position: absolute;
  top: 279px;
  left: 10px;
}

.about-US-page .button {
  all: unset;
  box-sizing: border-box;
  background-color: orange;
  border-radius: 11.4px;
  width: 118px;
  height: 38px;
  position: absolute;
  top: 752px;
  left: 126px;
  box-shadow: 3.23px 3.23px 3.23px #00000014;
}

.about-US-page .text-wrapper-116 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 18px;
  font-family: Poppins, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.4px;
  position: absolute;
  top: 9px;
  left: 34px;
}

.about-US-page .frame-106 {
  width: 349px;
  height: 103px;
  position: absolute;
  top: 619px;
  left: 10px;
}

.about-US-page .container-wrapper {
  background-color: #fff;
  border: .65px solid #ced4da;
  border-radius: 8.18px;
  width: 350px;
  height: 85px;
  position: absolute;
  top: 18px;
  left: -1px;
}

.about-US-page .container-4 {
  width: 316px;
  height: 15px;
  position: relative;
  top: 15px;
  left: 17px;
  overflow: hidden;
}

.about-US-page .div-4 {
  color: #2125295d;
  letter-spacing: 0;
  height: 18px;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -3px;
  left: 0;
}

.about-US-page .frame-107 {
  width: 349px;
  height: 60px;
  position: absolute;
  top: 364px;
  left: 10px;
}

.about-US-page .input-2 {
  background-color: #fff;
  border: .65px solid #ced4da;
  border-radius: 8.18px;
  width: 351px;
  height: 41px;
  position: absolute;
  top: 19px;
  left: -1px;
}

.about-US-page .enter-your-contact-wrapper {
  width: 316px;
  height: 15px;
  position: relative;
  top: 12px;
  left: 17px;
  overflow: hidden;
}

.about-US-page .text-wrapper-117 {
  color: #2125295d;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 400;
}

.about-US-page .decor-43 {
  width: 374px;
  height: 684px;
  position: absolute;
  top: 1835px;
  left: 128px;
}

.about-US-page .frame-108 {
  background-image: url("item-12.c55968d8.png");
  background-size: 100% 100%;
  width: 340px;
  height: 334px;
  position: absolute;
  top: 972px;
  left: 145px;
}

.about-US-page .group-38 {
  width: 253px;
  height: 150px;
  position: relative;
  top: 92px;
  left: 25px;
}

.about-US-page .frame-109 {
  height: 150px;
  position: relative;
}

.about-US-page .we-mix-smart-travel-2 {
  color: #000;
  letter-spacing: -.64px;
  width: 253px;
  height: 81px;
  font-family: Josefin Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 65px;
  left: 0;
}

.about-US-page .text-wrapper-118 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 45px;
  font-family: Poppins, Helvetica;
  font-size: 30px;
  font-weight: 500;
  line-height: 44.5px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .frame-110 {
  width: 340px;
  height: 335px;
  position: absolute;
  top: 1356px;
  left: 145px;
}

.about-US-page .frame-111 {
  background-image: url("item-13-2.b7c17b0b.png");
  background-size: 100% 100%;
  height: 334px;
}

.about-US-page .group-39 {
  width: 339px;
  height: 335px;
  position: absolute;
  top: 587px;
  left: 144px;
}

.about-US-page .item-19 {
  width: 162px;
  height: 159px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .item-20 {
  width: 162px;
  height: 159px;
  position: absolute;
  top: 176px;
  left: 0;
}

.about-US-page .item-21 {
  width: 162px;
  height: 159px;
  position: absolute;
  top: 0;
  left: 177px;
}

.about-US-page .item-22 {
  width: 162px;
  height: 159px;
  position: absolute;
  top: 176px;
  left: 177px;
}

.about-US-page .frame-112 {
  width: 57px;
  height: 33px;
  display: block;
  position: absolute;
  top: 452px;
  left: 1739px;
}

.about-US-page .text-wrapper-119 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  display: block;
  position: absolute;
  top: -1px;
  left: 0;
}

.about-US-page .group-40 {
  position: absolute;
}

.about-US-page .row-7 {
  background-image: url("item-10.c4ceb20c.png");
  background-size: 100% 100%;
  width: 1775px;
  height: 436px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .overlap-60 {
  background-image: url("item-9.f7b973f2.png");
  background-size: 100% 100%;
  width: 1762px;
  height: 502px;
  position: relative;
  top: -33px;
  left: 6px;
}

.about-US-page .frame-113 {
  width: 1116px;
  height: 331px;
  position: absolute;
  top: 108px;
  left: 323px;
}

.about-US-page .text-wrapper-120 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 20px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 310px;
  left: 423px;
}

.about-US-page .list-8 {
  width: 268px;
  height: 70px;
  position: absolute;
  top: 203px;
  left: 424px;
}

.about-US-page .link-item-20 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.about-US-page .symbol-22 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 23px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 21px;
}

.about-US-page .link-item-21 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 99px;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.about-US-page .symbol-23 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 33px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 18px;
}

.about-US-page .link-item-22 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 197px;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.about-US-page .symbol-24 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 42px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.about-US-page .goessy-black-logo-8 {
  width: 235px;
  height: 132px;
  position: absolute;
  top: -44px;
  left: 456px;
}

.about-US-page .frame-114 {
  width: 1235px;
  height: 58px;
  position: absolute;
  top: 219px;
  left: 264px;
}

.about-US-page .overlap-group-27 {
  width: 86px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 0;
}

.about-US-page .text-wrapper-121 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 44px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 43.8px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .line-19 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 41px;
  left: 3px;
}

.about-US-page .overlap-61 {
  width: 250px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 134px;
}

.about-US-page .text-wrapper-122 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 44px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .overlap-62 {
  width: 130px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 431px;
}

.about-US-page .overlap-63 {
  width: 126px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 611px;
}

.about-US-page .line-20 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 41px;
  left: 0;
}

.about-US-page .overlap-64 {
  width: 63px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 785px;
}

.about-US-page .line-21 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 41px;
  left: 2px;
}

.about-US-page .overlap-65 {
  width: 255px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 897px;
}

.about-US-page .ellipse-43 {
  filter: blur(446.03px);
  opacity: .3;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
}

.about-US-page .navbar-4 {
  position: absolute;
}

.about-US-page .link-home-4 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 626px;
}

.about-US-page .link-our-4 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 726px;
}

.about-US-page .link-lokally-AI-4 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 932px;
}

.about-US-page .link-business-with-4 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 1063px;
}

.about-US-page .link-about-us-4 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 1265px;
}

.about-US-page .label-first-name {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 120px;
  height: 23px;
  font-family: Montserrat, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.2px;
  position: absolute;
  top: 197px;
  left: 41px;
}

.about-US-page .input-3 {
  background-color: #fff;
  border: 1.16px solid #ced4da;
  border-radius: 14.68px;
  width: 629px;
  height: 63px;
  position: absolute;
  top: 230px;
  left: 17px;
}

.about-US-page .container-5 {
  width: 567px;
  height: 27px;
  position: relative;
  top: 17px;
  left: 30px;
}

.about-US-page .text-wrapper-123 {
  color: #2125295d;
  letter-spacing: 0;
  width: 184px;
  height: 24px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .label-last-name {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 117px;
  height: 23px;
  font-family: Montserrat, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.2px;
  position: absolute;
  top: 197px;
  left: 698px;
}

.about-US-page .input-4 {
  background-color: #fff;
  border: 1.16px solid #ced4da;
  border-radius: 14.68px;
  width: 629px;
  height: 63px;
  position: absolute;
  top: 230px;
  left: 675px;
}

.about-US-page .label-email {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 62px;
  height: 23px;
  font-family: Montserrat, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.2px;
  position: absolute;
  top: 305px;
  left: 41px;
}

.about-US-page .input-5 {
  background-color: #fff;
  border: 1.16px solid #ced4da;
  border-radius: 14.68px;
  width: 629px;
  height: 63px;
  position: absolute;
  top: 338px;
  left: 17px;
}

.about-US-page .text-wrapper-124 {
  color: #2125295d;
  letter-spacing: 0;
  width: 139px;
  height: 24px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .label-contact-number {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 182px;
  height: 23px;
  font-family: Montserrat, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.2px;
  position: absolute;
  top: 305px;
  left: 698px;
}

.about-US-page .input-6 {
  background-color: #fff;
  border: 1.16px solid #ced4da;
  border-radius: 14.68px;
  width: 629px;
  height: 63px;
  position: absolute;
  top: 338px;
  left: 675px;
}

.about-US-page .text-wrapper-125 {
  color: #2125295d;
  letter-spacing: 0;
  width: 146px;
  height: 24px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .label-city {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 43px;
  height: 23px;
  font-family: Montserrat, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.2px;
  position: absolute;
  top: 412px;
  left: 41px;
}

.about-US-page .input-7 {
  background-color: #fff;
  border: 1.16px solid #ced4da;
  border-radius: 14.68px;
  width: 629px;
  height: 63px;
  position: absolute;
  top: 446px;
  left: 17px;
}

.about-US-page .container-6 {
  width: 567px;
  height: 27px;
  position: relative;
  top: 17px;
  left: 30px;
  overflow: hidden;
}

.about-US-page .text-wrapper-126 {
  color: #2125295d;
  letter-spacing: 0;
  width: 183px;
  height: 24px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .input-8 {
  background-color: #fff;
  border: 1.16px solid #ced4da;
  border-radius: 14.68px;
  width: 1287px;
  height: 63px;
  position: absolute;
  top: 560px;
  left: 17px;
}

.about-US-page .label-state {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 58px;
  height: 23px;
  font-family: Montserrat, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.2px;
  position: absolute;
  top: 412px;
  left: 698px;
}

.about-US-page .input-9 {
  background-color: #fff;
  border: 1.16px solid #ced4da;
  border-radius: 14.68px;
  width: 629px;
  height: 63px;
  position: absolute;
  top: 446px;
  left: 675px;
}

.about-US-page .text-wrapper-127 {
  color: #2125295d;
  letter-spacing: 0;
  width: 195px;
  height: 24px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.about-US-page .label-description {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 127px;
  height: 23px;
  font-family: Montserrat, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.2px;
  position: absolute;
  top: 520px;
  left: 41px;
}

.about-US-page .button-2 {
  all: unset;
  box-sizing: border-box;
  background-color: orange;
  border-radius: 20.47px;
  width: 212px;
  height: 68px;
  position: absolute;
  top: 654px;
  left: 555px;
  box-shadow: 5.79px 5.79px 5.79px #00000014;
}

.about-US-page .text-wrapper-128 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 32px;
  font-family: Inter, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: 31.3px;
  position: absolute;
  top: 16px;
  left: 71px;
}

.about-US-page .frame-115 {
  position: absolute;
}

.about-US-page .text-wrapper-129 {
  color: #211715;
  letter-spacing: 0;
  white-space: nowrap;
  height: 96px;
  font-family: Poppins, Helvetica;
  font-size: 70px;
  font-weight: 500;
  line-height: 95.5px;
  position: absolute;
  top: -1px;
  left: 0;
}

.about-US-page .text-wrapper-130 {
  color: #708090;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 41px;
  font-family: Poppins, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 40.9px;
  position: absolute;
  top: 99px;
  left: 83px;
}

.about-US-page .overlap-group-28 {
  height: 34px;
  position: relative;
  top: -1px;
}

.about-US-page .rectangle-9 {
  background-color: #000;
  border: .69px solid;
  border-radius: 8px;
  width: 92px;
  height: 31px;
  position: absolute;
  top: 3px;
  left: 0;
}

.about-US-page .text-wrapper-131 {
  color: #fff;
  letter-spacing: -.57px;
  white-space: nowrap;
  font-family: Montserrat, Helvetica;
  font-size: 14.4px;
  font-weight: 700;
  line-height: 34px;
  position: absolute;
  top: 0;
  left: 14px;
}

.about-US-page .arrow-4 {
  width: 13px;
  height: 7px;
  position: absolute;
  top: 16px;
  left: 65px;
}

.about-US-page .goessy-logo-pdf-9 {
  width: 106px;
  height: 60px;
  position: absolute;
  top: 8px;
  left: 25px;
}

.blog-page {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.blog-page .blog-page-mob {
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.blog-page .overlap-66, .blog-page .frame-116 {
  position: absolute;
}

.blog-page .ellipse-44 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 197px / 161.45px;
  width: 394px;
  height: 323px;
  position: absolute;
  top: 1718px;
  left: 107px;
}

.blog-page .frame-117 {
  width: 1520px;
  height: 520px;
  position: absolute;
  top: 1300px;
  left: 200px;
}

.blog-page .frame-118 {
  width: 455px;
  height: 626px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .overlap-group-29 {
  height: 626px;
  position: relative;
}

.blog-page .frame-119 {
  width: 455px;
  height: 626px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .rectangle-10 {
  object-fit: cover;
  width: 455px;
  height: 371px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .frame-120 {
  width: 455px;
  height: 223px;
  position: absolute;
  top: 391px;
  left: 0;
}

.blog-page .community-june-2 {
  color: #0000;
  letter-spacing: 0;
  height: 21px;
  font-family: Poppins, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .text-wrapper-132 {
  color: #708090;
  font-weight: 700;
}

.blog-page .text-wrapper-133 {
  color: #708090;
}

.blog-page .text-wrapper-134 {
  color: #b5b5b5;
}

.blog-page .unlocking-innovation-3 {
  color: #000;
  letter-spacing: -.41px;
  width: 455px;
  height: 86px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: 28.6px;
  position: absolute;
  top: 28px;
  left: 0;
}

.blog-page .frame-121 {
  width: 455px;
  height: 626px;
  position: absolute;
  top: 0;
  left: 532px;
}

.blog-page .div-5 {
  color: #b5b5b5;
  letter-spacing: 0;
  height: 21px;
  font-family: Poppins, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .text-wrapper-135 {
  font-weight: 700;
}

.blog-page .text-wrapper-136 {
  color: #b5b5b5;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 14px;
  font-weight: 400;
}

.blog-page .frame-122 {
  width: 455px;
  height: 626px;
  position: absolute;
  top: 0;
  left: 1065px;
}

.blog-page .overlap-67 {
  width: 456px;
  height: 626px;
  position: relative;
}

.blog-page .text-wrapper-137 {
  color: #000;
  letter-spacing: -.41px;
  width: 455px;
  height: 29px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: 28.6px;
  position: absolute;
  top: 29px;
  left: 0;
}

.blog-page .frame-123 {
  width: 1520px;
  height: 520px;
  position: absolute;
  top: 1920px;
  left: 200px;
}

.blog-page .text-wrapper-138 {
  color: #708090;
  letter-spacing: -4.5px;
  text-align: center;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 150px;
  font-weight: 600;
  line-height: 150px;
  position: absolute;
  top: 369px;
  left: 548px;
}

.blog-page .text-wrapper-139 {
  color: #708090;
  letter-spacing: 0;
  text-align: center;
  width: 1128px;
  height: 90px;
  font-family: Josefin Sans, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: 45px;
  position: absolute;
  top: 1146px;
  left: 396px;
}

.blog-page .frame-124 {
  background-color: #fff;
  width: 1918px;
  height: 113px;
  position: absolute;
  top: 888px;
  left: 1px;
}

.blog-page .frame-125 {
  background-color: #000;
  border-radius: 15px;
  width: 199px;
  height: 73px;
  position: absolute;
  top: 21px;
  left: 300px;
}

.blog-page .text-wrapper-140 {
  color: var(--white);
  letter-spacing: 0;
  white-space: nowrap;
  height: 21px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 26px;
  left: 32px;
}

.blog-page .frame-126 {
  background-color: #eaeaea;
  border-radius: 15px;
  width: 199px;
  height: 73px;
  position: absolute;
  top: 21px;
  left: 524px;
}

.blog-page .text-wrapper-141 {
  color: var(--our-grey);
  letter-spacing: 0;
  white-space: nowrap;
  height: 21px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 25px;
  left: 41px;
}

.blog-page .frame-127 {
  background-color: #eaeaea;
  border-radius: 15px;
  width: 199px;
  height: 73px;
  position: absolute;
  top: 21px;
  left: 748px;
}

.blog-page .text-wrapper-142 {
  color: var(--our-grey);
  letter-spacing: 0;
  white-space: nowrap;
  height: 21px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 25px;
  left: 52px;
}

.blog-page .frame-128 {
  background-color: #eaeaea;
  border-radius: 15px;
  width: 199px;
  height: 73px;
  position: absolute;
  top: 21px;
  left: 1196px;
}

.blog-page .text-wrapper-143 {
  color: var(--our-grey);
  letter-spacing: 0;
  white-space: nowrap;
  height: 21px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 26px;
  left: 52px;
}

.blog-page .frame-129 {
  background-color: #eaeaea;
  border-radius: 15px;
  width: 199px;
  height: 73px;
  position: absolute;
  top: 21px;
  left: 972px;
}

.blog-page .text-wrapper-144 {
  color: var(--our-grey);
  letter-spacing: 0;
  white-space: nowrap;
  height: 21px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 25px;
  left: 63px;
}

.blog-page .frame-130 {
  background-color: #eaeaea;
  border-radius: 15px;
  width: 199px;
  height: 73px;
  position: absolute;
  top: 21px;
  left: 1420px;
}

.blog-page .text-wrapper-145 {
  color: var(--our-grey);
  letter-spacing: 0;
  white-space: nowrap;
  height: 21px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 26px;
  left: 63px;
}

.blog-page .ellipse-45 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 1149px;
  left: 664px;
  transform: rotate(-32.69deg);
}

.blog-page .decor-44 {
  width: 648px;
  height: 1233px;
  position: absolute;
  top: 1230px;
  left: 1272px;
}

.blog-page .decor-45 {
  width: 622px;
  height: 1123px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .decor-46 {
  width: 564px;
  height: 1233px;
  position: absolute;
  top: 159px;
  left: 1356px;
}

.blog-page .we-re-your {
  text-align: center;
  white-space: nowrap;
  font-weight: 400;
  position: absolute;
}

.blog-page .goessy-logo-pdf-10 {
  width: 106px;
  height: 60px;
  position: absolute;
  top: 38px;
  left: 94px;
}

.blog-page .text-wrapper-146 {
  color: #708090;
  letter-spacing: 0;
  text-align: center;
  width: 320px;
  height: 54px;
  font-family: Josefin Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  top: 35px;
  left: 0;
}

.blog-page .frame-131 {
  position: absolute;
}

.blog-page .frame-132 {
  width: 63px;
  height: 33px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .text-wrapper-147 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: -1px;
  left: 0;
}

.blog-page .frame-133 {
  width: 186px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 113px;
}

.blog-page .frame-134 {
  width: 98px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 349px;
}

.blog-page .frame-135 {
  width: 94px;
  height: 33px;
  display: block;
  position: absolute;
  top: 0;
  left: 497px;
}

.blog-page .frame-136 {
  width: 57px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 641px;
}

.blog-page .overlap-68 {
  border-radius: 21.34px;
  height: 196px;
  position: relative;
}

.blog-page .item-23 {
  background-color: orange;
  border-radius: 21.34px;
  width: 350px;
  height: 196px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.blog-page .goessy-black-logo-9 {
  width: 78px;
  height: 44px;
  position: absolute;
  top: 11px;
  left: 136px;
}

.blog-page .text-wrapper-148 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 9px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 9px;
  font-weight: 400;
  line-height: 9px;
  position: absolute;
  top: 169px;
  left: 114px;
}

.blog-page .list-9 {
  width: 121px;
  height: 32px;
  position: absolute;
  top: 128px;
  left: 114px;
}

.blog-page .link-item-23 {
  background-color: #000;
  border-radius: 15.86px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 6.34px 6.34px #0000001a;
}

.blog-page .symbol-25 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 12px;
  height: 18px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  line-height: 18.1px;
  position: absolute;
  top: 6px;
  left: 9px;
}

.blog-page .link-item-24 {
  background-color: #000;
  border-radius: 15.86px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 44px;
  box-shadow: 0 6.34px 6.34px #0000001a;
}

.blog-page .symbol-26 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 16px;
  height: 18px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  line-height: 18.1px;
  position: absolute;
  top: 6px;
  left: 8px;
}

.blog-page .link-item-25 {
  background-color: #000;
  border-radius: 15.86px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 89px;
  box-shadow: 0 6.34px 6.34px #0000001a;
}

.blog-page .symbol-27 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 21px;
  height: 18px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  line-height: 18.1px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.blog-page .frame-137 {
  width: 287px;
  height: 56px;
  position: absolute;
  top: 63px;
  left: 29px;
}

.blog-page .frame-138 {
  width: 258px;
  height: 27px;
  position: absolute;
  top: 0;
  left: 14px;
}

.blog-page .frame-139 {
  width: 39px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .overlap-group-30 {
  width: 40px;
  height: 21px;
  position: relative;
}

.blog-page .text-wrapper-149 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 21px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.1px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .line-22 {
  object-fit: cover;
  width: 14px;
  height: 1px;
  position: absolute;
  top: 19px;
  left: 1px;
}

.blog-page .frame-140 {
  width: 114px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 62px;
}

.blog-page .overlap-69 {
  width: 117px;
  height: 21px;
  position: relative;
}

.blog-page .frame-141 {
  width: 60px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 198px;
}

.blog-page .overlap-70 {
  width: 61px;
  height: 21px;
  position: relative;
}

.blog-page .frame-142 {
  width: 248px;
  height: 20px;
  position: absolute;
  top: 31px;
  left: 19px;
}

.blog-page .frame-143 {
  width: 57px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .overlap-group-31 {
  width: 59px;
  height: 21px;
  position: relative;
}

.blog-page .line-23 {
  object-fit: cover;
  width: 14px;
  height: 1px;
  position: absolute;
  top: 19px;
  left: 0;
}

.blog-page .frame-144 {
  width: 29px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 80px;
}

.blog-page .overlap-71 {
  width: 30px;
  height: 21px;
  position: relative;
  top: -1px;
}

.blog-page .frame-145 {
  width: 136px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 132px;
}

.blog-page .overlap-72 {
  width: 119px;
  height: 21px;
  position: relative;
}

.blog-page .text-wrapper-150 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 45px;
  font-family: Josefin Sans, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: 45px;
  position: absolute;
  top: 1077px;
  left: 800px;
}

.blog-page .frame-146 {
  border: 1px solid #000;
  border-radius: 10.15px;
  width: 101px;
  height: 37px;
  position: absolute;
  top: 2352px;
  left: 264px;
}

.blog-page .frame-147 {
  width: 65px;
  height: 11px;
  position: relative;
  top: 12px;
  left: 17px;
}

.blog-page .frame-148 {
  width: 71px;
  height: 11px;
  position: relative;
  left: -5px;
}

.blog-page .line-24 {
  width: 7px;
  height: 9px;
  position: absolute;
  top: 1px;
  left: 67px;
}

.blog-page .text-wrapper-151 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 11px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 10.7px;
  position: absolute;
  top: -1px;
  left: 0;
}

.blog-page .group-41 {
  width: 325px;
  height: 105px;
  position: absolute;
  top: 166px;
  left: 155px;
}

.blog-page .text-wrapper-152 {
  color: #708090;
  letter-spacing: -1.2px;
  text-align: center;
  white-space: nowrap;
  font-family: Poppins, Helvetica;
  font-size: 40px;
  font-weight: 600;
  line-height: 45px;
  position: absolute;
  top: 0;
  left: 50px;
}

.blog-page .we-re-your-2 {
  color: #000;
  letter-spacing: -.8px;
  text-align: center;
  width: 321px;
  height: 50px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  position: absolute;
  top: 55px;
  left: 0;
}

.blog-page .ellipse-46 {
  filter: blur(172.72px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 76.29px / 73.84px;
  width: 153px;
  height: 148px;
  position: absolute;
  top: 2115px;
  left: 71px;
}

.blog-page .ellipse-47 {
  filter: blur(172.72px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 76.29px / 73.84px;
  width: 153px;
  height: 148px;
  position: absolute;
  top: 1867px;
  left: 28px;
}

.blog-page .decor-47 {
  width: 92px;
  height: 274px;
  position: absolute;
  top: 11px;
  left: 128px;
}

.blog-page .group-42 {
  width: 630px;
  height: 2955px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .overlap-73 {
  width: 210px;
  height: 757px;
  position: absolute;
  top: 494px;
  left: 292px;
}

.blog-page .decor-48 {
  width: 138px;
  height: 428px;
  position: absolute;
  top: 0;
  left: 73px;
}

.blog-page .decor-49 {
  width: 210px;
  height: 587px;
  position: absolute;
  top: 171px;
  left: 0;
}

.blog-page .decor-50 {
  width: 202px;
  height: 270px;
  position: absolute;
  top: 2454px;
  left: 128px;
}

.blog-page .ellipse-48 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 69.13px / 90.39px;
  width: 138px;
  height: 181px;
  position: absolute;
  top: 2707px;
  left: 428px;
}

.blog-page .decor-51 {
  width: 209px;
  height: 358px;
  position: absolute;
  top: -86px;
  left: 128px;
}

.blog-page .group-43 {
  width: 266px;
  height: 687px;
  position: absolute;
  top: 894px;
  left: 0;
}

.blog-page .ellipse-49 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 69.13px / 90.39px;
  width: 138px;
  height: 181px;
  position: absolute;
  top: 506px;
  left: 128px;
}

.blog-page .overlap-group-32 {
  width: 258px;
  height: 312px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .ellipse-50 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 69.13px / 90.39px;
  width: 138px;
  height: 181px;
  position: absolute;
  top: 0;
  left: 119px;
}

.blog-page .ellipse-51 {
  filter: blur(156.51px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 69.13px / 90.39px;
  width: 138px;
  height: 181px;
  position: absolute;
  top: 131px;
  left: 0;
}

.blog-page .decor-52 {
  width: 374px;
  height: 684px;
  position: absolute;
  top: 1835px;
  left: 128px;
}

.blog-page .frame-149 {
  background-color: #fff;
  width: 374px;
  height: 69px;
  position: absolute;
  top: 604px;
  left: 128px;
  overflow-x: scroll;
}

.blog-page .frame-150 {
  background-color: #000;
  border-radius: 9.26px;
  width: 123px;
  height: 45px;
  position: absolute;
  top: 13px;
  left: 12px;
}

.blog-page .text-wrapper-153 {
  color: var(--white);
  letter-spacing: 0;
  white-space: nowrap;
  height: 13px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  position: absolute;
  top: 16px;
  left: 20px;
}

.blog-page .frame-151 {
  background-color: #eaeaea;
  border-radius: 9.26px;
  width: 123px;
  height: 45px;
  position: absolute;
  top: 13px;
  left: 145px;
}

.blog-page .text-wrapper-154 {
  color: var(--our-grey);
  letter-spacing: 0;
  white-space: nowrap;
  height: 13px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  position: absolute;
  top: 16px;
  left: 39px;
}

.blog-page .frame-152 {
  background-color: #eaeaea;
  border-radius: 9.26px;
  width: 123px;
  height: 45px;
  position: absolute;
  top: 13px;
  left: 278px;
}

.blog-page .text-wrapper-155 {
  color: var(--our-grey);
  letter-spacing: 0;
  white-space: nowrap;
  height: 13px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  position: absolute;
  top: 16px;
  left: 32px;
}

.blog-page .frame-153 {
  background-color: #eaeaea;
  border-radius: 9.26px;
  width: 123px;
  height: 45px;
  position: absolute;
  top: 13px;
  left: 543px;
}

.blog-page .frame-154 {
  background-color: #eaeaea;
  border-radius: 9.26px;
  width: 123px;
  height: 45px;
  position: absolute;
  top: 13px;
  left: 410px;
}

.blog-page .frame-155 {
  background-color: #eaeaea;
  border-radius: 9.26px;
  width: 123px;
  height: 45px;
  position: absolute;
  top: 13px;
  left: 676px;
}

.blog-page .frame-156 {
  width: 343px;
  height: 1414px;
  position: absolute;
  top: 888px;
  left: 143px;
}

.blog-page .frame-157 {
  width: 343px;
  height: 303px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .overlap-group-33 {
  height: 303px;
  position: relative;
}

.blog-page .rectangle-11 {
  object-fit: cover;
  width: 340px;
  height: 225px;
  position: absolute;
  top: 0;
  left: 1px;
}

.blog-page .frame-158 {
  width: 342px;
  height: 70px;
  position: absolute;
  top: 233px;
  left: 0;
}

.blog-page .div-6 {
  color: #b5b5b5;
  letter-spacing: 0;
  height: 18px;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -2px;
  left: 0;
}

.blog-page .text-wrapper-156 {
  color: #b5b5b5;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 400;
}

.blog-page .text-wrapper-157 {
  color: #000;
  letter-spacing: -.31px;
  width: 342px;
  height: 44px;
  font-family: Poppins, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.5px;
  position: absolute;
  top: 26px;
  left: 0;
}

.blog-page .frame-159 {
  width: 343px;
  height: 303px;
  position: absolute;
  top: 353px;
  left: 0;
}

.blog-page .frame-160 {
  width: 343px;
  height: 303px;
  position: absolute;
  top: 706px;
  left: 0;
}

.blog-page .frame-161 {
  width: 343px;
  height: 355px;
  position: absolute;
  top: 1059px;
  left: 0;
}

.blog-page .overlap-74 {
  height: 355px;
  position: relative;
}

.blog-page .rectangle-wrapper {
  width: 343px;
  height: 355px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .frame-162 {
  width: 342px;
  height: 113px;
  position: absolute;
  top: 242px;
  left: 0;
}

.blog-page .text-wrapper-158 {
  color: #000;
  letter-spacing: -.31px;
  width: 342px;
  height: 87px;
  font-family: Poppins, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.5px;
  position: absolute;
  top: 26px;
  left: 0;
}

.blog-page .navbar-5 {
  background-color: #fff;
  width: 375px;
  height: 76px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 3.76px orange;
}

.blog-page .link-home-5 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 626px;
}

.blog-page .link-our-5 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 726px;
}

.blog-page .link-lokally-AI-5 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 932px;
}

.blog-page .link-business-with-5 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 1063px;
}

.blog-page .link-about-us-5 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 17px;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  left: 1265px;
}

.blog-page .frame-163 {
  width: 92px;
  height: 34px;
  position: absolute;
  top: 23px;
  left: 264px;
}

.blog-page .overlap-group-34 {
  height: 34px;
  position: relative;
  top: -1px;
}

.blog-page .rectangle-12 {
  background-color: #000;
  border: .69px solid;
  border-radius: 8px;
  width: 92px;
  height: 31px;
  position: absolute;
  top: 3px;
  left: 0;
}

.blog-page .text-wrapper-159 {
  color: #fff;
  letter-spacing: -.57px;
  white-space: nowrap;
  font-family: Montserrat, Helvetica;
  font-size: 14.4px;
  font-weight: 700;
  line-height: 34px;
  position: absolute;
  top: 0;
  left: 14px;
}

.blog-page .arrow-5 {
  width: 13px;
  height: 7px;
  position: absolute;
  top: 16px;
  left: 65px;
}

.blog-page .goessy-logo-pdf-11 {
  width: 106px;
  height: 60px;
  position: absolute;
  top: 8px;
  left: 25px;
}

.blog-page .overlap-75 {
  width: 1733px;
  height: 520px;
  position: absolute;
  top: 2540px;
  left: -13px;
}

.blog-page .frame-164 {
  width: 1520px;
  height: 520px;
  position: absolute;
  top: 0;
  left: 213px;
}

.blog-page .ellipse-52 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da9080;
  border-radius: 197px / 161.45px;
  width: 394px;
  height: 323px;
  position: absolute;
  top: 96px;
  left: 0;
}

.blog-page .overlap-76 {
  width: 2053px;
  height: 1387px;
  position: absolute;
  top: 3160px;
  left: 0;
}

.blog-page .overlap-77 {
  width: 2053px;
  height: 1387px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .frame-165 {
  width: 1520px;
  height: 520px;
  position: absolute;
  top: 0;
  left: 200px;
}

.blog-page .row-8 {
  background-image: url("item-18.2181de89.png");
  background-size: 100% 100%;
  width: 1775px;
  height: 436px;
  position: absolute;
  top: 893px;
  left: 73px;
}

.blog-page .overlap-78 {
  background-image: url("item-19.f94d55de.png");
  background-size: 100% 100%;
  width: 1762px;
  height: 502px;
  position: relative;
  top: -33px;
  left: 6px;
}

.blog-page .frame-166 {
  width: 1116px;
  height: 331px;
  position: absolute;
  top: 108px;
  left: 323px;
}

.blog-page .text-wrapper-160 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 20px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 310px;
  left: 423px;
}

.blog-page .list-10 {
  width: 268px;
  height: 70px;
  position: absolute;
  top: 203px;
  left: 424px;
}

.blog-page .link-item-26 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.blog-page .symbol-28 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 23px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 21px;
}

.blog-page .link-item-27 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 99px;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.blog-page .symbol-29 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 33px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 18px;
}

.blog-page .link-item-28 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 197px;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.blog-page .symbol-30 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 42px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.blog-page .goessy-black-logo-10 {
  width: 235px;
  height: 132px;
  position: absolute;
  top: -44px;
  left: 456px;
}

.blog-page .frame-167 {
  width: 1148px;
  height: 58px;
  position: absolute;
  top: 215px;
  left: 307px;
}

.blog-page .overlap-79 {
  width: 86px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 0;
}

.blog-page .text-wrapper-161 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 44px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 43.8px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .line-25 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 41px;
  left: 3px;
}

.blog-page .overlap-80 {
  width: 250px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 134px;
}

.blog-page .text-wrapper-162 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 44px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .overlap-81 {
  width: 130px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 431px;
}

.blog-page .overlap-group-35 {
  width: 126px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 611px;
}

.blog-page .line-26 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 41px;
  left: 0;
}

.blog-page .overlap-82 {
  width: 63px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 785px;
}

.blog-page .line-27 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 41px;
  left: 2px;
}

.blog-page .overlap-83 {
  width: 255px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 897px;
}

.blog-page .decor-53 {
  width: 662px;
  height: 1233px;
  position: absolute;
  top: 5px;
  left: 0;
}

.blog-page .decor-54 {
  width: 563px;
  height: 451px;
  position: absolute;
  top: 936px;
  left: 0;
}

.blog-page .ellipse-53 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 161.45px;
  width: 394px;
  height: 323px;
  position: absolute;
  top: 1012px;
  left: 1659px;
}

.blog-page .frame-168 {
  border: 2px solid #000;
  border-radius: 20px;
  width: 199px;
  height: 73px;
  position: absolute;
  top: 620px;
  left: 860px;
}

.blog-page .frame-169 {
  width: 128px;
  height: 21px;
  position: relative;
  top: 24px;
  left: 34px;
}

.blog-page .line-28 {
  width: 15px;
  height: 18px;
  position: absolute;
  top: 2px;
  left: 113px;
}

.blog-page .text-wrapper-163 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 21px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: -1px;
  left: 0;
}

.terms-and-conditions-screen {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.terms-and-conditions-screen .terms-and-conditions-2 {
  background-color: #fff;
  width: 1920px;
  height: 2541px;
  position: relative;
  overflow: hidden;
}

.terms-and-conditions-screen .decor-55 {
  width: 506px;
  height: 559px;
  position: absolute;
  top: -3297px;
  left: -6397px;
}

.terms-and-conditions-screen .decor-56 {
  width: 506px;
  height: 559px;
  position: absolute;
  top: -1978px;
  left: -6586px;
}

.terms-and-conditions-screen .ellipse-54 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 3990px;
  left: 1651px;
}

.terms-and-conditions-screen .overlap-84 {
  width: 2150px;
  height: 2870px;
  position: absolute;
  top: -402px;
  left: -131px;
}

.terms-and-conditions-screen .goessy-logo-pdf-12 {
  width: 106px;
  height: 60px;
  position: absolute;
  top: 436px;
  left: 225px;
}

.terms-and-conditions-screen .text-wrapper-164 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  left: 1254px;
}

.terms-and-conditions-screen .text-wrapper-165 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  left: 1367px;
}

.terms-and-conditions-screen .text-wrapper-166 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  left: 1603px;
}

.terms-and-conditions-screen .text-wrapper-167 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  left: 1751px;
}

.terms-and-conditions-screen .frame-170 {
  width: 2150px;
  height: 2859px;
  position: absolute;
  top: 0;
  left: 0;
}

.terms-and-conditions-screen .overlap-85 {
  width: 2051px;
  height: 2859px;
  position: relative;
}

.terms-and-conditions-screen .overlap-group-36 {
  width: 1920px;
  height: 2579px;
  position: absolute;
  top: 0;
  left: 131px;
}

.terms-and-conditions-screen .decor-57 {
  width: 656px;
  height: 1057px;
  position: absolute;
  top: 1482px;
  left: 1264px;
}

.terms-and-conditions-screen .group-44 {
  filter: blur(1569.58px);
  background-color: #f0da904c;
  border-radius: 693.25px / 432.84px;
  width: 1386px;
  height: 866px;
  position: absolute;
  top: 0;
  left: 267px;
}

.terms-and-conditions-screen .decor-58 {
  width: 744px;
  height: 1057px;
  position: absolute;
  top: 461px;
  left: 0;
}

.terms-and-conditions-screen .decor-59 {
  width: 672px;
  height: 1057px;
  position: absolute;
  top: 666px;
  left: 1248px;
}

.terms-and-conditions-screen .group-45 {
  width: 624px;
  height: 946px;
  position: absolute;
  top: 1540px;
  left: 729px;
  transform: rotate(-32.69deg);
}

.terms-and-conditions-screen .ellipse-55 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 700px;
  left: 110px;
}

.terms-and-conditions-screen .ellipse-56 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 420px;
  left: 0;
}

.terms-and-conditions-screen .ellipse-57 {
  filter: blur(446.03px);
  opacity: .3;
  background-color: #f0da90;
  border-radius: 197px / 123px;
  width: 394px;
  height: 246px;
  position: absolute;
  top: 0;
  left: 230px;
}

.terms-and-conditions-screen .group-46 {
  width: 624px;
  height: 946px;
  position: absolute;
  top: 1913px;
  left: 0;
}

.terms-and-conditions-screen .row-9 {
  background-image: url("item.5f700304.png");
  background-size: 100% 100%;
  width: 1775px;
  height: 436px;
  position: absolute;
  top: 2434px;
  left: 204px;
}

.terms-and-conditions-screen .overlap-86 {
  background-image: url("item-1.30152dd8.png");
  background-size: 100% 100%;
  width: 1762px;
  height: 502px;
  position: relative;
  top: -33px;
  left: 6px;
}

.terms-and-conditions-screen .frame-171 {
  width: 1116px;
  height: 331px;
  position: absolute;
  top: 108px;
  left: 323px;
}

.terms-and-conditions-screen .text-wrapper-168 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 20px;
  font-family: Bai Jamjuree, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 310px;
  left: 423px;
}

.terms-and-conditions-screen .list-11 {
  width: 268px;
  height: 70px;
  position: absolute;
  top: 203px;
  left: 424px;
}

.terms-and-conditions-screen .link-item-29 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.terms-and-conditions-screen .symbol-31 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 23px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 21px;
}

.terms-and-conditions-screen .link-item-30 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 99px;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.terms-and-conditions-screen .symbol-32 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 33px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 18px;
}

.terms-and-conditions-screen .link-item-31 {
  background-color: #000;
  border-radius: 35.24px;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 197px;
  box-shadow: 0 14.1px 14.1px #0000001a;
}

.terms-and-conditions-screen .symbol-33 {
  color: orange;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 42px;
  height: 36px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  line-height: 36.3px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.terms-and-conditions-screen .goessy-black-logo-11 {
  width: 235px;
  height: 132px;
  position: absolute;
  top: -44px;
  left: 456px;
}

.terms-and-conditions-screen .frame-172 {
  width: 1190px;
  height: 58px;
  position: absolute;
  top: 219px;
  left: 264px;
}

.terms-and-conditions-screen .overlap-87 {
  width: 86px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 0;
}

.terms-and-conditions-screen .text-wrapper-169 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 44px;
  font-family: Museo Sans-300, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  left: 0;
}

.terms-and-conditions-screen .line-29 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 40px;
  left: 3px;
}

.terms-and-conditions-screen .overlap-88 {
  width: 250px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 134px;
}

.terms-and-conditions-screen .overlap-89 {
  width: 130px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 431px;
}

.terms-and-conditions-screen .overlap-90 {
  width: 126px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 611px;
}

.terms-and-conditions-screen .line-30 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 40px;
  left: 0;
}

.terms-and-conditions-screen .overlap-91 {
  width: 63px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 785px;
}

.terms-and-conditions-screen .line-31 {
  width: 30px;
  height: 2px;
  position: absolute;
  top: 40px;
  left: 2px;
}

.terms-and-conditions-screen .overlap-group-37 {
  width: 255px;
  height: 44px;
  position: absolute;
  top: -1px;
  left: 897px;
}

.terms-and-conditions-screen .frame-173 {
  width: 57px;
  height: 33px;
  position: absolute;
  top: 452px;
  left: 1895px;
}

.terms-and-conditions-screen .text-wrapper-170 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 33px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 32.8px;
  position: absolute;
  top: -1px;
  left: 0;
}

.terms-and-conditions-screen .frame-174 {
  background-color: #fff;
  border-radius: 40px;
  width: 1141px;
  height: 1655px;
  position: absolute;
  top: 579px;
  left: 811px;
  box-shadow: 0 0 10px #00000040;
}

.terms-and-conditions-screen .privacy-policies-2 {
  color: #000;
  letter-spacing: 0;
  width: 1043px;
  height: 1522px;
  font-family: undefined-Regular, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 26px;
  position: absolute;
  top: 74px;
  left: 56px;
}

.terms-and-conditions-screen .ellipse-58 {
  filter: blur(128.42px);
  opacity: .3;
  background-color: #c090f080;
  border-radius: 178.5px / 175.93px;
  width: 357px;
  height: 352px;
  position: absolute;
  top: 3003px;
  left: 732px;
}

.terms-and-conditions-screen .frame-175 {
  background-color: #fff;
  border-radius: 40px;
  width: 528px;
  height: 535px;
  position: fixed;
  top: 177px;
  left: 99px;
  box-shadow: 0 0 10px #00000040;
}

.terms-and-conditions-screen .frame-176 {
  background-color: orange;
  border-radius: 20px;
  width: 441px;
  height: 96px;
  position: absolute;
  top: 95px;
  left: 47px;
}

.terms-and-conditions-screen .text-wrapper-171 {
  color: #22272c;
  letter-spacing: 0;
  white-space: nowrap;
  width: 223px;
  height: 28px;
  font-family: Museo Sans-500, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 33px;
  left: 56px;
}

.terms-and-conditions-screen .frame-177 {
  background-color: #fff;
  border-radius: 20px;
  width: 441px;
  height: 96px;
  position: absolute;
  top: 215px;
  left: 47px;
  box-shadow: 0 0 4px #00000040;
}

.terms-and-conditions-screen .frame-178 {
  background-color: #fff;
  border-radius: 20px;
  width: 441px;
  height: 96px;
  position: absolute;
  top: 335px;
  left: 47px;
  box-shadow: 0 0 4px #00000040;
}
/*# sourceMappingURL=index.c3bdb21d.css.map */
