.terms-and-conditions {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.terms-and-conditions .div {
  background-color: #ffffff;
  position: relative;
}

.terms-and-conditions .decor {
  height: 559px;
  left: -6397px;
  position: absolute;
  top: -3297px;
  width: 506px;
}

.terms-and-conditions .img {
  height: 559px;
  left: -6586px;
  position: absolute;
  top: -1978px;
  width: 506px;
}

.terms-and-conditions .ellipse {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 1651px;
  opacity: 0.3;
  position: absolute;
  top: 3990px;
  width: 394px;
}

.terms-and-conditions .overlap {
  height: 2870px;
  left: -131px;
  position: absolute;
  top: -402px;
  width: 2150px;
}

.terms-and-conditions .goessy-logo-pdf {
  height: 60px;
  left: 225px;
  position: absolute;
  top: 436px;
  width: 106px;
}

.terms-and-conditions .text-wrapper {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1254px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  white-space: nowrap;
}

.terms-and-conditions .text-wrapper-2 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1367px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  white-space: nowrap;
}

.terms-and-conditions .text-wrapper-3 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1603px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  white-space: nowrap;
}

.terms-and-conditions .text-wrapper-4 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1751px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  white-space: nowrap;
}

.terms-and-conditions .frame {
  height: 2859px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2150px;
}

.terms-and-conditions .overlap-group {
  height: 2859px;
  position: relative;
  width: 2051px;
}

.terms-and-conditions .overlap-group-2 {
  height: 2579px;
  left: 131px;
  position: absolute;
  top: 0;
  width: 1920px;
}

.terms-and-conditions .decor-2 {
  height: 1057px;
  left: 1264px;
  position: absolute;
  top: 1482px;
  width: 656px;
}

.terms-and-conditions .group {
  background-color: #f0da904c;
  border-radius: 693.25px/432.84px;
  filter: blur(1569.58px);
  height: 866px;
  left: 267px;
  position: absolute;
  top: 0;
  width: 1386px;
}

.terms-and-conditions .decor-3 {
  height: 1057px;
  left: 0;
  position: absolute;
  top: 461px;
  width: 744px;
}

.terms-and-conditions .decor-4 {
  height: 1057px;
  left: 1248px;
  position: absolute;
  top: 666px;
  width: 672px;
}

.terms-and-conditions .group-2 {
  height: 946px;
  left: 729px;
  position: absolute;
  top: 1540px;
  transform: rotate(-32.69deg);
  width: 624px;
}

.terms-and-conditions .ellipse-2 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 110px;
  opacity: 0.3;
  position: absolute;
  top: 700px;
  width: 394px;
}

.terms-and-conditions .ellipse-3 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 420px;
  width: 394px;
}

.terms-and-conditions .ellipse-4 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 230px;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 394px;
}

.terms-and-conditions .group-3 {
  height: 946px;
  left: 0;
  position: absolute;
  top: 1913px;
  width: 624px;
}

.terms-and-conditions .row {
  background-image: url(../../../static/img/item.png);
  background-size: 100% 100%;
  height: 436px;
  left: 204px;
  position: absolute;
  top: 2434px;
  width: 1775px;
}

.terms-and-conditions .overlap-2 {
  background-image: url(../../../static/img/item-1.png);
  background-size: 100% 100%;
  height: 502px;
  left: 6px;
  position: relative;
  top: -33px;
  width: 1762px;
}

.terms-and-conditions .frame-2 {
  height: 331px;
  left: 323px;
  position: absolute;
  top: 108px;
  width: 1116px;
}

.terms-and-conditions .text-wrapper-5 {
  color: #000000;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 423px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 310px;
  white-space: nowrap;
}

.terms-and-conditions .list {
  height: 70px;
  left: 424px;
  position: absolute;
  top: 203px;
  width: 268px;
}

.terms-and-conditions .link-item {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 70px;
}

.terms-and-conditions .symbol {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 21px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 23px;
}

.terms-and-conditions .symbol-wrapper {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 99px;
  position: absolute;
  top: 0;
  width: 70px;
}

.terms-and-conditions .symbol-2 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 18px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 33px;
}

.terms-and-conditions .div-wrapper {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 197px;
  position: absolute;
  top: 0;
  width: 70px;
}

.terms-and-conditions .symbol-3 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 15px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 42px;
}

.terms-and-conditions .goessy-black-logo {
  height: 132px;
  left: 456px;
  position: absolute;
  top: -44px;
  width: 235px;
}

.terms-and-conditions .frame-3 {
  height: 58px;
  left: 264px;
  position: absolute;
  top: 219px;
  width: 1190px;
}

.terms-and-conditions .overlap-3 {
  height: 44px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 86px;
}

.terms-and-conditions .text-wrapper-6 {
  color: #000000;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 44px;
  left: 0;
  letter-spacing: 0;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.terms-and-conditions .line {
  height: 2px;
  left: 3px;
  position: absolute;
  top: 40px;
  width: 30px;
}

.terms-and-conditions .overlap-4 {
  height: 44px;
  left: 134px;
  position: absolute;
  top: -1px;
  width: 250px;
}

.terms-and-conditions .overlap-group-3 {
  height: 44px;
  left: 431px;
  position: absolute;
  top: -1px;
  width: 130px;
}

.terms-and-conditions .overlap-5 {
  height: 44px;
  left: 611px;
  position: absolute;
  top: -1px;
  width: 126px;
}

.terms-and-conditions .line-2 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 30px;
}

.terms-and-conditions .overlap-6 {
  height: 44px;
  left: 785px;
  position: absolute;
  top: -1px;
  width: 63px;
}

.terms-and-conditions .line-3 {
  height: 2px;
  left: 2px;
  position: absolute;
  top: 40px;
  width: 30px;
}

.terms-and-conditions .overlap-7 {
  height: 44px;
  left: 897px;
  position: absolute;
  top: -1px;
  width: 255px;
}

.terms-and-conditions .frame-4 {
  height: 33px;
  left: 1895px;
  position: absolute;
  top: 452px;
  width: 57px;
}

.terms-and-conditions .text-wrapper-7 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 0;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.terms-and-conditions .privacy-policies-wrapper {
  background-color: #ffffff;
  border-radius: 40px;
  box-shadow: 0px 0px 10px #00000040;
  height: 1655px;
  left: 811px;
  position: absolute;
  top: 579px;
  width: 1141px;
}

.terms-and-conditions .privacy-policies {
  color: #000000;
  font-family: "undefined-Regular", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 1522px;
  left: 56px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 74px;
  width: 1043px;
}

.terms-and-conditions .ellipse-5 {
  background-color: #c090f080;
  border-radius: 178.5px/175.93px;
  filter: blur(128.42px);
  height: 352px;
  left: 732px;
  opacity: 0.3;
  position: absolute;
  top: 3003px;
  width: 357px;
}

.terms-and-conditions .frame-5 {
  background-color: #ffffff;
}

.terms-and-conditions .p {
  color: #000000;
  font-family: "undefined-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 2739px;
  left: 26px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 22px;
  width: 278px;
}

.terms-and-conditions .frame-6 {
  background-color: #ffa500;
  border-radius: 20px;
  height: 96px;
  left: 47px;
  position: absolute;
  top: 95px;
  width: 441px;
}

.terms-and-conditions .text-wrapper-8 {
  color: #22272c;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 28px;
  left: 56px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 33px;
  white-space: nowrap;
  width: 223px;
}

.terms-and-conditions .frame-7 {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 4px #00000040;
  height: 96px;
  left: 47px;
  position: absolute;
  top: 215px;
  width: 441px;
}

.terms-and-conditions .frame-8 {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 4px #00000040;
  height: 96px;
  left: 47px;
  position: absolute;
  top: 335px;
  width: 441px;
}

.terms-and-conditions .navbar {
  background-color: #ffffff;
  height: 73px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.terms-and-conditions .link-home {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 626px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.terms-and-conditions .link-our {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 726px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.terms-and-conditions .link-lokally-AI {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 932px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.terms-and-conditions .link-business-with {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 1063px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.terms-and-conditions .link-about-us {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 1265px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.terms-and-conditions .overlap-group-wrapper {
  height: 34px;
  left: 264px;
  position: absolute;
  top: 23px;
  width: 92px;
}

.terms-and-conditions .overlap-group-4 {
  height: 34px;
  position: relative;
  top: -1px;
}

.terms-and-conditions .rectangle {
  background-color: #000000;
  border: 0.69px solid;
  border-radius: 8px;
  height: 31px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 92px;
}

.terms-and-conditions .text-wrapper-9 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 14.4px;
  font-weight: 700;
  left: 14px;
  letter-spacing: -0.57px;
  line-height: 34px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.terms-and-conditions .arrow {
  height: 7px;
  left: 65px;
  position: absolute;
  top: 16px;
  width: 13px;
}

.terms-and-conditions .rectangle-2 {
  background-color: #ffa500;
  height: 4px;
  left: 0;
  position: absolute;
  top: 69px;
  width: 375px;
}

.terms-and-conditions .goessy-logo-pdf-2 {
  height: 60px;
  left: 25px;
  position: absolute;
  top: 8px;
  width: 106px;
}

.terms-and-conditions .frame-wrapper {
  background-color: #ffffff;
  box-shadow: 0px 4px 4px #00000040;
  height: 81px;
  left: 0;
  position: fixed;
  top: 73px;
  width: 375px;
}

.terms-and-conditions .frame-9 {
  height: 41px;
  overflow-x: scroll;
  position: relative;
  top: 20px;
}

.terms-and-conditions .frame-10 {
  background-color: #ffa500;
  border-radius: 12px;
  height: 41px;
  left: 10px;
  position: absolute;
  top: 0;
  width: 186px;
}

.terms-and-conditions .text-wrapper-10 {
  color: #22272c;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 28px;
  left: 22px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 6px;
  white-space: nowrap;
}

.terms-and-conditions .frame-11 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #708090;
  border-radius: 12px;
  height: 41px;
  left: 201px;
  position: absolute;
  top: 0;
  width: 156px;
}

.terms-and-conditions .text-wrapper-11 {
  color: #22272c;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 28px;
  left: 27px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 5px;
  white-space: nowrap;
}

.terms-and-conditions .frame-12 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #708090;
  border-radius: 12px;
  height: 41px;
  left: 362px;
  position: absolute;
  top: 0;
  width: 156px;
}

.terms-and-conditions .text-wrapper-12 {
  color: #22272c;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 28px;
  left: 40px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 5px;
  white-space: nowrap;
}
