.lokally-AI {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.lokally-AI .lokally-AI-mob {
  background-color: #ffffff;
  overflow: hidden;
}

.lokally-AI .frame-50 {
  position: absolute;
}

.lokally-AI .frame-51 {
  height: 308px;
  position: relative;
}

.lokally-AI .frame-52 {
  height: 25px;
  left: 52px;
  position: absolute;
  top: 0;
  width: 203px;
}

.lokally-AI .how-it-works-wrapper {
  height: 31px;
  position: relative;
}

.lokally-AI .how-it-works {
  color: transparent;
  font-family: "Poppins", Helvetica;
  font-size: 30px;
  font-weight: 500;
  height: 22px;
  left: 7px;
  letter-spacing: 0;
  line-height: 21.3px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.lokally-AI .text-wrapper-63 {
  color: #000000;
}

.lokally-AI .text-wrapper-64 {
  color: #211715;
}

.lokally-AI .lokally-AI-helps-you {
  color: #870058;
  font-family: "Grandstander-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 4px;
  letter-spacing: -0.72px;
  line-height: 28px;
  position: absolute;
  text-align: center;
  top: 44px;
  width: 305px;
}

.lokally-AI .text-wrapper-65 {
  letter-spacing: -0.13px;
}

.lokally-AI .text-wrapper-66 {
  font-family: "Josefin Sans", Helvetica;
  letter-spacing: -0.13px;
}

.lokally-AI .article-wie {
  height: 215px;
  left: -9px;
  position: absolute;
  top: 143px;
  width: 331px;
}

.lokally-AI .group-20 {
  height: 946px;
  left: 835px;
  position: absolute;
  top: 1564px;
  transform: rotate(-32.69deg);
  width: 624px;
}

.lokally-AI .ellipse-22 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 110px;
  opacity: 0.3;
  position: absolute;
  top: 700px;
  width: 394px;
}

.lokally-AI .ellipse-23 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 420px;
  width: 394px;
}

.lokally-AI .ellipse-24 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 230px;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 394px;
}

.lokally-AI .group-21 {
  height: 5748px;
  left: 0;
  position: absolute;
  top: 544px;
  width: 2306px;
}

.lokally-AI .decor-26 {
  height: 1233px;
  left: 194px;
  position: absolute;
  top: 3029px;
  width: 662px;
}

.lokally-AI .overlap-group-18 {
  height: 2304px;
  left: 1466px;
  position: absolute;
  top: 23px;
  width: 648px;
}

.lokally-AI .decor-27 {
  height: 1233px;
  left: 0;
  position: absolute;
  top: 1071px;
  width: 648px;
}

.lokally-AI .decor-28 {
  height: 1233px;
  left: 84px;
  position: absolute;
  top: 0;
  width: 564px;
}

.lokally-AI .decor-29 {
  height: 1137px;
  left: 194px;
  position: absolute;
  top: 4760px;
  width: 563px;
}

.lokally-AI .ellipse-25 {
  background-color: #f0da90;
  border-radius: 197px/161.45px;
  filter: blur(446.03px);
  height: 323px;
  left: 1853px;
  opacity: 0.3;
  position: absolute;
  top: 4836px;
  width: 394px;
}

.lokally-AI .decor-30 {
  height: 1123px;
  left: 194px;
  position: absolute;
  top: -136px;
  width: 622px;
}

.lokally-AI .group-22 {
  height: 1242px;
  left: 71px;
  position: absolute;
  top: 1582px;
  width: 624px;
}

.lokally-AI .ellipse-26 {
  background-color: #f0da9080;
  border-radius: 197px/161.45px;
  filter: blur(446.03px);
  height: 323px;
  left: 110px;
  opacity: 0.3;
  position: absolute;
  top: 919px;
  width: 394px;
}

.lokally-AI .ellipse-27 {
  background-color: #f0da9080;
  border-radius: 197px/161.45px;
  filter: blur(446.03px);
  height: 323px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 551px;
  width: 394px;
}

.lokally-AI .ellipse-28 {
  background-color: #f0da9080;
  border-radius: 197px/161.45px;
  filter: blur(446.03px);
  height: 323px;
  left: 230px;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 394px;
}

.lokally-AI .group-23 {
  background-color: #f0da904c;
  border-radius: 693.25px/432.84px;
  filter: blur(1569.58px);
  height: 866px;
  left: 461px;
  position: absolute;
  top: 0;
  width: 1386px;
}

.lokally-AI .ellipse-29 {
  background-color: #f0da9080;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 1853px;
  opacity: 0.3;
  position: absolute;
  top: 4552px;
  width: 394px;
}

.lokally-AI .text-wrapper-67 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1287px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 457px;
  white-space: nowrap;
}

.lokally-AI .text-wrapper-68 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1400px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 457px;
  white-space: nowrap;
}

.lokally-AI .text-wrapper-69 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1636px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 457px;
  white-space: nowrap;
}

.lokally-AI .text-wrapper-70 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1784px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 457px;
  white-space: nowrap;
}

.lokally-AI .frame-53 {
  height: 242px;
  left: 628px;
  position: absolute;
  top: 790px;
  width: 1052px;
}

.lokally-AI .intro {
  height: 150px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1052px;
}

.lokally-AI .healine {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 7.21px;
  position: relative;
}

.lokally-AI .text {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 3.61px;
  position: relative;
  width: 100%;
}

.lokally-AI .meet-lokally-AI {
  color: var(--our-grey);
  font-family: "Poppins", Helvetica;
  font-size: 150px;
  font-weight: 400;
  letter-spacing: -4.5px;
  line-height: 131.2px;
  margin-top: -0.9px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.lokally-AI .text-wrapper-71 {
  color: #708090;
  font-weight: 600;
  letter-spacing: -6.75px;
}

.lokally-AI .text-wrapper-72 {
  color: #708090;
  font-family: "Grandstander", Helvetica;
  font-weight: 600;
  letter-spacing: -6.75px;
}

.lokally-AI .text-wrapper-73 {
  color: #ffa500;
  font-family: "Grandstander", Helvetica;
  font-weight: 600;
  letter-spacing: -6.75px;
}

.lokally-AI .text-wrapper-74 {
  color: var(--black);
  font-family: "Poppins", Helvetica;
  font-size: 40px;
  font-weight: 400;
  left: 201px;
  letter-spacing: -1.2px;
  line-height: 37.5px;
  position: absolute;
  text-align: center;
  top: 189px;
  white-space: nowrap;
}

.lokally-AI .article-wie-2 {
  background-color: #ffffff;
  border-radius: 117.2px;
  box-shadow: 0px 2.34px 16.1px #0000001a;
  height: 586px;
  left: 561px;
  position: absolute;
  top: 4236px;
  width: 1176px;
}

.lokally-AI .background {
  background-color: #ffa500;
  border-radius: 27.4px;
  height: 55px;
  left: 59px;
  position: absolute;
  top: 48px;
  width: 55px;
}

.lokally-AI .heading-the-way-to {
  color: var(--black);
  font-family: "Museo Sans-500", Helvetica;
  font-size: 50px;
  font-weight: 400;
  height: 50px;
  left: 218px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  text-align: center;
  top: 191px;
  white-space: nowrap;
}

.lokally-AI .text-wrapper-75 {
  color: var(--our-grey);
  font-family: "Josefin Sans", Helvetica;
  font-size: 36px;
  font-weight: 400;
  height: 92px;
  left: 81px;
  letter-spacing: 0;
  line-height: 46px;
  position: absolute;
  text-align: center;
  top: 315px;
  width: 1014px;
}

.lokally-AI .container {
  height: 1066px;
  left: 499px;
  position: absolute;
  top: 2970px;
  width: 1315px;
}

.lokally-AI .group-24 {
  height: 920px;
  left: 0;
  position: absolute;
  top: 138px;
  width: 1317px;
}

.lokally-AI .background-shadow {
  background-color: #ffffff;
  border-radius: 47.39px;
  box-shadow: inset 0px 0px 8.74px #ffa500;
  height: 301px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 643px;
}

.lokally-AI .img-2 {
  height: 151px;
  left: 46px;
  position: absolute;
  top: 75px;
  width: 151px;
}

.lokally-AI .frame-54 {
  height: 148px;
  left: 236px;
  position: absolute;
  top: 76px;
  width: 347px;
}

.lokally-AI .text-wrapper-76 {
  color: #000000;
  font-family: "Josefin Sans", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 108px;
  left: 0;
  letter-spacing: 0;
  line-height: 36px;
  position: absolute;
  top: 49px;
  width: 347px;
}

.lokally-AI .text-wrapper-77 {
  color: #870058;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 38px;
  left: 0;
  letter-spacing: 0;
  line-height: 37.9px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.lokally-AI .background-shadow-2 {
  background-color: #ffffff;
  border-radius: 47.39px;
  box-shadow: inset 0px 0px 8.74px #ffa500;
  height: 301px;
  left: 673px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 643px;
}

.lokally-AI .background-shadow-3 {
  background-color: #ffffff;
  border-radius: 47.39px;
  box-shadow: inset 0px 0px 8.74px #ffa500;
  height: 301px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 336px;
  width: 643px;
}

.lokally-AI .frame-55 {
  height: 148px;
  left: 237px;
  position: absolute;
  top: 76px;
  width: 347px;
}

.lokally-AI .background-shadow-4 {
  background-color: #ffffff;
  border-radius: 47.39px;
  box-shadow: inset 0px 0px 8.74px #ffa500;
  height: 301px;
  left: 673px;
  overflow: hidden;
  position: absolute;
  top: 336px;
  width: 643px;
}

.lokally-AI .background-shadow-5 {
  background-color: #ffffff;
  border-radius: 47.39px;
  box-shadow: inset 0px 0px 8.74px #ffa500;
  height: 249px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 671px;
  width: 1315px;
}

.lokally-AI .heading-digital {
  color: #870058;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 38px;
  left: 237px;
  letter-spacing: 0;
  line-height: 37.9px;
  position: absolute;
  top: 61px;
  white-space: nowrap;
}

.lokally-AI .indicator-svgrepo {
  height: 151px;
  left: 46px;
  position: absolute;
  top: 49px;
  width: 151px;
}

.lokally-AI .text-wrapper-78 {
  color: #000000;
  font-family: "Josefin Sans", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 72px;
  left: 237px;
  letter-spacing: 0;
  line-height: 36px;
  position: absolute;
  top: 119px;
  width: 864px;
}

.lokally-AI .why-choose-lokally {
  color: transparent;
  font-family: "Poppins", Helvetica;
  font-size: 70px;
  font-weight: 400;
  height: 88px;
  left: 245px;
  letter-spacing: 0;
  line-height: 87.4px;
  position: absolute;
  text-align: center;
  top: -1px;
  white-space: nowrap;
}

.lokally-AI .text-wrapper-79 {
  color: #000000;
  font-weight: 500;
}

.lokally-AI .text-wrapper-80 {
  color: #ffa500;
  font-family: "Grandstander", Helvetica;
  font-weight: 500;
}

.lokally-AI .group-25 {
  height: 660px;
  left: 682px;
  position: absolute;
  top: 2089px;
  width: 944px;
}

.lokally-AI .article-wie-3 {
  height: 475px;
  left: 28px;
  object-fit: cover;
  position: absolute;
  top: 220px;
  width: 888px;
}

.lokally-AI .heading {
  height: 79px;
  left: 239px;
  position: absolute;
  top: 0;
  width: 465px;
}

.lokally-AI .text-wrapper-81 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 70px;
  font-weight: 500;
  height: 71px;
  left: 6px;
  letter-spacing: 0;
  line-height: 70.3px;
  position: absolute;
  text-align: center;
  top: 2px;
  white-space: nowrap;
}

.lokally-AI .lokally-AI-helps-you-wrapper {
  height: 92px;
  left: 0;
  position: absolute;
  top: 109px;
  width: 946px;
}

.lokally-AI .lokally-AI-helps-you-2 {
  color: #870058;
  font-family: "Grandstander", Helvetica;
  font-size: 36px;
  font-weight: 400;
  height: 92px;
  left: 0;
  letter-spacing: 0;
  line-height: 46px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 944px;
}

.lokally-AI .text-wrapper-82 {
  font-weight: 500;
}

.lokally-AI .text-wrapper-83 {
  font-family: "Josefin Sans", Helvetica;
  font-weight: 500;
}

.lokally-AI .text-wrapper-84 {
  font-family: "Josefin Sans", Helvetica;
}

.lokally-AI .we-are-in-wrapper {
  height: 280px;
  left: 494px;
  position: absolute;
  top: 1503px;
  width: 1322px;
}

.lokally-AI .we-are-in {
  color: var(--black);
  font-family: "Josefin Sans", Helvetica;
  font-size: 50px;
  font-weight: 400;
  left: 0;
  letter-spacing: -2px;
  line-height: 70px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 1320px;
}

.lokally-AI .row-6 {
  background-image: url(../../../static/img/item-8.png);
  background-size: 100% 100%;
  height: 436px;
  left: 267px;
  position: absolute;
  top: 5921px;
  width: 1775px;
}

.lokally-AI .overlap-39 {
  background-image: url(../../../static/img/item-9.png);
  background-size: 100% 100%;
  height: 502px;
  left: 6px;
  position: relative;
  top: -33px;
  width: 1762px;
}

.lokally-AI .frame-56 {
  height: 331px;
  left: 323px;
  position: absolute;
  top: 108px;
  width: 1116px;
}

.lokally-AI .text-wrapper-85 {
  color: #000000;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 423px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 310px;
  white-space: nowrap;
}

.lokally-AI .list-5 {
  height: 70px;
  left: 424px;
  position: absolute;
  top: 203px;
  width: 268px;
}

.lokally-AI .link-item-11 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 70px;
}

.lokally-AI .symbol-13 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 21px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 23px;
}

.lokally-AI .link-item-12 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 99px;
  position: absolute;
  top: 0;
  width: 70px;
}

.lokally-AI .symbol-14 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 18px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 33px;
}

.lokally-AI .link-item-13 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 197px;
  position: absolute;
  top: 0;
  width: 70px;
}

.lokally-AI .symbol-15 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 15px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 42px;
}

.lokally-AI .goessy-black-logo-5 {
  height: 132px;
  left: 456px;
  position: absolute;
  top: -44px;
  width: 235px;
}

.lokally-AI .frame-57 {
  height: 58px;
  left: 264px;
  position: absolute;
  top: 219px;
  width: 1190px;
}

.lokally-AI .overlap-40 {
  height: 44px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 86px;
}

.lokally-AI .text-wrapper-86 {
  color: #000000;
  display: block;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 44px;
  left: 0;
  letter-spacing: 0;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.lokally-AI .line-12 {
  height: 2px;
  left: 3px;
  position: absolute;
  top: 40px;
  width: 30px;
}

.lokally-AI .overlap-41 {
  height: 44px;
  left: 134px;
  position: absolute;
  top: -1px;
  width: 250px;
}

.lokally-AI .text-wrapper-87 {
  color: #000000;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 44px;
  left: 0;
  letter-spacing: 0;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.lokally-AI .overlap-group-19 {
  height: 44px;
  left: 431px;
  position: absolute;
  top: -1px;
  width: 130px;
}

.lokally-AI .overlap-42 {
  height: 44px;
  left: 611px;
  position: absolute;
  top: -1px;
  width: 126px;
}

.lokally-AI .line-13 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 30px;
}

.lokally-AI .overlap-43 {
  height: 44px;
  left: 785px;
  position: absolute;
  top: -1px;
  width: 63px;
}

.lokally-AI .line-14 {
  height: 2px;
  left: 2px;
  position: absolute;
  top: 40px;
  width: 30px;
}

.lokally-AI .overlap-44 {
  height: 44px;
  left: 897px;
  position: absolute;
  top: -1px;
  width: 255px;
}

.lokally-AI .group-26 {
  height: 699px;
  left: 442px;
  position: absolute;
  top: 5022px;
  width: 1423px;
}

.lokally-AI .frame-58 {
  height: 550px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1423px;
}

.lokally-AI .text-wrapper-88 {
  color: #969696;
  font-family: "Montserrat", Helvetica;
  font-size: 120px;
  font-weight: 700;
  height: 600px;
  left: 0;
  letter-spacing: 0;
  line-height: 150px;
  opacity: 1;
  position: absolute;
  top: -34px;
  width: 1471px;
}

.lokally-AI .frame-59 {
  height: 69px;
  left: 471px;
  position: absolute;
  top: 630px;
  width: 482px;
}

.lokally-AI .frame-60 {
  display: block;
  height: 33px;
  left: 1928px;
  position: absolute;
  top: 458px;
  width: 57px;
}

.lokally-AI .text-wrapper-89 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 0;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.lokally-AI .frame-61 {
  height: 128px;
  left: 143px;
  position: absolute;
  top: 590px;
  width: 344px;
}

.lokally-AI .text-wrapper-90 {
  color: var(--black);
  font-family: "Josefin Sans", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 16px;
  letter-spacing: -0.88px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: -1px;
  width: 312px;
}

.lokally-AI .frame-62 {
  height: 935px;
  left: 142px;
  position: absolute;
  top: 1264px;
  width: 345px;
}

.lokally-AI .frame-63 {
  height: 823px;
  left: 14px;
  position: absolute;
  top: 108px;
  width: 318px;
}

.lokally-AI .background-shadow-6 {
  background-color: #ffffff;
  border-radius: 23.43px;
  box-shadow: inset 0px 0px 5px #ffa500;
  height: 149px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 318px;
}

.lokally-AI .img-3 {
  height: 75px;
  left: 23px;
  position: absolute;
  top: 37px;
  width: 75px;
}

.lokally-AI .frame-64 {
  height: 93px;
  left: 117px;
  position: absolute;
  top: 28px;
  width: 171px;
}

.lokally-AI .text-wrapper-91 {
  color: #870058;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 19px;
  left: 0;
  letter-spacing: 0;
  line-height: 18.7px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
}

.lokally-AI .text-wrapper-92 {
  color: #000000;
  font-family: "Josefin Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 61px;
  left: 0;
  letter-spacing: 0;
  line-height: 15.1px;
  position: absolute;
  top: 30px;
  width: 171px;
}

.lokally-AI .background-shadow-7 {
  background-color: #ffffff;
  border-radius: 23.43px;
  box-shadow: inset 0px 0px 5px #ffa500;
  height: 149px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 169px;
  width: 318px;
}

.lokally-AI .frame-65 {
  height: 93px;
  left: 117px;
  position: absolute;
  top: 34px;
  width: 172px;
}

.lokally-AI .background-shadow-8 {
  background-color: #ffffff;
  border-radius: 23.43px;
  box-shadow: inset 0px 0px 5px #ffa500;
  height: 149px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 337px;
  width: 318px;
}

.lokally-AI .frame-66 {
  height: 93px;
  left: 117px;
  position: absolute;
  top: 35px;
  width: 171px;
}

.lokally-AI .background-shadow-9 {
  background-color: #ffffff;
  border-radius: 23.43px;
  box-shadow: inset 0px 0px 5px #ffa500;
  height: 149px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 506px;
  width: 318px;
}

.lokally-AI .frame-67 {
  height: 92px;
  left: 117px;
  position: absolute;
  top: 33px;
  width: 171px;
}

.lokally-AI .background-shadow-10 {
  background-color: #ffffff;
  border-radius: 23.43px;
  box-shadow: inset 0px 0px 5px #ffa500;
  height: 149px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 675px;
  width: 318px;
}

.lokally-AI .frame-68 {
  height: 93px;
  left: 117px;
  position: absolute;
  top: 32px;
  width: 171px;
}

.lokally-AI .why-choose-lokally-2 {
  color: transparent;
  font-family: "Poppins", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 80px;
  left: 49px;
  letter-spacing: 0;
  line-height: 40px;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 246px;
}

.lokally-AI .text-wrapper-93 {
  color: #ffa500;
  font-weight: 500;
}

.lokally-AI .article-wie-4 {
  background-color: #ffffff;
  border-radius: 33.97px;
  box-shadow: 0px 0.68px 4.67px #00000026;
  height: 212px;
  left: 145px;
  position: absolute;
  top: 2299px;
  width: 341px;
}

.lokally-AI .background-2 {
  background-color: #ffa500;
  border-radius: 10px;
  height: 20px;
  left: 17px;
  position: absolute;
  top: 14px;
  width: 20px;
}

.lokally-AI .frame-69 {
  height: 115px;
  left: 23px;
  position: absolute;
  top: 49px;
  width: 294px;
}

.lokally-AI .heading-the-way-to-2 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 25px;
  left: 13px;
  letter-spacing: 0;
  line-height: 24.9px;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.lokally-AI .text-wrapper-94 {
  color: var(--our-grey);
  font-family: "Josefin Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 60px;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 58px;
  width: 294px;
}

.lokally-AI .goessy-logo-pdf-6 {
  position: absolute;
}

.lokally-AI .group-27 {
  height: 3355px;
  left: 0;
  position: absolute;
  top: 0;
  width: 591px;
}

.lokally-AI .decor-31 {
  height: 471px;
  left: 291px;
  position: absolute;
  top: 629px;
  width: 211px;
}

.lokally-AI .decor-32 {
  height: 393px;
  left: 128px;
  position: absolute;
  top: 2854px;
  width: 202px;
}

.lokally-AI .ellipse-30 {
  background-color: #f0da9080;
  border-radius: 69.13px/90.39px;
  filter: blur(156.51px);
  height: 181px;
  left: 428px;
  opacity: 0.3;
  position: absolute;
  top: 2707px;
  width: 138px;
}

.lokally-AI .overlap-45 {
  height: 618px;
  left: 128px;
  position: absolute;
  top: -86px;
  width: 374px;
}

.lokally-AI .decor-33 {
  height: 455px;
  left: 0;
  position: absolute;
  top: 0;
  width: 209px;
}

.lokally-AI .decor-34 {
  height: 467px;
  left: 198px;
  position: absolute;
  top: 151px;
  width: 176px;
}

.lokally-AI .group-28 {
  height: 1285px;
  left: 0;
  position: absolute;
  top: 1025px;
  width: 228px;
}

.lokally-AI .ellipse-31 {
  background-color: #f0da9080;
  border-radius: 69px/135px;
  filter: blur(156.51px);
  height: 270px;
  left: 38px;
  opacity: 0.3;
  position: absolute;
  top: 375px;
  width: 138px;
}

.lokally-AI .ellipse-32 {
  background-color: #f0da9080;
  border-radius: 69px/135px;
  filter: blur(156.51px);
  height: 270px;
  left: 38px;
  opacity: 0.3;
  position: absolute;
  top: 1015px;
  width: 138px;
}

.lokally-AI .overlap-group-20 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 0;
  width: 228px;
}

.lokally-AI .ellipse-33 {
  background-color: #f0da9080;
  border-radius: 69.13px/90.39px;
  filter: blur(156.51px);
  height: 181px;
  left: 89px;
  opacity: 0.3;
  position: absolute;
  top: 169px;
  width: 138px;
}

.lokally-AI .ellipse-34 {
  background-color: #f0da90;
  border-radius: 69.13px/90.39px;
  filter: blur(156.51px);
  height: 181px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 138px;
}

.lokally-AI .frame-70 {
  height: 196px;
  left: 140px;
  position: absolute;
  top: 3026px;
  width: 350px;
}

.lokally-AI .overlap-46 {
  border-radius: 21.34px;
  height: 196px;
  position: relative;
}

.lokally-AI .item-13 {
  background-color: #ffa500;
  border-radius: 21.34px;
  height: 196px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 350px;
}

.lokally-AI .goessy-black-logo-6 {
  height: 44px;
  left: 136px;
  position: absolute;
  top: 11px;
  width: 78px;
}

.lokally-AI .text-wrapper-95 {
  color: #000000;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 9px;
  font-weight: 400;
  height: 9px;
  left: 114px;
  letter-spacing: 0;
  line-height: 9px;
  position: absolute;
  text-align: center;
  top: 169px;
  white-space: nowrap;
}

.lokally-AI .list-6 {
  height: 32px;
  left: 114px;
  position: absolute;
  top: 128px;
  width: 121px;
}

.lokally-AI .link-item-14 {
  background-color: #000000;
  border-radius: 15.86px;
  box-shadow: 0px 6.34px 6.34px #0000001a;
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
}

.lokally-AI .symbol-16 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  height: 18px;
  left: 9px;
  letter-spacing: 0;
  line-height: 18.1px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 12px;
}

.lokally-AI .link-item-15 {
  background-color: #000000;
  border-radius: 15.86px;
  box-shadow: 0px 6.34px 6.34px #0000001a;
  height: 32px;
  left: 44px;
  position: absolute;
  top: 0;
  width: 32px;
}

.lokally-AI .symbol-17 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  height: 18px;
  left: 8px;
  letter-spacing: 0;
  line-height: 18.1px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 16px;
}

.lokally-AI .link-item-16 {
  background-color: #000000;
  border-radius: 15.86px;
  box-shadow: 0px 6.34px 6.34px #0000001a;
  height: 32px;
  left: 89px;
  position: absolute;
  top: 0;
  width: 32px;
}

.lokally-AI .symbol-18 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  height: 18px;
  left: 6px;
  letter-spacing: 0;
  line-height: 18.1px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 21px;
}

.lokally-AI .frame-71 {
  height: 56px;
  left: 29px;
  position: absolute;
  top: 63px;
  width: 287px;
}

.lokally-AI .frame-72 {
  height: 27px;
  left: 14px;
  position: absolute;
  top: 0;
  width: 258px;
}

.lokally-AI .frame-73 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 39px;
}

.lokally-AI .overlap-group-21 {
  height: 21px;
  position: relative;
  width: 40px;
}

.lokally-AI .text-wrapper-96 {
  color: #000000;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 21px;
  left: 0;
  letter-spacing: 0;
  line-height: 20.1px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.lokally-AI .line-15 {
  height: 1px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 19px;
  width: 14px;
}

.lokally-AI .frame-74 {
  height: 20px;
  left: 62px;
  position: absolute;
  top: 0;
  width: 114px;
}

.lokally-AI .overlap-47 {
  height: 21px;
  position: relative;
  width: 117px;
}

.lokally-AI .frame-75 {
  height: 20px;
  left: 198px;
  position: absolute;
  top: 0;
  width: 60px;
}

.lokally-AI .overlap-48 {
  height: 21px;
  position: relative;
  width: 61px;
}

.lokally-AI .frame-76 {
  height: 20px;
  left: 19px;
  position: absolute;
  top: 28px;
  width: 248px;
}

.lokally-AI .frame-77 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 57px;
}

.lokally-AI .overlap-group-22 {
  height: 21px;
  position: relative;
  width: 59px;
}

.lokally-AI .line-16 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 19px;
  width: 14px;
}

.lokally-AI .frame-78 {
  height: 20px;
  left: 80px;
  position: absolute;
  top: 0;
  width: 29px;
}

.lokally-AI .overlap-49 {
  height: 21px;
  position: relative;
  top: -1px;
  width: 30px;
}

.lokally-AI .frame-79 {
  height: 20px;
  left: 132px;
  position: absolute;
  top: 0;
  width: 136px;
}

.lokally-AI .overlap-50 {
  height: 21px;
  position: relative;
  width: 119px;
}

.lokally-AI .frame-80 {
  height: 315px;
  left: 151px;
  position: absolute;
  top: 2611px;
  width: 328px;
}

.lokally-AI .frame-81 {
  height: 208px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 328px;
}

.lokally-AI .text-wrapper-97 {
  color: #969696;
  font-family: "Montserrat", Helvetica;
  font-size: 34px;
  font-weight: 700;
  height: 250px;
  left: 0;
  letter-spacing: 0;
  line-height: 50px;
  opacity: 1;
  position: absolute;
  top: -23px;
  width: 334px;
}

.lokally-AI .frame-82 {
  height: 41px;
  left: 22px;
  position: absolute;
  top: 274px;
  width: 285px;
}

.lokally-AI .group-29 {
  height: 81px;
  left: 152px;
  position: absolute;
  top: 170px;
  width: 325px;
}

.lokally-AI .meet-lokallyai {
  color: transparent;
  font-family: "Poppins", Helvetica;
  font-size: 40px;
  font-weight: 400;
  left: 21px;
  letter-spacing: -1.2px;
  line-height: 45.1px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.lokally-AI .text-wrapper-98 {
  color: #708090;
  font-weight: 600;
  letter-spacing: -0.48px;
}

.lokally-AI .text-wrapper-99 {
  color: #ffa500;
  font-family: "Grandstander", Helvetica;
  font-weight: 600;
  letter-spacing: -0.48px;
}

.lokally-AI .text-wrapper-100 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 25px;
  left: 0;
  letter-spacing: -0.8px;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 56px;
  width: 321px;
}

.lokally-AI .group-30 {
  height: 946px;
  left: 832px;
  position: absolute;
  top: 869px;
  width: 624px;
}

.lokally-AI .navbar-3 {
  background-color: #ffffff;
  box-shadow: 0px 0px 3.76px #ffa500;
  height: 76px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.lokally-AI .link-home-3 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 626px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.lokally-AI .link-our-3 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 726px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.lokally-AI .link-lokally-AI-3 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 932px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.lokally-AI .link-business-with-3 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 1063px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.lokally-AI .link-about-us-3 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 1265px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.lokally-AI .frame-83 {
  height: 34px;
  left: 264px;
  position: absolute;
  top: 23px;
  width: 92px;
}

.lokally-AI .overlap-group-23 {
  height: 34px;
  position: relative;
  top: -1px;
}

.lokally-AI .rectangle-8 {
  background-color: #000000;
  border: 0.69px solid;
  border-radius: 8px;
  height: 31px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 92px;
}

.lokally-AI .text-wrapper-101 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 14.4px;
  font-weight: 700;
  left: 14px;
  letter-spacing: -0.57px;
  line-height: 34px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.lokally-AI .arrow-3 {
  height: 7px;
  left: 65px;
  position: absolute;
  top: 16px;
  width: 13px;
}

.lokally-AI .goessy-logo-pdf-7 {
  height: 60px;
  left: 25px;
  position: absolute;
  top: 8px;
  width: 106px;
}
