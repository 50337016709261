.blog-page-inside {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.blog-page-inside .div-2 {
  background-color: #ffffff;
  height: 5798px;
  overflow: hidden;
  position: relative;
  width: 1920px;
}

.blog-page-inside .overlap-30 {
  height: 5572px;
  left: -13px;
  position: absolute;
  top: 148px;
  width: 2066px;
}

.blog-page-inside .ellipse-18 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 677px;
  opacity: 0.3;
  position: absolute;
  top: 1001px;
  transform: rotate(-32.69deg);
  width: 394px;
}

.blog-page-inside .decor-21 {
  height: 1233px;
  left: 1285px;
  position: absolute;
  top: 1082px;
  width: 648px;
}

.blog-page-inside .decor-22 {
  height: 1233px;
  left: 13px;
  position: absolute;
  top: 3948px;
  width: 563px;
}

.blog-page-inside .ellipse-19 {
  background-color: #f0da90;
  border-radius: 197px/161.45px;
  filter: blur(446.03px);
  height: 323px;
  left: 1672px;
  opacity: 0.3;
  position: absolute;
  top: 4024px;
  width: 394px;
}

.blog-page-inside .decor-23 {
  height: 1233px;
  left: 1278px;
  position: absolute;
  top: 29px;
  width: 655px;
}

.blog-page-inside .ellipse-20 {
  background-color: #f0da9080;
  border-radius: 197px/161.45px;
  filter: blur(446.03px);
  height: 323px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 2489px;
  width: 394px;
}

.blog-page-inside .ellipse-21 {
  background-color: #f0da9080;
  border-radius: 197px/161.45px;
  filter: blur(446.03px);
  height: 323px;
  left: 120px;
  opacity: 0.3;
  position: absolute;
  top: 1570px;
  width: 394px;
}

.blog-page-inside .rectangle-4 {
  height: 623px;
  left: 344px;
  object-fit: cover;
  position: absolute;
  top: 50px;
  width: 1259px;
}

.blog-page-inside .rectangle-5 {
  height: 623px;
  left: 344px;
  object-fit: cover;
  position: absolute;
  top: 1393px;
  width: 1259px;
}

.blog-page-inside .unlocking-innovation {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 36px;
  font-weight: 500;
  height: 127px;
  left: 344px;
  letter-spacing: -0.9px;
  line-height: 63px;
  position: absolute;
  top: 772px;
  width: 1259px;
}

.blog-page-inside .community-june {
  color: #b5b5b5;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 30px;
  left: 341px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 715px;
}

.blog-page-inside .text-wrapper-53 {
  font-weight: 700;
}

.blog-page-inside .text-wrapper-54 {
  color: #b5b5b5;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
}

.blog-page-inside .strong-history-of {
  color: #1c1c1c;
  font-family: "Poppins", Helvetica;
  font-size: 26px;
  font-weight: 600;
  height: 22px;
  left: 341px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 955px;
  white-space: nowrap;
}

.blog-page-inside .strong-history-of-2 {
  color: #1c1c1c;
  font-family: "Poppins", Helvetica;
  font-size: 26px;
  font-weight: 500;
  height: 90px;
  left: 341px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1247px;
  width: 1262px;
}

.blog-page-inside .most-of-you-are {
  color: #1c1c1c;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 210px;
  left: 341px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1007px;
  width: 1256px;
}

.blog-page-inside .udaipur-the-city-of {
  color: #000000;
  font-family: "Museo Sans-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 344px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 2090px;
  width: 1259px;
}

.blog-page-inside .text-wrapper-55 {
  color: #000000;
  font-family: "Museo Sans-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
}

.blog-page-inside .beyond-the-sights {
  color: #000000;
  font-family: "Museo Sans-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 344px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 2580px;
  width: 1259px;
}

.blog-page-inside .frame-33 {
  height: 1265px;
  left: 213px;
  position: absolute;
  top: 3671px;
  width: 1520px;
}

.blog-page-inside .text-wrapper-56 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 36px;
  font-weight: 500;
  height: 64px;
  left: 4px;
  letter-spacing: -0.9px;
  line-height: 63px;
  position: absolute;
  top: -1px;
  width: 1259px;
}

.blog-page-inside .group-19 {
  height: 1140px;
  left: 0;
  position: absolute;
  top: 114px;
  width: 1520px;
}

.blog-page-inside .frame-34 {
  height: 520px;
  left: 0;
  position: absolute;
  top: 620px;
  width: 1520px;
}

.blog-page-inside .frame-35 {
  display: block;
  height: 626px;
  left: 0;
  position: absolute;
  top: 0;
  width: 455px;
}

.blog-page-inside .overlap-group-16 {
  height: 626px;
  position: relative;
}

.blog-page-inside .frame-36 {
  height: 626px;
  left: 0;
  position: absolute;
  top: 0;
  width: 455px;
}

.blog-page-inside .rectangle-6 {
  height: 371px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 455px;
}

.blog-page-inside .frame-37 {
  height: 223px;
  left: 0;
  position: absolute;
  top: 391px;
  width: 455px;
}

.blog-page-inside .div-3 {
  color: #b5b5b5;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 21px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.blog-page-inside .text-wrapper-57 {
  color: #b5b5b5;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}

.blog-page-inside .unlocking-innovation-2 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 500;
  height: 86px;
  left: 0;
  letter-spacing: -0.41px;
  line-height: 28.6px;
  position: absolute;
  top: 28px;
  width: 455px;
}

.blog-page-inside .frame-38 {
  height: 626px;
  left: 532px;
  position: absolute;
  top: 0;
  width: 455px;
}

.blog-page-inside .frame-39 {
  height: 626px;
  left: 1065px;
  position: absolute;
  top: 0;
  width: 455px;
}

.blog-page-inside .overlap-31 {
  height: 626px;
  position: relative;
  width: 456px;
}

.blog-page-inside .text-wrapper-58 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 500;
  height: 29px;
  left: 0;
  letter-spacing: -0.41px;
  line-height: 28.6px;
  position: absolute;
  top: 29px;
  width: 455px;
}

.blog-page-inside .frame-40 {
  height: 520px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1520px;
}

.blog-page-inside .decor-24 {
  height: 1233px;
  left: 13px;
  position: absolute;
  top: 0;
  width: 616px;
}

.blog-page-inside .decor-25 {
  height: 1233px;
  left: 13px;
  position: absolute;
  top: 3017px;
  width: 662px;
}

.blog-page-inside .row-5 {
  background-image: url(../../../static/img/item.png);
  background-size: 100% 100%;
  height: 436px;
  left: 86px;
  position: absolute;
  top: 5136px;
  width: 1775px;
}

.blog-page-inside .overlap-32 {
  background-image: url(../../../static/img/item-1.png);
  background-size: 100% 100%;
  height: 502px;
  left: 6px;
  position: relative;
  top: -33px;
  width: 1762px;
}

.blog-page-inside .frame-41 {
  height: 331px;
  left: 323px;
  position: absolute;
  top: 108px;
  width: 1116px;
}

.blog-page-inside .text-wrapper-59 {
  color: #000000;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 423px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 310px;
  white-space: nowrap;
}

.blog-page-inside .list-4 {
  height: 70px;
  left: 424px;
  position: absolute;
  top: 203px;
  width: 268px;
}

.blog-page-inside .link-item-8 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 70px;
}

.blog-page-inside .symbol-10 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 21px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 23px;
}

.blog-page-inside .link-item-9 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 99px;
  position: absolute;
  top: 0;
  width: 70px;
}

.blog-page-inside .symbol-11 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 18px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 33px;
}

.blog-page-inside .link-item-10 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 197px;
  position: absolute;
  top: 0;
  width: 70px;
}

.blog-page-inside .symbol-12 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 15px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 42px;
}

.blog-page-inside .goessy-black-logo-4 {
  height: 132px;
  left: 456px;
  position: absolute;
  top: -44px;
  width: 235px;
}

.blog-page-inside .frame-42 {
  height: 58px;
  left: 307px;
  position: absolute;
  top: 215px;
  width: 1148px;
}

.blog-page-inside .overlap-group-17 {
  height: 44px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 86px;
}

.blog-page-inside .text-wrapper-60 {
  color: #000000;
  display: block;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 44px;
  left: 0;
  letter-spacing: 0;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.blog-page-inside .line-9 {
  height: 2px;
  left: 3px;
  position: absolute;
  top: 41px;
  width: 30px;
}

.blog-page-inside .overlap-33 {
  height: 44px;
  left: 134px;
  position: absolute;
  top: -1px;
  width: 250px;
}

.blog-page-inside .text-wrapper-61 {
  color: #000000;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 44px;
  left: 0;
  letter-spacing: 0;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.blog-page-inside .overlap-34 {
  height: 44px;
  left: 431px;
  position: absolute;
  top: -1px;
  width: 130px;
}

.blog-page-inside .overlap-35 {
  height: 44px;
  left: 611px;
  position: absolute;
  top: -1px;
  width: 126px;
}

.blog-page-inside .line-10 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 41px;
  width: 30px;
}

.blog-page-inside .overlap-36 {
  height: 44px;
  left: 785px;
  position: absolute;
  top: -1px;
  width: 63px;
}

.blog-page-inside .line-11 {
  height: 2px;
  left: 2px;
  position: absolute;
  top: 41px;
  width: 30px;
}

.blog-page-inside .overlap-37 {
  height: 44px;
  left: 897px;
  position: absolute;
  top: -1px;
  width: 255px;
}

.blog-page-inside .frame-43 {
  height: 125px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.blog-page-inside .overlap-38 {
  background-color: #ffffff;
  height: 196px;
  position: relative;
}

.blog-page-inside .frame-44 {
  height: 33px;
  left: 1116px;
  position: absolute;
  top: 53px;
  width: 698px;
}

.blog-page-inside .frame-45 {
  display: block;
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 63px;
}

.blog-page-inside .text-wrapper-62 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 0;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blog-page-inside .frame-46 {
  height: 33px;
  left: 113px;
  position: absolute;
  top: 0;
  width: 186px;
}

.blog-page-inside .frame-47 {
  height: 33px;
  left: 349px;
  position: absolute;
  top: 0;
  width: 98px;
}

.blog-page-inside .frame-48 {
  display: block;
  height: 33px;
  left: 497px;
  position: absolute;
  top: 0;
  width: 94px;
}

.blog-page-inside .frame-49 {
  display: block;
  height: 33px;
  left: 641px;
  position: absolute;
  top: 0;
  width: 57px;
}

.blog-page-inside .rectangle-7 {
  background-color: #ffa500;
  height: 4px;
  left: 0;
  position: absolute;
  top: 121px;
  width: 1920px;
}

.blog-page-inside .goessy-logo-pdf-5 {
  height: 60px;
  left: 94px;
  position: absolute;
  top: 38px;
  width: 106px;
}
