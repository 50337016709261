.home-page {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.home-page .homepage-mobile {
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
}

.home-page .overlap-8 {
  position: absolute;
}

.home-page .overlap-wrapper {
  background-image: url(../../../static/img/item-2.png);
  background-size: 100% 100%;
  height: 436px;
  left: 73px;
  position: absolute;
  top: 146px;
  width: 1775px;
}

.home-page .overlap-9 {
  background-image: url(../../../static/img/item-3.png);
  background-size: 100% 100%;
  height: 502px;
  left: 6px;
  position: relative;
  top: -33px;
  width: 1762px;
}

.home-page .frame-13 {
  height: 331px;
  left: 323px;
  position: absolute;
  top: 108px;
  width: 1116px;
}

.home-page .text-wrapper-13 {
  color: #000000;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 423px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 310px;
  white-space: nowrap;
}

.home-page .list-2 {
  height: 70px;
  left: 424px;
  position: absolute;
  top: 203px;
  width: 268px;
}

.home-page .link-item-2 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 70px;
}

.home-page .symbol-4 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 21px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 23px;
}

.home-page .link-item-3 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 99px;
  position: absolute;
  top: 0;
  width: 70px;
}

.home-page .symbol-5 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 18px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 33px;
}

.home-page .link-item-4 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 197px;
  position: absolute;
  top: 0;
  width: 70px;
}

.home-page .symbol-6 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 15px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 42px;
}

.home-page .goessy-black-logo-2 {
  height: 132px;
  left: 456px;
  position: absolute;
  top: -44px;
  width: 235px;
}

.home-page .frame-14 {
  height: 58px;
  left: 264px;
  position: absolute;
  top: 219px;
  width: 1190px;
}

.home-page .overlap-10 {
  height: 44px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 86px;
}

.home-page .text-wrapper-14 {
  color: #000000;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 44px;
  left: 0;
  letter-spacing: 0;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.home-page .line-4 {
  height: 2px;
  left: 3px;
  position: absolute;
  top: 40px;
  width: 30px;
}

.home-page .overlap-11 {
  height: 44px;
  left: 134px;
  position: absolute;
  top: -1px;
  width: 250px;
}

.home-page .overlap-12 {
  height: 44px;
  left: 431px;
  position: absolute;
  top: -1px;
  width: 130px;
}

.home-page .text-wrapper-15 {
  color: #000000;
  display: block;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 44px;
  left: 0;
  letter-spacing: 0;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.home-page .overlap-group-5 {
  height: 44px;
  left: 611px;
  position: absolute;
  top: -1px;
  width: 126px;
}

.home-page .line-5 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 30px;
}

.home-page .overlap-13 {
  height: 44px;
  left: 785px;
  position: absolute;
  top: -1px;
  width: 63px;
}

.home-page .line-6 {
  height: 2px;
  left: 2px;
  position: absolute;
  top: 40px;
  width: 30px;
}

.home-page .overlap-14 {
  height: 44px;
  left: 897px;
  position: absolute;
  top: -1px;
  width: 255px;
}

.home-page .decor-5 {
  position: absolute;
}

.home-page .group-4 {
  height: 1468px;
  left: 0;
  position: absolute;
  top: 0;
  width: 493px;
}

.home-page .ellipse-6 {
  background-color: #c090f080;
  border-radius: 69.5px/68.5px;
  filter: blur(50px);
  height: 137px;
  left: 158px;
  opacity: 0.3;
  position: absolute;
  top: 1331px;
  width: 139px;
}

.home-page .overlap-group-6 {
  height: 537px;
  left: 25px;
  position: absolute;
  top: -76px;
  width: 413px;
}

.home-page .decor-6 {
  height: 294px;
  left: 203px;
  position: absolute;
  top: 208px;
  width: 211px;
}

.home-page .decor-7 {
  height: 284px;
  left: 39px;
  position: absolute;
  top: 0;
  width: 268px;
}

.home-page .decor-8 {
  height: 294px;
  left: 197px;
  position: absolute;
  top: 33px;
  width: 216px;
}

.home-page .group-5 {
  height: 203px;
  left: 0;
  position: absolute;
  top: 334px;
  width: 219px;
}

.home-page .ellipse-7 {
  background-color: #f0da90;
  border-radius: 69.13px/26.35px;
  filter: blur(156.51px);
  height: 53px;
  left: 39px;
  opacity: 0.3;
  position: absolute;
  top: 150px;
  width: 138px;
}

.home-page .ellipse-8 {
  background-color: #f0da90;
  border-radius: 69.13px/26.35px;
  filter: blur(156.51px);
  height: 53px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 90px;
  width: 138px;
}

.home-page .ellipse-9 {
  background-color: #f0da90;
  border-radius: 69.13px/26.35px;
  filter: blur(156.51px);
  height: 53px;
  left: 81px;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 138px;
}

.home-page .decor-9 {
  height: 294px;
  left: 64px;
  position: absolute;
  top: 938px;
  width: 202px;
}

.home-page .group-6 {
  height: 1017px;
  left: 0;
  position: absolute;
  top: 1033px;
  width: 502px;
}

.home-page .overlap-15 {
  height: 645px;
  left: 64px;
  position: absolute;
  top: -87px;
  width: 374px;
}

.home-page .decor-10 {
  height: 314px;
  left: 164px;
  position: absolute;
  top: 254px;
  width: 211px;
}

.home-page .decor-11 {
  height: 314px;
  left: 0;
  position: absolute;
  top: 0;
  width: 268px;
}

.home-page .decor-12 {
  height: 314px;
  left: 0;
  position: absolute;
  top: 331px;
  width: 209px;
}

.home-page .group-7 {
  height: 235px;
  left: 0;
  position: absolute;
  top: 386px;
  width: 180px;
}

.home-page .ellipse-10 {
  background-color: #f0da90;
  border-radius: 69.13px/30.59px;
  filter: blur(156.51px);
  height: 61px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 174px;
  width: 138px;
}

.home-page .ellipse-11 {
  background-color: #f0da90;
  border-radius: 69.13px/30.59px;
  filter: blur(156.51px);
  height: 61px;
  left: 42px;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 138px;
}

.home-page .decor-13 {
  height: 238px;
  left: 64px;
  position: absolute;
  top: 790px;
  width: 202px;
}

.home-page .ellipse-12 {
  background-color: #f0da90;
  border-radius: 69.13px/30.59px;
  filter: blur(156.51px);
  height: 61px;
  left: 363px;
  opacity: 0.3;
  position: absolute;
  top: 916px;
  width: 138px;
}

.home-page .ellipse-13 {
  opacity: 0.3;
  position: absolute;
}

.home-page .decor-14 {
  height: 274px;
  left: 64px;
  position: absolute;
  top: 69px;
  width: 92px;
}

.home-page .decor-15 {
  height: 274px;
  left: 287px;
  position: absolute;
  top: 122px;
  width: 151px;
}

.home-page .content {
  align-items: flex-start;
  display: flex;
  gap: 12.76px;
  height: 357px;
  left: 79px;
  position: absolute;
  top: 32px;
  width: 344px;
}

.home-page .item {
  align-self: stretch;
  background-color: #00000033;
  background-image: url(../../../static/img/item-3-2x.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 43px;
  flex: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.home-page .revaluing-now-travel {
  color: #ffffff;
  font-family: "Josefin Sans", Helvetica;
  font-size: 36px;
  font-weight: 300;
  left: 50px;
  letter-spacing: -1.44px;
  line-height: 50px;
  position: absolute;
  top: 102px;
  width: 244px;
}

.home-page .item-2 {
  background-image: url(../../../static/img/item-2.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 22px;
  height: 180px;
  left: 79px;
  overflow: hidden;
  position: absolute;
  top: 409px;
  width: 345px;
}

.home-page .overlap-16 {
  height: 180px;
  position: relative;
}

.home-page .image {
  height: 180px;
  left: 166px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 178px;
}

.home-page .vector {
  height: 180px;
  left: 0;
  position: absolute;
  top: 0;
  width: 322px;
}

.home-page .new-style-new {
  color: #000000;
  font-family: "Josefin Sans", Helvetica;
  font-size: 22.3px;
  font-weight: 300;
  left: 13px;
  letter-spacing: -0.89px;
  line-height: 22px;
  position: absolute;
  top: 93px;
}

.home-page .row-2 {
  align-items: flex-start;
  display: flex;
  gap: 12.76px;
  height: 164px;
  left: 79px;
  position: absolute;
  top: 609px;
  width: 345px;
}

.home-page .item-3 {
  align-self: stretch;
  background-color: #000000;
  border-radius: 21.27px;
  flex: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.home-page .vector-2 {
  height: 100px;
  left: -1190px;
  position: absolute;
  top: -1288px;
  width: 115px;
}

.home-page .overlap-group-7 {
  background-image: url(../../../static/img/vector-2.svg);
  background-size: 100% 100%;
  height: 108px;
  left: 0;
  position: absolute;
  top: 56px;
  width: 166px;
}

.home-page .text-wrapper-16 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 17px;
  font-weight: 600;
  left: 13px;
  letter-spacing: -0.68px;
  line-height: 22px;
  position: absolute;
  top: 49px;
  width: 124px;
}

.home-page .experince {
  color: #ffffff;
  font-family: "Josefin Sans", Helvetica;
  font-size: 36px;
  font-weight: 600;
  left: 10px;
  letter-spacing: -1.44px;
  line-height: 36px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
  width: 105px;
}

.home-page .span {
  color: #dca3ff;
  letter-spacing: -0.52px;
}

.home-page .text-wrapper-17 {
  color: #dca3ff;
  font-size: 21.3px;
  letter-spacing: -0.18px;
  line-height: 25.5px;
}

.home-page .content-2 {
  height: 21px;
  left: 125px;
  position: absolute;
  top: 19px;
  width: 21px;
}

.home-page .item-4 {
  align-self: stretch;
  background-color: #ffeb4d;
  border-radius: 21.27px;
  flex: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.home-page .overlap-17 {
  height: 116px;
  left: 12px;
  position: absolute;
  top: 48px;
  width: 154px;
}

.home-page .vector-3 {
  height: 116px;
  left: 37px;
  position: absolute;
  top: 0;
  width: 117px;
}

.home-page .text-wrapper-18 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 17px;
  font-weight: 600;
  left: 2px;
  letter-spacing: -0.68px;
  line-height: 22px;
  position: absolute;
  top: 57px;
  width: 96px;
}

.home-page .explore {
  color: #000000;
  font-family: "Josefin Sans", Helvetica;
  font-size: 36px;
  font-weight: 600;
  left: 0;
  letter-spacing: -1.44px;
  line-height: 36px;
  position: absolute;
  top: 18px;
  white-space: nowrap;
  width: 78px;
}

.home-page .text-wrapper-19 {
  color: #ffa500;
  letter-spacing: -0.52px;
}

.home-page .text-wrapper-20 {
  color: #ffa500;
  font-size: 17px;
  letter-spacing: -0.12px;
}

.home-page .frame-15 {
  height: 346px;
  left: 76px;
  position: absolute;
  top: 1080px;
  width: 350px;
}

.home-page .item-5 {
  background-color: #ffeb4d;
  border-radius: 21.34px;
  height: 164px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 182px;
  width: 350px;
}

.home-page .overlap-18 {
  height: 106px;
  left: 17px;
  position: absolute;
  top: 59px;
  width: 305px;
}

.home-page .vector-4 {
  height: 106px;
  left: 83px;
  position: absolute;
  top: 0;
  width: 222px;
}

.home-page .text-wrapper-21 {
  color: #000000;
  font-family: "Grandstander", Helvetica;
  font-size: 30px;
  font-weight: 600;
  left: 0;
  letter-spacing: -1.2px;
  line-height: 35.3px;
  position: absolute;
  top: 55px;
  white-space: nowrap;
}

.home-page .overlap-group-8 {
  height: 36px;
  left: 17px;
  position: absolute;
  top: 15px;
  width: 319px;
}

.home-page .content-3 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 319px;
}

.home-page .text-wrapper-22 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 0;
  letter-spacing: -0.56px;
  line-height: 17.5px;
  position: absolute;
  top: -1px;
  width: 189px;
}

.home-page .frame-16 {
  background-color: #ffa500;
  border-radius: 10px;
  box-shadow: 0px 0px 2.57px #deaaf0;
  height: 36px;
  left: 210px;
  position: absolute;
  top: 0;
  width: 108px;
}

.home-page .text-wrapper-23 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 10px;
  font-weight: 700;
  left: 14px;
  letter-spacing: -0.4px;
  line-height: 19.2px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.home-page .group-8 {
  height: 13px;
  left: 79px;
  position: absolute;
  top: 13px;
  width: 14px;
}

.home-page .item-wrapper {
  align-items: flex-start;
  display: flex;
  gap: 12.8px;
  height: 164px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 346px;
}

.home-page .item-6 {
  align-self: stretch;
  background-color: #000000;
  border-radius: 21.34px;
  flex: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.home-page .vector-5 {
  height: 100px;
  left: -1099px;
  position: absolute;
  top: -1758px;
  width: 115px;
}

.home-page .overlap-19 {
  height: 103px;
  left: 17px;
  position: absolute;
  top: 61px;
  width: 329px;
}

.home-page .vector-6 {
  height: 103px;
  left: 180px;
  position: absolute;
  top: 0;
  width: 149px;
}

.home-page .text-wrapper-24 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 30px;
  font-weight: 600;
  left: 0;
  letter-spacing: -1.2px;
  line-height: 35.3px;
  position: absolute;
  top: 46px;
  white-space: nowrap;
}

.home-page .overlap-20 {
  height: 36px;
  left: 17px;
  position: absolute;
  top: 15px;
  width: 316px;
}

.home-page .content-4 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 315px;
}

.home-page .text-wrapper-25 {
  color: #dca3ff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 0;
  letter-spacing: -0.56px;
  line-height: 17.5px;
  position: absolute;
  top: -3px;
  width: 189px;
}

.home-page .group-9 {
  height: 36px;
  left: 206px;
  position: absolute;
  top: 0;
  width: 110px;
}

.home-page .overlap-group-9 {
  background-color: #dda6f8;
  border-radius: 10px;
  box-shadow: 0px 0px 2.57px #deaaf0;
  height: 36px;
  position: relative;
  width: 108px;
}

.home-page .vector-7 {
  height: 10px;
  left: 79px;
  position: absolute;
  top: 14px;
  width: 11px;
}

.home-page .text-wrapper-26 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 10px;
  font-weight: 700;
  left: 12px;
  letter-spacing: -0.4px;
  line-height: 19.2px;
  position: absolute;
  top: 9px;
  width: 63px;
}

.home-page .group-10 {
  height: 213px;
  left: 76px;
  position: absolute;
  top: 1526px;
  width: 352px;
}

.home-page .text-wrapper-27 {
  color: #708090;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 22px;
  font-weight: 600;
  height: 145px;
  left: 0;
  letter-spacing: 0;
  line-height: 28.6px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 350px;
}

.home-page .cta-link-wrapper {
  background-color: var(--black);
  border-radius: 12px;
  box-shadow: 0px 0px 15px 0.31px #00000040;
  height: 48px;
  left: 47px;
  position: absolute;
  top: 165px;
  width: 257px;
}

.home-page .cta-link {
  height: 18px;
  left: 15px;
  position: relative;
  top: 15px;
  width: 226px;
}

.home-page .cta-link-wrapper-2 {
  height: 18px;
  position: relative;
}

.home-page .text-wrapper-28 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(177, 108, 234) 14.06%,
    rgb(255, 94, 105) 44.79%,
    rgb(255, 138, 86) 71.88%,
    rgb(255, 168, 75) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 18px;
  font-weight: 600;
  height: 18px;
  left: 0;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-fill-color: transparent;
  top: 0;
  white-space: nowrap;
}

.home-page .vector-8 {
  height: 13px;
  left: 212px;
  position: absolute;
  top: 2px;
  width: 13px;
}

.home-page .group-11 {
  height: 167px;
  left: 107px;
  position: absolute;
  top: 873px;
  width: 291px;
}

.home-page .text-wrapper-29 {
  color: #211715;
  font-family: "Poppins", Helvetica;
  font-size: 30px;
  font-weight: 600;
  left: 30px;
  letter-spacing: -1.2px;
  line-height: 40px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.home-page .text-wrapper-30 {
  color: #211715;
  font-family: "Josefin Sans", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.88px;
  line-height: 26.5px;
  position: absolute;
  text-align: center;
  top: 60px;
  width: 287px;
}

.home-page .frame-17 {
  height: 196px;
  left: 76px;
  position: absolute;
  top: 1839px;
  width: 350px;
}

.home-page .overlap-21 {
  border-radius: 21.34px;
  height: 196px;
  position: relative;
}

.home-page .item-7 {
  background-color: #ffa500;
  border-radius: 21.34px;
  height: 196px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 350px;
}

.home-page .goessy-black-logo-3 {
  height: 44px;
  left: 136px;
  position: absolute;
  top: 11px;
  width: 78px;
}

.home-page .text-wrapper-31 {
  color: #000000;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 9px;
  font-weight: 400;
  height: 9px;
  left: 114px;
  letter-spacing: 0;
  line-height: 9px;
  position: absolute;
  text-align: center;
  top: 169px;
  white-space: nowrap;
}

.home-page .list-3 {
  height: 32px;
  left: 114px;
  position: absolute;
  top: 128px;
  width: 121px;
}

.home-page .link-item-5 {
  background-color: #000000;
  border-radius: 15.86px;
  box-shadow: 0px 6.34px 6.34px #0000001a;
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
}

.home-page .symbol-7 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  height: 18px;
  left: 9px;
  letter-spacing: 0;
  line-height: 18.1px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 12px;
}

.home-page .link-item-6 {
  background-color: #000000;
  border-radius: 15.86px;
  box-shadow: 0px 6.34px 6.34px #0000001a;
  height: 32px;
  left: 44px;
  position: absolute;
  top: 0;
  width: 32px;
}

.home-page .symbol-8 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  height: 18px;
  left: 8px;
  letter-spacing: 0;
  line-height: 18.1px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 16px;
}

.home-page .link-item-7 {
  background-color: #000000;
  border-radius: 15.86px;
  box-shadow: 0px 6.34px 6.34px #0000001a;
  height: 32px;
  left: 89px;
  position: absolute;
  top: 0;
  width: 32px;
}

.home-page .symbol-9 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 18.1px;
  font-weight: 400;
  height: 18px;
  left: 6px;
  letter-spacing: 0;
  line-height: 18.1px;
  position: absolute;
  text-align: right;
  top: 6px;
  white-space: nowrap;
  width: 21px;
}

.home-page .frame-18 {
  height: 56px;
  left: 29px;
  position: absolute;
  top: 63px;
  width: 287px;
}

.home-page .frame-19 {
  height: 27px;
  left: 14px;
  position: absolute;
  top: 0;
  width: 258px;
}

.home-page .frame-20 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 39px;
}

.home-page .overlap-group-10 {
  height: 21px;
  position: relative;
  width: 40px;
}

.home-page .text-wrapper-32 {
  color: #000000;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 21px;
  left: 0;
  letter-spacing: 0;
  line-height: 20.1px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.home-page .line-7 {
  height: 1px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 19px;
  width: 14px;
}

.home-page .frame-21 {
  height: 20px;
  left: 62px;
  position: absolute;
  top: 0;
  width: 114px;
}

.home-page .overlap-22 {
  height: 21px;
  position: relative;
  width: 117px;
}

.home-page .frame-22 {
  height: 20px;
  left: 198px;
  position: absolute;
  top: 0;
  width: 60px;
}

.home-page .overlap-23 {
  height: 21px;
  position: relative;
  width: 61px;
}

.home-page .frame-23 {
  height: 20px;
  left: 19px;
  position: absolute;
  top: 31px;
  width: 248px;
}

.home-page .frame-24 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 57px;
}

.home-page .overlap-group-11 {
  height: 21px;
  position: relative;
  width: 59px;
}

.home-page .line-8 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 19px;
  width: 14px;
}

.home-page .frame-25 {
  height: 20px;
  left: 80px;
  position: absolute;
  top: 0;
  width: 29px;
}

.home-page .overlap-24 {
  height: 21px;
  position: relative;
  top: -1px;
  width: 30px;
}

.home-page .frame-26 {
  height: 20px;
  left: 132px;
  position: absolute;
  top: 0;
  width: 136px;
}

.home-page .overlap-25 {
  height: 21px;
  position: relative;
  width: 119px;
}

.home-page .decor-16 {
  height: 218px;
  left: 311px;
  position: absolute;
  top: 681px;
  width: 127px;
}

.home-page .group-12 {
  position: absolute;
}

.home-page .ellipse-14 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 110px;
  opacity: 0.3;
  position: absolute;
  top: 700px;
  width: 394px;
}

.home-page .ellipse-15 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 420px;
  width: 394px;
}

.home-page .ellipse-16 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 230px;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 394px;
}

.home-page .overlap-26 {
  height: 2466px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2051px;
}

.home-page .decor-17 {
  height: 1057px;
  left: 1395px;
  position: absolute;
  top: 1046px;
  width: 656px;
}

.home-page .group-13 {
  height: 457px;
  left: 517px;
  position: absolute;
  top: 2009px;
  width: 1150px;
}

.home-page .text-wrapper-33 {
  color: #708090;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 60px;
  font-weight: 700;
  height: 312px;
  left: 0;
  letter-spacing: 0;
  line-height: 78px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 1148px;
}

.home-page .group-14 {
  background-color: var(--black);
  border-radius: 23.51px;
  box-shadow: 0px 0px 15px 0.58px #00000040;
  height: 91px;
  left: 327px;
  position: absolute;
  top: 365px;
  width: 495px;
}

.home-page .cta-link-2 {
  height: 35px;
  left: 47px;
  position: relative;
  top: 29px;
  width: 402px;
}

.home-page .cta-link-wrapper-3 {
  height: 35px;
  position: relative;
}

.home-page .text-wrapper-34 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(177, 108, 234) 14.06%,
    rgb(255, 94, 105) 44.79%,
    rgb(255, 138, 86) 71.88%,
    rgb(255, 168, 75) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 30px;
  font-weight: 600;
  height: 30px;
  left: 2px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  text-fill-color: transparent;
  top: 0;
  white-space: nowrap;
}

.home-page .vector-9 {
  height: 26px;
  left: 376px;
  position: absolute;
  top: 5px;
  width: 20px;
}

.home-page .goessy-logo-pdf-3 {
  height: 60px;
  left: 225px;
  position: absolute;
  top: 0;
  width: 106px;
}

.home-page .decor-18 {
  height: 1057px;
  left: 131px;
  position: absolute;
  top: 25px;
  width: 744px;
}

.home-page .decor-19 {
  height: 1057px;
  left: 1379px;
  position: absolute;
  top: 230px;
  width: 672px;
}

.home-page .content-5 {
  align-items: flex-start;
  display: flex;
  gap: 23.67px;
  height: 662px;
  left: 431px;
  position: absolute;
  top: 126px;
  width: 1320px;
}

.home-page .revaluing-now-travel-wrapper {
  align-self: stretch;
  background-color: #00000033;
  background-image: url(../../../static/img/item-5.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 75.87px;
  flex: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.home-page .revaluing-now-travel-2 {
  color: #ffffff;
  font-family: "Josefin Sans", Helvetica;
  font-size: 70px;
  font-weight: 300;
  left: 64px;
  letter-spacing: -2.8px;
  line-height: 91px;
  position: absolute;
  top: 193px;
  width: 444px;
}

.home-page .left {
  align-self: stretch;
  overflow: hidden;
  position: relative;
  width: 639.02px;
}

.home-page .item-8 {
  background-image: url(../../../static/img/item-4.svg);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 39.45px;
  height: 334px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 639px;
}

.home-page .overlap-group-12 {
  height: 334px;
  position: relative;
}

.home-page .image-2 {
  height: 334px;
  left: 309px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 330px;
}

.home-page .vector-10 {
  height: 334px;
  left: 0;
  position: absolute;
  top: 0;
  width: 598px;
}

.home-page .new-style-new-2 {
  color: #211715;
  font-family: "Poppins", Helvetica;
  font-size: 36px;
  font-weight: 200;
  left: 31px;
  letter-spacing: -1.44px;
  line-height: 40px;
  position: absolute;
  top: 180px;
  width: 246px;
}

.home-page .row-3 {
  align-items: flex-start;
  display: flex;
  gap: 23.67px;
  height: 304px;
  left: 0;
  position: absolute;
  top: 358px;
  width: 639px;
}

.home-page .item-9 {
  align-self: stretch;
  background-color: #000000;
  border-radius: 39.45px;
  flex: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.home-page .vector-11 {
  height: 185px;
  left: -7px;
  position: absolute;
  top: -1024px;
  width: 213px;
}

.home-page .overlap-group-13 {
  background-image: url(../../../static/img/vector-3-2.svg);
  background-size: 100% 100%;
  height: 191px;
  left: 0;
  position: absolute;
  top: 112px;
  width: 308px;
}

.home-page .text-wrapper-35 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 32px;
  font-weight: 600;
  left: 31px;
  letter-spacing: -1.28px;
  line-height: 41.1px;
  position: absolute;
  top: 90px;
  width: 243px;
}

.home-page .experience {
  color: #ffffff;
  font-family: "Josefin Sans", Helvetica;
  font-size: 70px;
  font-weight: 600;
  left: 27px;
  letter-spacing: -2.8px;
  line-height: 47.3px;
  position: absolute;
  top: 29px;
  white-space: nowrap;
  width: 243px;
}

.home-page .text-wrapper-36 {
  color: #dca3ff;
  letter-spacing: -1.96px;
}

.home-page .text-wrapper-37 {
  color: #dca3ff;
  font-size: 40px;
  letter-spacing: -0.64px;
}

.home-page .content-6 {
  height: 39px;
  left: 32px;
  position: absolute;
  top: 32px;
  width: 245px;
}

.home-page .item-10 {
  align-self: stretch;
  background-color: #ffeb4d;
  border-radius: 39.45px;
  flex: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.home-page .overlap-27 {
  height: 219px;
  left: 27px;
  position: absolute;
  top: 85px;
  width: 281px;
}

.home-page .vector-12 {
  height: 219px;
  left: 62px;
  position: absolute;
  top: 0;
  width: 218px;
}

.home-page .text-wrapper-38 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 32px;
  font-weight: 600;
  left: 5px;
  letter-spacing: -1.28px;
  line-height: 41.1px;
  position: absolute;
  top: 118px;
  width: 231px;
}

.home-page .explore-2 {
  color: #000000;
  font-family: "Josefin Sans", Helvetica;
  font-size: 70px;
  font-weight: 600;
  left: 0;
  letter-spacing: -2.8px;
  line-height: 47.3px;
  position: absolute;
  top: 56px;
  white-space: nowrap;
  width: 231px;
}

.home-page .text-wrapper-39 {
  color: #ffa500;
  letter-spacing: -1.96px;
}

.home-page .text-wrapper-40 {
  color: #ffa500;
  font-size: 40px;
  letter-spacing: -0.64px;
}

.home-page .content-7 {
  height: 36px;
  left: 32px;
  position: absolute;
  top: 32px;
  width: 245px;
}

.home-page .group-15 {
  height: 946px;
  left: 0;
  position: absolute;
  top: 1477px;
  width: 624px;
}

.home-page .group-16 {
  height: 946px;
  left: 860px;
  position: absolute;
  top: 1104px;
  transform: rotate(-32.69deg);
  width: 624px;
}

.home-page .group-17 {
  height: 748px;
  left: 323px;
  position: absolute;
  top: 1061px;
  width: 1514px;
}

.home-page .frame-27 {
  height: 348px;
  left: 0;
  position: absolute;
  top: 400px;
  width: 1514px;
}

.home-page .row-wrapper {
  height: 345px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 735px;
}

.home-page .row-4 {
  align-items: flex-start;
  display: flex;
  gap: 27.08px;
  height: 348px;
  left: 4px;
  position: relative;
  width: 731px;
}

.home-page .item-11 {
  align-self: stretch;
  background-color: #000000;
  border-radius: 45.13px;
  flex: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.home-page .vector-13 {
  height: 212px;
  left: 977px;
  position: absolute;
  top: -1970px;
  width: 244px;
}

.home-page .overlap-group-14 {
  height: 219px;
  left: 36px;
  position: absolute;
  top: 129px;
  width: 695px;
}

.home-page .vector-14 {
  height: 219px;
  left: 392px;
  position: absolute;
  top: 0;
  width: 303px;
}

.home-page .text-wrapper-41 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 60px;
  font-weight: 600;
  left: 0;
  letter-spacing: -2.4px;
  line-height: 74.6px;
  position: absolute;
  top: 98px;
  white-space: nowrap;
}

.home-page .content-8 {
  height: 45px;
  left: 36px;
  position: absolute;
  top: 36px;
  width: 665px;
}

.home-page .text-wrapper-42 {
  color: #dca3ff;
  font-family: "Poppins", Helvetica;
  font-size: 26px;
  font-weight: 600;
  left: 0;
  letter-spacing: -1.04px;
  line-height: 36.9px;
  position: absolute;
  top: 1px;
  width: 400px;
}

.home-page .frame-28 {
  background-color: #dda6f8;
  border-radius: 19.82px;
  box-shadow: 0px 0px 4.95px #deaaf0;
  height: 69px;
  left: 457px;
  position: absolute;
  top: 4px;
  width: 199px;
}

.home-page .text-wrapper-43 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 600;
  left: 26px;
  letter-spacing: -0.8px;
  line-height: 36.9px;
  position: absolute;
  top: 15px;
  white-space: nowrap;
}

.home-page .frame-29 {
  height: 25px;
  left: 157px;
  position: absolute;
  top: 22px;
  width: 25px;
}

.home-page .item-12 {
  background-color: #ffeb4d;
  border-radius: 45.13px;
  height: 348px;
  left: 775px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 740px;
}

.home-page .overlap-28 {
  height: 238px;
  left: 36px;
  position: absolute;
  top: 110px;
  width: 642px;
}

.home-page .vector-15 {
  height: 238px;
  left: 173px;
  position: absolute;
  top: 0;
  width: 469px;
}

.home-page .text-wrapper-44 {
  color: #000000;
  font-family: "Grandstander", Helvetica;
  font-size: 60px;
  font-weight: 600;
  left: 0;
  letter-spacing: -2.4px;
  line-height: 74.6px;
  position: absolute;
  top: 132px;
  white-space: nowrap;
}

.home-page .content-9 {
  height: 41px;
  left: 36px;
  position: absolute;
  top: 36px;
  width: 675px;
}

.home-page .text-wrapper-45 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 26px;
  font-weight: 600;
  left: 0;
  letter-spacing: -1.04px;
  line-height: 36.9px;
  position: absolute;
  top: 1px;
  width: 400px;
}

.home-page .frame-30 {
  background-color: #ffa500;
  border-radius: 19.82px;
  box-shadow: 0px 0px 4.95px #ffa500;
  height: 69px;
  left: 464px;
  position: absolute;
  top: 5px;
  width: 199px;
}

.home-page .direct-instagram {
  height: 20px;
  left: 157px;
  position: absolute;
  top: 25px;
  width: 20px;
}

.home-page .group-18 {
  height: 350px;
  left: 108px;
  position: absolute;
  top: 0;
  width: 1324px;
}

.home-page .text-wrapper-46 {
  color: var(--black);
  font-family: "Poppins", Helvetica;
  font-size: 70px;
  font-weight: 500;
  left: 441px;
  letter-spacing: -2.8px;
  line-height: 90px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.home-page .text-wrapper-47 {
  color: var(--black);
  font-family: "Josefin Sans", Helvetica;
  font-size: 50px;
  font-weight: 400;
  left: 0;
  letter-spacing: -2px;
  line-height: 70px;
  position: absolute;
  text-align: center;
  top: 140px;
  width: 1320px;
}

.home-page .text-wrapper-48 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1254px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 15px;
  white-space: nowrap;
}

.home-page .text-wrapper-49 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1603px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 15px;
  white-space: nowrap;
}

.home-page .text-wrapper-50 {
  color: #000000;
  display: block;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1751px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 15px;
  white-space: nowrap;
}

.home-page .frame-31 {
  display: block;
  height: 33px;
  left: 1895px;
  position: absolute;
  top: 16px;
  width: 57px;
}

.home-page .text-wrapper-51 {
  color: #000000;
  display: block;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 0;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.home-page .overlap-29 {
  height: 76px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.home-page .navbar-2 {
  background-color: #ffffff;
  box-shadow: 0px 0px 3.76px #ffa500;
  height: 76px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.home-page .link-home-2 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 626px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.home-page .link-our-2 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 726px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.home-page .link-lokally-AI-2 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 932px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.home-page .link-business-with-2 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 1063px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.home-page .link-about-us-2 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 17px;
  left: 1265px;
  letter-spacing: 0;
  line-height: 16.9px;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.home-page .frame-32 {
  height: 34px;
  left: 264px;
  position: absolute;
  top: 23px;
  width: 92px;
}

.home-page .overlap-group-15 {
  height: 34px;
  position: relative;
  top: -1px;
}

.home-page .rectangle-3 {
  background-color: #000000;
  border: 0.69px solid;
  border-radius: 8px;
  height: 31px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 92px;
}

.home-page .text-wrapper-52 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 14.4px;
  font-weight: 700;
  left: 14px;
  letter-spacing: -0.57px;
  line-height: 34px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.home-page .arrow-2 {
  height: 7px;
  left: 65px;
  position: absolute;
  top: 16px;
  width: 13px;
}

.home-page .goessy-logo-pdf-4 {
  height: 60px;
  left: 25px;
  position: absolute;
  top: 8px;
  width: 106px;
}

.home-page .decor-20 {
  height: 559px;
  left: 1222px;
  position: absolute;
  top: 3378px;
  width: 506px;
}

.home-page .ellipse-17 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 1651px;
  opacity: 0.3;
  position: absolute;
  top: 3990px;
  width: 394px;
}
