.terms-and-conditions-screen {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.terms-and-conditions-screen .terms-and-conditions-2 {
  background-color: #ffffff;
  height: 2541px;
  overflow: hidden;
  position: relative;
  width: 1920px;
}

.terms-and-conditions-screen .decor-55 {
  height: 559px;
  left: -6397px;
  position: absolute;
  top: -3297px;
  width: 506px;
}

.terms-and-conditions-screen .decor-56 {
  height: 559px;
  left: -6586px;
  position: absolute;
  top: -1978px;
  width: 506px;
}

.terms-and-conditions-screen .ellipse-54 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 1651px;
  opacity: 0.3;
  position: absolute;
  top: 3990px;
  width: 394px;
}

.terms-and-conditions-screen .overlap-84 {
  height: 2870px;
  left: -131px;
  position: absolute;
  top: -402px;
  width: 2150px;
}

.terms-and-conditions-screen .goessy-logo-pdf-12 {
  height: 60px;
  left: 225px;
  position: absolute;
  top: 436px;
  width: 106px;
}

.terms-and-conditions-screen .text-wrapper-164 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1254px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  white-space: nowrap;
}

.terms-and-conditions-screen .text-wrapper-165 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1367px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  white-space: nowrap;
}

.terms-and-conditions-screen .text-wrapper-166 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1603px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  white-space: nowrap;
}

.terms-and-conditions-screen .text-wrapper-167 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 1751px;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: 451px;
  white-space: nowrap;
}

.terms-and-conditions-screen .frame-170 {
  height: 2859px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2150px;
}

.terms-and-conditions-screen .overlap-85 {
  height: 2859px;
  position: relative;
  width: 2051px;
}

.terms-and-conditions-screen .overlap-group-36 {
  height: 2579px;
  left: 131px;
  position: absolute;
  top: 0;
  width: 1920px;
}

.terms-and-conditions-screen .decor-57 {
  height: 1057px;
  left: 1264px;
  position: absolute;
  top: 1482px;
  width: 656px;
}

.terms-and-conditions-screen .group-44 {
  background-color: #f0da904c;
  border-radius: 693.25px/432.84px;
  filter: blur(1569.58px);
  height: 866px;
  left: 267px;
  position: absolute;
  top: 0;
  width: 1386px;
}

.terms-and-conditions-screen .decor-58 {
  height: 1057px;
  left: 0;
  position: absolute;
  top: 461px;
  width: 744px;
}

.terms-and-conditions-screen .decor-59 {
  height: 1057px;
  left: 1248px;
  position: absolute;
  top: 666px;
  width: 672px;
}

.terms-and-conditions-screen .group-45 {
  height: 946px;
  left: 729px;
  position: absolute;
  top: 1540px;
  transform: rotate(-32.69deg);
  width: 624px;
}

.terms-and-conditions-screen .ellipse-55 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 110px;
  opacity: 0.3;
  position: absolute;
  top: 700px;
  width: 394px;
}

.terms-and-conditions-screen .ellipse-56 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 420px;
  width: 394px;
}

.terms-and-conditions-screen .ellipse-57 {
  background-color: #f0da90;
  border-radius: 197px/123px;
  filter: blur(446.03px);
  height: 246px;
  left: 230px;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 394px;
}

.terms-and-conditions-screen .group-46 {
  height: 946px;
  left: 0;
  position: absolute;
  top: 1913px;
  width: 624px;
}

.terms-and-conditions-screen .row-9 {
  background-image: url(../../../static/img/item.png);
  background-size: 100% 100%;
  height: 436px;
  left: 204px;
  position: absolute;
  top: 2434px;
  width: 1775px;
}

.terms-and-conditions-screen .overlap-86 {
  background-image: url(../../../static/img/item-1.png);
  background-size: 100% 100%;
  height: 502px;
  left: 6px;
  position: relative;
  top: -33px;
  width: 1762px;
}

.terms-and-conditions-screen .frame-171 {
  height: 331px;
  left: 323px;
  position: absolute;
  top: 108px;
  width: 1116px;
}

.terms-and-conditions-screen .text-wrapper-168 {
  color: #000000;
  font-family: "Bai Jamjuree", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 20px;
  left: 423px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 310px;
  white-space: nowrap;
}

.terms-and-conditions-screen .list-11 {
  height: 70px;
  left: 424px;
  position: absolute;
  top: 203px;
  width: 268px;
}

.terms-and-conditions-screen .link-item-29 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 70px;
}

.terms-and-conditions-screen .symbol-31 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 21px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 23px;
}

.terms-and-conditions-screen .link-item-30 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 99px;
  position: absolute;
  top: 0;
  width: 70px;
}

.terms-and-conditions-screen .symbol-32 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 18px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 33px;
}

.terms-and-conditions-screen .link-item-31 {
  background-color: #000000;
  border-radius: 35.24px;
  box-shadow: 0px 14.1px 14.1px #0000001a;
  height: 70px;
  left: 197px;
  position: absolute;
  top: 0;
  width: 70px;
}

.terms-and-conditions-screen .symbol-33 {
  color: #ffa500;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 36.3px;
  font-weight: 400;
  height: 36px;
  left: 15px;
  letter-spacing: 0;
  line-height: 36.3px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 42px;
}

.terms-and-conditions-screen .goessy-black-logo-11 {
  height: 132px;
  left: 456px;
  position: absolute;
  top: -44px;
  width: 235px;
}

.terms-and-conditions-screen .frame-172 {
  height: 58px;
  left: 264px;
  position: absolute;
  top: 219px;
  width: 1190px;
}

.terms-and-conditions-screen .overlap-87 {
  height: 44px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 86px;
}

.terms-and-conditions-screen .text-wrapper-169 {
  color: #000000;
  font-family: "Museo Sans-300", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 44px;
  left: 0;
  letter-spacing: 0;
  line-height: 43.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.terms-and-conditions-screen .line-29 {
  height: 2px;
  left: 3px;
  position: absolute;
  top: 40px;
  width: 30px;
}

.terms-and-conditions-screen .overlap-88 {
  height: 44px;
  left: 134px;
  position: absolute;
  top: -1px;
  width: 250px;
}

.terms-and-conditions-screen .overlap-89 {
  height: 44px;
  left: 431px;
  position: absolute;
  top: -1px;
  width: 130px;
}

.terms-and-conditions-screen .overlap-90 {
  height: 44px;
  left: 611px;
  position: absolute;
  top: -1px;
  width: 126px;
}

.terms-and-conditions-screen .line-30 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 30px;
}

.terms-and-conditions-screen .overlap-91 {
  height: 44px;
  left: 785px;
  position: absolute;
  top: -1px;
  width: 63px;
}

.terms-and-conditions-screen .line-31 {
  height: 2px;
  left: 2px;
  position: absolute;
  top: 40px;
  width: 30px;
}

.terms-and-conditions-screen .overlap-group-37 {
  height: 44px;
  left: 897px;
  position: absolute;
  top: -1px;
  width: 255px;
}

.terms-and-conditions-screen .frame-173 {
  height: 33px;
  left: 1895px;
  position: absolute;
  top: 452px;
  width: 57px;
}

.terms-and-conditions-screen .text-wrapper-170 {
  color: #000000;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 33px;
  left: 0;
  letter-spacing: 0;
  line-height: 32.8px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.terms-and-conditions-screen .frame-174 {
  background-color: #ffffff;
  border-radius: 40px;
  box-shadow: 0px 0px 10px #00000040;
  height: 1655px;
  left: 811px;
  position: absolute;
  top: 579px;
  width: 1141px;
}

.terms-and-conditions-screen .privacy-policies-2 {
  color: #000000;
  font-family: "undefined-Regular", Helvetica;
  font-size: 26px;
  font-weight: 400;
  height: 1522px;
  left: 56px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 74px;
  width: 1043px;
}

.terms-and-conditions-screen .ellipse-58 {
  background-color: #c090f080;
  border-radius: 178.5px/175.93px;
  filter: blur(128.42px);
  height: 352px;
  left: 732px;
  opacity: 0.3;
  position: absolute;
  top: 3003px;
  width: 357px;
}

.terms-and-conditions-screen .frame-175 {
  background-color: #ffffff;
  border-radius: 40px;
  box-shadow: 0px 0px 10px #00000040;
  height: 535px;
  left: 99px;
  position: fixed;
  top: 177px;
  width: 528px;
}

.terms-and-conditions-screen .frame-176 {
  background-color: #ffa500;
  border-radius: 20px;
  height: 96px;
  left: 47px;
  position: absolute;
  top: 95px;
  width: 441px;
}

.terms-and-conditions-screen .text-wrapper-171 {
  color: #22272c;
  font-family: "Museo Sans-500", Helvetica;
  font-size: 22px;
  font-weight: 400;
  height: 28px;
  left: 56px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 33px;
  white-space: nowrap;
  width: 223px;
}

.terms-and-conditions-screen .frame-177 {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 4px #00000040;
  height: 96px;
  left: 47px;
  position: absolute;
  top: 215px;
  width: 441px;
}

.terms-and-conditions-screen .frame-178 {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 4px #00000040;
  height: 96px;
  left: 47px;
  position: absolute;
  top: 335px;
  width: 441px;
}
